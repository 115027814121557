import axios from "../helpers/axios";
import {TokenDto} from "../models/dtos/token.dto";
import {LoginRequest} from "../models/requests/login.request";
import {OtpRequest} from "../models/requests/otp.request";
import {SignupRequest} from "../models/requests/signup.request";
import {SmsLoginRequest} from "../models/requests/smsLogin.request";

export default class AuthService {

  async loginWithCode(request: SmsLoginRequest): Promise<TokenDto> {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/sms-login`,
        {...request},
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async login(request: LoginRequest): Promise<TokenDto> {
    try {
      const response = await axios.post<TokenDto>(
        `${process.env.REACT_APP_BASE_URL}/auth/login`,
        {...request}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async signup(request: SignupRequest): Promise<TokenDto> {
    try {
      const response = await axios.post<TokenDto>(
        `${process.env.REACT_APP_BASE_URL}/auth/sign-up`,
        {...request}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async sendOtp(request: OtpRequest): Promise<void> {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/otp`,
        {...request}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async refresh(): Promise<void> {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/refresh`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
