import TagManager from 'react-gtm-module';

export default class GTMService {

  public static init(): void {
    TagManager.initialize({gtmId: `${process.env.REACT_APP_GTM}`})
  }

  public static pageView(): void {
    (window as any).dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.origin + window.location.pathname + window.location.search,
      }
    });
  }

  public static eventLogin() {
    const env: string = `${process.env.REACT_APP_ENV}`;
    if (env !== 'production') {
      console.log(`>> GTM EVENT: Login`);
    }
    (window as any).dataLayer.push({
      event: 'goalEvent',
      eventAction: 'login',
      eventLabel: '/login',
    });
  }
}
