import {Button, Modal, Spin} from "antd";
import parse from "html-react-parser";
import React from "react";
import icon from "../../../../assets/images/popup-icon-alert.svg";
import FirebaseService from "../../../../core/services/firebase.service";
import "./ConsentApprovalModal.scss";

interface IProps {
  isLoading: boolean;
  callback: (isApproved: boolean) => void;
}

const ConsentApprovalModal = (props: IProps) => {

  return (
    <Modal
      className="consent-approval-modal"
      maskStyle={{backdropFilter: "blur(8px)"}}
      visible
      centered
      closable={false}
      footer={false}
      destroyOnClose
    >
      <div className="body">

        <div className="header">
          <img className="logo" src={icon} alt="alert"/>
        </div>

        <p className="title">
          {parse(FirebaseService.getValue(FirebaseService.consent_popup_title))}
        </p>
        <p className="text">
          {parse(FirebaseService.getValue(FirebaseService.consent_popup_desc))}
        </p>

        <div className="button-wrapper">
          {
            props.isLoading
              ?
              <Spin/>
              :
              <React.Fragment>
                <Button
                  className="button cancel"
                  onClick={() => props.callback(true)}
                >
                  {parse(FirebaseService.getValue(FirebaseService.consent_popup_button1))}
                </Button>
                <Button
                  className="button"
                  onClick={() => props.callback(false)}
                >
                  {parse(FirebaseService.getValue(FirebaseService.consent_popup_button2))}
                </Button>
              </React.Fragment>
          }
        </div>
      </div>
    </Modal>
  );
}

export default ConsentApprovalModal
