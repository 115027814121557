import {Spin} from "antd";
import React from "react";
import {Constants} from "../../../../core/helpers/constants";
import {setStorage, storageVposResult} from "../../../../core/helpers/storage";
import "./PayByMeSuccessPage.scss";

class PayByMeSuccessPage extends React.Component {

  componentDidMount() {
    setStorage(storageVposResult, Constants.vposResultSuccess);
  }

  render() {
    return (
      <div id="pay-by-me-success-page">
        <Spin size="large"/>
      </div>
    );
  }
}

export default PayByMeSuccessPage;
