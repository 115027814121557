import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import AuthHeader from "../../../../components/Auth/AuthHeader/AuthHeader";
import CreateCardText from "../../../../components/Auth/CreateCardText/CreateCardText";
import FanCard from "../../../../components/Auth/FanCard/FanCard";
import WelcomeText from "../../../../components/Auth/WelcomeText/WelcomeText";
import {setSignupInfo} from "../../../../core/actions/signupInfo";
import {Utilities} from "../../../../core/helpers/utilities";
import {FormValuesSignupInfo} from "../../../../core/models/custom/formValuesSignupInfo";
import {SignupInfoRequest} from "../../../../core/models/custom/signupInfo.request";
import SignupInfoForm from "./SignupInfoForm/SignupInfoForm";
import "./SignupInfoPage.scss";

interface IProps {
  setSignupInfo: (request: SignupInfoRequest) => void;
}

interface IState {
  error?: string;
}

class SignupInfoPage extends React.Component<IProps> {
  state: IState = {
    error: undefined,
  }

  private async handleSubmit(values: FormValuesSignupInfo): Promise<void> {
    this.setState({error: undefined});
    const request: SignupInfoRequest = {
      firstName: Utilities.getFirstNameFromFullName(values.fullName),
      lastName: Utilities.getLastNameFromFullName(values.fullName),
      country: values.country,
    };
    await this.props.setSignupInfo(request);
  }

  render() {
    return (
      <div id="signup-info-page">
        <AuthHeader
          error={this.state.error}
        />
        <div className="container">
          <div className="page-content">
            <CreateCardText/>
            <FanCard/>
            <WelcomeText/>
            <SignupInfoForm
              isSubmitDisabled={false}
              callbackSubmit={values => this.handleSubmit(values)}
              callbackError={error => this.setState({error})}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setSignupInfo,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(SignupInfoPage);
