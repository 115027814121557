import {
  LOGIN_CODE_SUBMIT_FAIL,
  LOGIN_CODE_SUBMIT_RESET,
  LOGIN_CODE_SUBMIT_START,
  LOGIN_CODE_SUBMIT_SUCCESS,
  LOGIN_MSISDN_SUBMIT_FAIL,
  LOGIN_MSISDN_SUBMIT_RESET,
  LOGIN_MSISDN_SUBMIT_START,
  LOGIN_MSISDN_SUBMIT_SUCCESS,
  SET_LOGIN_MSISDN
} from "../constants/loginMsisdn";
import { LoginMsisdnActions, LoginMsisdnState } from "../types/loginMsisdn";

const initialState: LoginMsisdnState = {
  msisdn: undefined,
  submit: {
    loading: false,
    data: false,
    error: undefined,
  },
  code: {
    loading: false,
    data: false,
    error: undefined,
  }
}

const reducer = (
  state = initialState,
  action: LoginMsisdnActions,
): LoginMsisdnState => {
  switch (action.type) {
    case SET_LOGIN_MSISDN:
      return {
        ...state,
        msisdn: action.payload,
      }
    case LOGIN_MSISDN_SUBMIT_START:
      return {
        ...state,
        submit: {
          ...initialState.submit,
          loading: true,
        }
      }
    case LOGIN_MSISDN_SUBMIT_SUCCESS:
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          data: action.payload,
        }
      }
    case LOGIN_MSISDN_SUBMIT_FAIL:
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          error: action.error,
        }
      }
    case LOGIN_MSISDN_SUBMIT_RESET:
      return {
        ...state,
        submit: {
          ...initialState.submit,
        }
      }
    case LOGIN_CODE_SUBMIT_START:
      return {
        ...state,
        code: {
          ...initialState.code,
          loading: true,
        }
      }
    case LOGIN_CODE_SUBMIT_SUCCESS:
      return {
        ...state,
        code: {
          ...state.code,
          loading: false,
          data: action.payload,
        }
      }
    case LOGIN_CODE_SUBMIT_FAIL:
      return {
        ...state,
        code: {
          ...state.code,
          loading: false,
          error: action.error,
        }
      }
    case LOGIN_CODE_SUBMIT_RESET:
      return {
        ...state,
        code: {
          ...initialState.code,
        }
      }
    default:
      return state;
  }
}

export default reducer;
