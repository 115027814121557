import { Spin } from "antd";
import parse from "html-react-parser";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import paymentPayByMe from "../../../../assets/images/payment-paybyme.svg";
import paymentTelekom from "../../../../assets/images/payment-telekom.svg";
import paymentTurkcell from "../../../../assets/images/payment-turkcell.svg";
import paymentVodafone from "../../../../assets/images/payment-vodafone.svg";
import AuthFooter from "../../../../components/Auth/AuthFooter/AuthFooter";
import AuthHeaderBottom from "../../../../components/Auth/AuthHeaderBottom/AuthHeaderBottom";
import AuthHeaderMobile from "../../../../components/Auth/AuthHeaderMobile/AuthHeaderMobile";
import FbCard from "../../../../components/Auth/FbCard/FbCard";
import PageTitle from "../../../../components/Auth/PageTitle/PageTitle";
import Privileges from "../../../../components/Auth/Privileges/Privileges";
import GenericModal, {
  ModalType,
} from "../../../../components/Modals/GenericModal/GenericModal";
import {
  loginCodeSubmit,
  loginMsisdnSubmit,
} from "../../../../core/actions/loginMsisdn";
import { mobilePaymentSetCreditCardRedirect } from "../../../../core/actions/mobilePayment";
import { Constants } from "../../../../core/helpers/constants";
import { Utilities } from "../../../../core/helpers/utilities";
import { AuthType } from "../../../../core/models/custom/enums/authType";
import { FormValuesLoginMsisdn } from "../../../../core/models/custom/formValuesLoginMsisdn";
import { ErrorDto } from "../../../../core/models/dtos/error.dto";
import { OtpRequest } from "../../../../core/models/requests/otp.request";
import { IStore } from "../../../../core/reducers";
import FirebaseService from "../../../../core/services/firebase.service";
import LoginMsisdnForm from "./LoginMsisdnForm/LoginMsisdnForm";
import "./LoginMsisdnPage.scss";

interface IProps {
  match: any;
  loadingSubmit: boolean;
  dataSubmit: boolean;
  errorSubmit?: ErrorDto;
  loadingCode: boolean;
  dataCode: boolean;
  errorError?: ErrorDto;
  mobilePaymentSetCreditCardRedirect: (isCreditCardRedirect: boolean) => void;
  loginMsisdnSubmit: (
    request: OtpRequest,
    authType: AuthType,
    marketingConsent: boolean,
    privacyConsent: boolean
  ) => void;
  loginCodeSubmit: (code: string) => void;
}

interface IState {
  authType: AuthType;
  phone: string;
  isContractError: boolean;
  isMsisdnError: boolean;
  isOtpError: boolean;
}

class LoginMsisdnPage extends React.Component<IProps> {
  state: IState = {
    authType: AuthType.login,
    phone: "90",
    isContractError: false,
    isMsisdnError: false,
    isOtpError: false,
  };

  private readonly code: string | null;
  private readonly authType: number;
  private readonly errorContract =
    "Sözleşmeyi̇ onaylamadınız. Lütfen kontrol edi̇p tekrar deneyi̇ni̇z.";
  private readonly errorMsisdn =
    "Lütfen geçerli bir telefon numarası girerek tekrar dene!";
  private readonly errorOtp = Constants.errorGenericText;
  public static authTextLogin = "GİRİŞ YAP";
  public static authTextSignUp = "ABONE OL";

  constructor(props: IProps) {
    super(props);
    this.code = this.getQueryParamCode();
    this.authType = this.getQueryParamAuthType();
  }

  componentDidMount() {
    FirebaseService.fireEvent(
      FirebaseService.event_user_count_view_MSISDN_checked
    );
    this.props.mobilePaymentSetCreditCardRedirect(false);
    if (this.code) {
      this.props.loginCodeSubmit(this.code);
    }
    this.setState({ authType: this.authType });
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (prevProps.loadingSubmit && !this.props.loadingSubmit) {
      if (
        this.props.errorSubmit?.status !== 404 &&
        this.props.errorSubmit?.status !== 418
      ) {
        this.setState({ isOtpError: true });
      }
    }
  }

  private getQueryParamCode(): string | null {
    return Utilities.getQueryParam("code");
  }

  private getQueryParamAuthType(): number {
    const authTypeString = Utilities.getQueryParam("authType");
    if (authTypeString && !isNaN(+authTypeString)) {
      const type: number = +authTypeString;
      if (type === 0 || type === 1) {
        return +authTypeString;
      }
    }
    return 0;
  }

  private getPageTitle(): string {
    return this.state.authType === AuthType.login
      ? LoginMsisdnPage.authTextLogin
      : LoginMsisdnPage.authTextSignUp;
  }

  private getError(): string | undefined {
    if (this.state.isContractError) {
      return this.errorContract;
    } else if (this.state.isMsisdnError) {
      return this.errorMsisdn;
    } else if (this.state.isOtpError) {
      return this.errorOtp;
    }
    return undefined;
  }

  private resetErrors(): void {
    this.setState({
      isContractError: false,
      isMsisdnError: false,
      isOtpError: false,
    });
  }

  private async handleSubmit(
    values: FormValuesLoginMsisdn,
    authType: AuthType
  ): Promise<void> {
    FirebaseService.fireEvent(
      FirebaseService.event_user_count_MSISDN_checked_login_clicked
    );
    this.resetErrors();
    const request: OtpRequest = {
      msisdn: values.msisdn,
      captcha: values.captcha,
    };
    await this.props.loginMsisdnSubmit(
      request,
      authType,
      values.marketingConsent ?? false,
      values.privacyConsent ?? false
    );
  }

  private renderPayment(href: string, src: any, alt: string) {
    return (
      <a className="payment" href={href} rel="noreferrer" target="_blank">
        <img className="img-fluid" src={src} alt={alt} />
      </a>
    );
  }

  private renderPaymentInfo(): ReactNode {
    if (this.state.authType !== AuthType.signup) {
      return <React.Fragment />;
    }
    return (
      <div className="payment-info">
        <p className="helper-1">
          {parse(
            FirebaseService.getValue(
              Utilities.isMsisdnTurkish(this.state.phone)
                ? FirebaseService.signup_description
                : FirebaseService.abroad_login_info_description
            )
          )}
        </p>
        <p className="helper-2">
          {parse(
            FirebaseService.getValue(
              Utilities.isMsisdnTurkish(this.state.phone)
                ? FirebaseService.login_info_subtext
                : FirebaseService.abroad_login_info_subtext
            )
          )}
        </p>

        {Utilities.isMsisdnTurkish(this.state.phone) && (
          <div className="payment-wrapper row">
            {this.renderPayment(
              "https://www.turkcell.com.tr/servisler/turkcellmobilodeme",
              paymentTurkcell,
              "turkcell"
            )}
            {this.renderPayment(
              "https://www.vodafone.com.tr/Servisler/vodafone-mobil-odeme.php",
              paymentVodafone,
              "vodafone"
            )}
            {this.renderPayment(
              "https://bireysel.turktelekom.com.tr/mobil/web/servisler/sayfalar/turk-telekom-mobil-odeme.aspx",
              paymentTelekom,
              "telekom"
            )}
            {this.renderPayment(
              "https://www.payby.me",
              paymentPayByMe,
              "payByMe"
            )}
          </div>
        )}
      </div>
    );
  }

  private renderContent(): ReactNode {
    if (this.props.loadingCode) {
      return <Spin size="large" className="mt-5" />;
    } else {
      return (
        <LoginMsisdnForm
          authType={this.state.authType}
          isSubmitDisabled={this.props.loadingSubmit}
          callbackChangeAuthType={(authType) => this.setState({ authType })}
          callbackChangePhone={(phone) => this.setState({ phone })}
          callbackSubmit={(values, authType) =>
            this.handleSubmit(values, authType)
          }
          callbackError={() => this.setState({ isMsisdnError: true })}
          callbackErrorContract={() => this.setState({ isContractError: true })}
        />
      );
    }
  }

  render() {
    return (
      <div id="login-msisdn-page">
        <div className="container">
          <AuthHeaderBottom />
          <AuthHeaderMobile pageTitle={this.getPageTitle()} />
          <div className="page-content pb-5">
            <div className="d-flex flex-column d-lg-none align-items-center my-auto content-mobile">
              <FbCard />
              {this.renderContent()}
              {this.renderPaymentInfo()}
            </div>

            <div className="d-none d-lg-flex my-auto content-desktop">
              <div className="col-6 mr-3">
                <PageTitle title={this.getPageTitle()} />
                {this.renderContent()}
              </div>
              <div className="col-6 ml-3 my-auto">
                <FbCard isBG={true} />
                {this.renderPaymentInfo()}
              </div>
            </div>
          </div>
        </div>
        {this.state.authType === AuthType.login && <Privileges />}

        <div
          className={`${
            this.state.authType === AuthType.signup ? "mt-auto" : ""
          }`}
        >
          <AuthFooter />
        </div>

        <GenericModal
          isVisible={!!this.getError()}
          type={ModalType.error}
          title={Constants.errorGenericTitle}
          text={this.getError() ?? ""}
          callbackOk={() => this.resetErrors()}
          callbackCancel={() => this.resetErrors()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      mobilePaymentSetCreditCardRedirect,
      loginMsisdnSubmit,
      loginCodeSubmit,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  const state = store.loginMsisdn;
  return {
    loadingSubmit: state.submit.loading,
    dataSubmit: state.submit.data,
    errorSubmit: state.submit.error,
    loadingCode: state.code.loading,
    dataCode: state.code.data,
    errorCode: state.code.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginMsisdnPage);
