import { Dispatch } from "redux";
import {
  COPY_CARD_EVENT_FAIL,
  COPY_CARD_EVENT_START,
  COPY_CARD_EVENT_SUCCESS,
} from "../constants/job";
import { ErrorDto, getErrorDtoFromApiError } from "../models/dtos/error.dto";
import JobService from "../services/job.service";
import {
  CopyCardEventFailAction,
  CopyCardEventStartAction,
  CopyCardEventSuccessAction,
} from "../types/job";

const jobService: JobService = new JobService();

const copyCardEventStartAction = (): CopyCardEventStartAction => {
  return {
    type: COPY_CARD_EVENT_START,
  };
};
const copyCardEventSuccessAction = (
  payload: boolean
): CopyCardEventSuccessAction => {
  return {
    type: COPY_CARD_EVENT_SUCCESS,
    payload,
  };
};
const copyCardEventFailAction = (error: ErrorDto): CopyCardEventFailAction => {
  return {
    type: COPY_CARD_EVENT_FAIL,
    error: error,
  };
};
export const copyCardEvent = () => async (dispatch: Dispatch) => {
  try {
    dispatch(copyCardEventStartAction());
    await jobService.copyCardEvent();
    dispatch(copyCardEventSuccessAction(true));
  } catch (error) {
    dispatch(copyCardEventFailAction(getErrorDtoFromApiError(error)));
  }
};
