import axios from "../helpers/axios";
import {PollDto} from "../models/dtos/poll.dto";
import {PollAnswerRequest} from "../models/requests/pollAnswer.request";

export default class PollService {

  async getActivePoll(): Promise<PollDto[]> {
    try {
      const response = await axios.get<PollDto[]>(
        `${process.env.REACT_APP_BASE_URL}/poll/active`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async postAnswer(quizId: number, request: PollAnswerRequest): Promise<PollDto> {
    try {
      const response = await axios.post<PollDto>(
        `${process.env.REACT_APP_BASE_URL}/poll/${quizId}`,
        {...request},
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getHistory(): Promise<PollDto[]> {
    try {
      const response = await axios.get<PollDto[]>(
        `${process.env.REACT_APP_BASE_URL}/poll/history`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
