import { Dispatch } from "redux";
import {
  PAY_BY_ME_VPOS_PAYMENT_FAIL,
  PAY_BY_ME_VPOS_PAYMENT_RESET,
  PAY_BY_ME_VPOS_PAYMENT_START,
  PAY_BY_ME_VPOS_PAYMENT_SUCCESS
} from "../constants/payByMe";
import { Constants } from "../helpers/constants";
import { history } from "../helpers/history";
import { router } from "../helpers/router";
import { Utilities } from "../helpers/utilities";
import { ErrorDto, getErrorDtoFromApiError } from "../models/dtos/error.dto";
import { VposPaymentDto } from "../models/dtos/vposPayment.dto";
import AuthService from "../services/auth.service";
import PaymentService from "../services/payment.service";
import {
  PayByMeVposPaymentFailAction,
  PayByMeVposPaymentResetAction,
  PayByMeVposPaymentStartAction,
  PayByMeVposPaymentSuccessAction
} from "../types/payByMe";
import { setAppVisibilityAction } from "./app";

const authService: AuthService = new AuthService();
const paymentService: PaymentService = new PaymentService();

const payByMeVposPaymentStartAction = (): PayByMeVposPaymentStartAction => {
  return {
    type: PAY_BY_ME_VPOS_PAYMENT_START,
  };
}
const payByMeVposPaymentSuccessAction = (payload: string): PayByMeVposPaymentSuccessAction => {
  return {
    type: PAY_BY_ME_VPOS_PAYMENT_SUCCESS,
    payload: payload,
  };
}
const payByMeVposPaymentFailAction = (error: ErrorDto): PayByMeVposPaymentFailAction => {
  return {
    type: PAY_BY_ME_VPOS_PAYMENT_FAIL,
    error: error,
  };
}
export const payByMeGetVposPayment = (isYearly: boolean, captcha?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(payByMeVposPaymentStartAction());
    const response: VposPaymentDto = await paymentService.startVposPayment(isYearly);
    dispatch(payByMeVposPaymentSuccessAction(response.redirectUrl));
  } catch (error) {
    const errorDto = getErrorDtoFromApiError(error);
    if (errorDto.status === 400) {
      if (errorDto.message === Constants.errorCodeUserActive) {
        await authService.refresh();
        dispatch(setAppVisibilityAction(true));
      } else if (errorDto.message === Constants.errorCodeOtpRequired) {
        const msisdn: string | null = Utilities.getMsisdnFromToken();
        if (msisdn) {
          await authService.sendOtp({ msisdn, captcha })
            .then(() => history.push(router.LOGIN_OTP))
            .catch(() => history.push(router.LOGIN_MSISDN));
        }
      }
    }
    dispatch(payByMeVposPaymentFailAction(errorDto));
  }
};

const payByMeVposPaymentResetAction = (): PayByMeVposPaymentResetAction => {
  return {
    type: PAY_BY_ME_VPOS_PAYMENT_RESET,
  };
}
export const payByMeResetVposPayment = () => (dispatch: Dispatch) => {
  dispatch(payByMeVposPaymentResetAction());
};
