import axios from "../helpers/axios";
import {UserMeDto} from "../models/dtos/userMe.dto";
import {UserMeUpdateRequest} from "../models/requests/userMeUpdate.request";

export default class UserService {

  async getDetails(): Promise<UserMeDto> {
    try {
      const response = await axios.get<UserMeDto>(
        `${process.env.REACT_APP_BASE_URL}/users/me`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateDetails(request: UserMeUpdateRequest): Promise<void> {
    try {
      const response = await axios.patch<void>(
        `${process.env.REACT_APP_BASE_URL}/users/me`,
        {...request},
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async confirmEmail(hash: string): Promise<void> {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/confirm-email?hash=${hash}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addPushToken(pushToken: string): Promise<void> {
    try {
      const response = await axios.post<void>(
        `${process.env.REACT_APP_BASE_URL}/users/me/push-token?pushToken=${pushToken}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async resendConfirmationEmail(): Promise<void> {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/resend-confirmation-email`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
