import parse from "html-react-parser";
import React, {ReactNode} from "react";
import {PollDto} from "../../../../core/models/dtos/poll.dto";
import {PollOptionDto} from "../../../../core/models/dtos/pollOption.dto";
import FirebaseService from "../../../../core/services/firebase.service";
import "./PollResult.scss";

interface IProps {
  data: PollDto;
  isCounter: boolean;
}

const PollResult = (props: IProps) => {

  const renderOption = (option: PollOptionDto): ReactNode => {
    const percentage = ((option.percentage ?? 0) * 100).toFixed(2);
    const percentageDelta = percentage + 1;
    return (
      <div
        key={`option-${option.id}`}
        className="poll-option no-select"
        style={{background: `linear-gradient(90deg, #ffed00 0%, #ffed00 ${percentage}%, transparent ${percentageDelta}%, transparent 100%)`}}
      >
        <div className={`option ${props.data.answerId === option.id ? 'selected' : ''}`}/>
        <span>
          {option.text === 'Other' ? FirebaseService.getValue(FirebaseService.option_other) : option.text}
        </span>
        <span className="percentage">
          {percentage === '0.00' ? '0' : percentage}%
        </span>
      </div>
    );
  }

  return (
    <div
      id="poll-result"
      key={`result-${props.data.id}`}
    >
      <div className="poll-name">
        {props.data.name}
      </div>
      <p className="poll-question">
        {props.data.text}
      </p>
      <div className="poll-options">
        {props.data.options.map(renderOption)}
      </div>
      {
        props.isCounter &&
        (
          props.data.description
            ?
            <div className="mt-4">
              {parse(props.data.description)}
            </div>
            :
            <div className="poll-counter">
              YENİ SORUYA SON
              <span className="days">{props.data.daysRemaining} Gün</span>
            </div>
        )
      }
    </div>
  );
}

export default PollResult;
