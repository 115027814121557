import axios from "../helpers/axios";
import {CampaignDto} from "../models/dtos/campaign.dto";

export default class CampaignService {

  async getAllCampaigns(): Promise<CampaignDto[]> {
    try {
      const response = await axios.get<CampaignDto[]>(
        `${process.env.REACT_APP_BASE_URL}/campaigns`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getActiveCampaigns(): Promise<CampaignDto[]> {
    try {
      const response = await axios.get<CampaignDto[]>(
        `${process.env.REACT_APP_BASE_URL}/campaigns/active`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getCampaign(id: number): Promise<CampaignDto> {
    try {
      const response = await axios.get<CampaignDto>(
        `${process.env.REACT_APP_BASE_URL}/campaigns/${id}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
