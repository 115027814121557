import { message } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import copy from "../../assets/images/copy.svg";
import { copyCardEvent } from "../../core/actions/job";
import { UserMeDto } from "../../core/models/dtos/userMe.dto";
import { IStore } from "../../core/reducers";
import FirebaseService from "../../core/services/firebase.service";
import "./UserFanCard.scss";

interface IProps {
  userMe?: UserMeDto;
  copyCardEvent: () => void;
}

const UserFanCard = (props: IProps) => {
  const onCopy = (): void => {
    FirebaseService.fireEvent(FirebaseService.event_taraftar_card_no_copy);
    props.copyCardEvent();
    message.info({
      className: "copy-message",
      style: { marginTop: "20px" },
      icon: (
        <img
          src={copy}
          alt="copy"
          style={{ width: "15px", paddingBottom: "4px", marginRight: "4px" }}
        />
      ),
      content: "Kart numaran kopyalandı.",
      duration: 2,
    });
  };

  return (
    <div id="user-fan-card">
      <img
        src="https://s3-eu-west-1.amazonaws.com/static.4p/fenerbahce-taraftar-kart-v.png"
        alt="fan-card"
      />
      <div className="user-info-wrapper">
        <span className="fullName">
          {props.userMe?.firstName?.toUpperCase()}&nbsp;
          {props.userMe?.lastName?.toUpperCase()}
        </span>
        <span className="cardNumber">{props.userMe?.cardNumber}</span>
      </div>
      <div className="copy-wrapper no-select">
        <CopyToClipboard text={props.userMe?.cardNumber ?? "-"} onCopy={onCopy}>
          <div>
            <img className="copy" src={copy} alt="copy" />
            <span>KOPYALA</span>
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      copyCardEvent,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFanCard);
