import React from "react";
import "./AuthHeader.scss";

interface IProps {
  error?: string
}

const AuthHeader = (props: IProps) => {

  return (
    <React.Fragment>
      <div id="auth-header">
        {
          props.error &&
          <div className="error">
            <span>{props.error}</span>
          </div>
        }
        <h1 className="title">
          FENERBAHÇE TARAFTAR KART
        </h1>
        <p className="sub-title">
          Fenerbahçe SK Resmi Dijital Kulüp Üyeliği
        </p>
      </div>

    </React.Fragment>
  );
}

export default AuthHeader;
