import {Button, message, Popover, Spin, Upload} from "antd";
import {RcFile, UploadChangeParam} from "antd/es/upload";
import React, {ReactNode, useState} from "react";
import {connect} from "react-redux";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";
import {bindActionCreators, Dispatch} from "redux";
import buttonContinue from "../../assets/images/personality-small-continue.svg";
import buttonAgain from "../../assets/images/personality-small-refresh.svg";
import buttonShare from "../../assets/images/personality-small-share.svg";
import download from "../../assets/images/test-download.svg";
import placeholder from "../../assets/images/test-image-placeholder.png";
import imageClose from "../../assets/images/x.svg";
import {resetAvatar, setUserMe} from "../../core/actions/app";
import axios from "../../core/helpers/axios";
import {Constants} from "../../core/helpers/constants";
import {history} from "../../core/helpers/history";
import {router} from "../../core/helpers/router";
import {Utilities} from "../../core/helpers/utilities";
import {PersonalityDto} from "../../core/models/dtos/personality.dto";
import {UserMeDto} from "../../core/models/dtos/userMe.dto";
import {IStore} from "../../core/reducers";
import FirebaseService from "../../core/services/firebase.service";
import "./PersonalitySmall.scss";

interface IProps {
  userMe?: UserMeDto;
  avatarUrl?: string;
  activeQuizId?: number;
  personality?: PersonalityDto;
  setUserMe: (userMe: UserMeDto) => void;
  resetAvatar: () => void;
}

const PersonalitySmall = (props: IProps) => {
  const [imageLoading, setImageLoading] = useState<boolean>(false);

  const beforeUpload = (file: RcFile): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Dosya JPG/PNG olmalı!');
    }
    const isLt3M = file.size / 1024 / 1024 < 2;
    if (!isLt3M) {
      message.error('Dosya boyutu en fazla 2MB olmalı!');
    }
    return isJpgOrPng && isLt3M;
  }

  const uploadFile = async (options: any) => {
    const {onSuccess, file} = options;
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("file", file);
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/personality/create-avatar`,
      formData,
      config
    )
      .then((res) => {
        FirebaseService.fireEvent(FirebaseService.event_quiz_count_upload_pic);
        onSuccess(res.data);
      })
      .catch(() => setImageLoading(false));
  };

  const getShareUrl = (isDownload: boolean = false): string => {
    if (props.userMe?.avatarUrl) {
      return props.userMe?.avatarUrl;
    }
    if (isDownload) {
      return Constants.fenerLogo;
    }
    return Constants.shareUrl;
  }

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === 'uploading') {
      setImageLoading(true);
      return;
    }
    setImageLoading(false);
    if (info.file.status === 'done') {
      const userMe = props.userMe;
      if (userMe) {
        userMe.avatarUrl = info.file.response;
        props.setUserMe(userMe);
      }
    }
  };

  const handleDeleteAvatar = (): void => {
    props.resetAvatar();
  }

  const handleTest = (): void => {
    history.push(router.TEST);
  }

  const shareButtonFacebook = (): ReactNode => {
    return (
      <FacebookShareButton
        className="share-button"
        url={`${props.userMe?.avatarUrl ?? Constants.shareUrl}`}
        quote={Utilities.getSocialShareText(props.personality)}
        onClick={() => FirebaseService.fireEvent(FirebaseService.event_quiz_count_share_facebook_button_clicked)}
      >
        <FacebookIcon size={32} round/>
      </FacebookShareButton>
    );
  }

  const shareButtonTwitter = (): ReactNode => {
    return (
      <TwitterShareButton
        className="share-button"
        url={`${props.userMe?.avatarUrl ?? Constants.shareUrl}`}
        title={Utilities.getSocialShareTextTwitter(props.personality)}
        onClick={() => FirebaseService.fireEvent(FirebaseService.event_quiz_count_share_twitter_button_clicked)}
      >
        <TwitterIcon size={32} round/>
      </TwitterShareButton>
    );
  }

  const shareContent = (): ReactNode => {
    return (
      <div className="share-content">
        {shareButtonFacebook()}
        {shareButtonTwitter()}
        <a
          className="share-icon ml-2"
          href={getShareUrl(true)}
          target="_blank"
          rel="noreferrer"
        >
          <img className="download" src={download} alt="download"/>
        </a>
      </div>
    );
  }

  const renderAvatar = (): ReactNode => {
    return (
      props.userMe?.avatarUrl
        ?
        <div className="upload-wrapper">
          <Upload
            listType="picture"
            className="avatar-uploader"
            accept="image/png, image/jpg"
            showUploadList={false}
            customRequest={uploadFile}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            <div
              key={props.avatarUrl ?? 'avatar'}
              className="avatar"
              style={{
                background: `url(${props.avatarUrl ?? placeholder})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top',
              }}
            />
            {imageLoading && <Spin className="avatar image-spinner" size="large"/>}
            <div className="upload-tip">
              <div className="content">
                <span className="text">Fotoğrafı Değiştir</span>
              </div>
            </div>
          </Upload>
          <img
            className="delete-avatar"
            src={imageClose}
            alt="close"
            onClick={handleDeleteAvatar}
          />
        </div>
        :
        <div
          className="avatar"
          style={{
            background: `url(${props.userMe?.avatarUrl ?? Constants.fenerLogo})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
          }}
        >
        </div>
    );
  }
  const renderStart = (): ReactNode => {
    return (
      <React.Fragment>
        <p className="personality-description">
          Kişilik testini çöz,
          <br/>
          nasıl bir Fenerbahçeli olduğunu öğren!
        </p>
        <Button
          className="personality-button button-solid fix-width"
          onClick={handleTest}
        >
          HEMEN ÖĞREN
        </Button>
      </React.Fragment>
    );
  }

  const renderContinue = (): ReactNode => {
    return (
      <React.Fragment>
        <p className="personality-description">
          Kişilik testin yarım kalmış.
          <br/>
          Teste devam et, nasıl bir Fenerbahçeli olduğunu öğren!
        </p>
        <Button
          className="personality-button button-solid fix-width"
          onClick={handleTest}
        >
          DEVAM ET
          <img className="button-image continue" src={buttonContinue} alt="continue"/>
        </Button>
      </React.Fragment>
    );
  }

  const renderAgain = (): ReactNode => {
    return (
      <div className="render-again">
        {renderAvatar()}
        <div className="d-flex flex-column justify-content-center ml-2">
          <p
            className="personality-title"
            onClick={Utilities.navigateToTestResultPage}
          >
            {props.personality?.title}
          </p>
          <div className="button-wrapper">
            <Button
              className="personality-button button-outline"
              onClick={handleTest}
            >
              <img className="button-image" src={buttonAgain} alt="again"/>
            </Button>
            <Popover
              className="share-popover"
              placement="top"
              content={shareContent}
              trigger="click"
            >
              <Button
                className="personality-button button-solid"
              >
                <img className="button-image" src={buttonShare} alt="share"/>
                PAYLAŞ
              </Button>
            </Popover>
          </div>
        </div>
      </div>
    );
  }

  const renderContent = (): ReactNode => {
    if (!props.personality) {
      return renderStart();
    } else if (props.activeQuizId) {
      return renderContinue();
    } else if (props.personality) {
      return renderAgain();
    }
    return <React.Fragment/>;
  }

  return (
    <div id="personality-small">
      <div
        className="personality-heading"
        onClick={Utilities.navigateToTestResultPage}
      >
        Sen Nasıl Bir Fenerbahçelisin?
      </div>
      {renderContent()}
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setUserMe,
      resetAvatar,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    userMe: store.app.userMe.data,
    avatarUrl: store.app.userMe.data?.avatarUrl,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalitySmall);
