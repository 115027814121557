import { Spin } from "antd";
import React, { ReactNode } from "react";
import Iframe from "react-iframe";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import AuthFooter from "../../../../components/Auth/AuthFooter/AuthFooter";
import AuthHeaderBottom from "../../../../components/Auth/AuthHeaderBottom/AuthHeaderBottom";
import AuthHeaderMobile from "../../../../components/Auth/AuthHeaderMobile/AuthHeaderMobile";
import Privileges from "../../../../components/Auth/Privileges/Privileges";
import SubscriptionFail from "../../../../components/Auth/SubscriptionFail/SubscriptionFail";
import {
  logout,
  setAppVisibility,
  setUserMe,
} from "../../../../core/actions/app";
import {
  payByMeGetVposPayment,
  payByMeResetVposPayment,
} from "../../../../core/actions/payByMe";
import { Constants } from "../../../../core/helpers/constants";
import { history } from "../../../../core/helpers/history";
import { router } from "../../../../core/helpers/router";
import {
  getStorage,
  removeStorage,
  storageRecaptcha,
  storageVposResult,
} from "../../../../core/helpers/storage";
import { Utilities } from "../../../../core/helpers/utilities";
import { ErrorDto } from "../../../../core/models/dtos/error.dto";
import { UserMeDto } from "../../../../core/models/dtos/userMe.dto";
import { SubscriptionState } from "../../../../core/models/enums/subscriptionState";
import { IStore } from "../../../../core/reducers";
import AuthService from "../../../../core/services/auth.service";
import FirebaseService from "../../../../core/services/firebase.service";
import UserService from "../../../../core/services/user.service";
import { SubscriptionPeriod } from "../../SubscriptionPage/SubscriptionPage";
import "./PayByMePage.scss";

interface IProps {
  match: any;
  loginMsisdn?: string;
  loginMsisdnSubmitData: boolean;
  loadingVpos: boolean;
  dataVpos?: string;
  errorVpos?: ErrorDto;
  setUserMe: (userMe: UserMeDto) => void;
  payByMeGetVposPayment: (isYearly: boolean, captcha?: string) => void;
  payByMeResetVposPayment: () => void;
  setAppVisibility: (isAppVisible: boolean) => void;
  logout: () => void;
}

interface IState {
  intervalIdPaymentListener?: NodeJS.Timeout;
}

class PayByMePage extends React.Component<IProps> {
  state: IState = {
    intervalIdPaymentListener: undefined,
  };

  private readonly paymentListenerIntervalMs = 1000;
  private readonly subscriptionPeriod: string | undefined;
  private authService: AuthService = new AuthService();
  private userService: UserService = new UserService();

  constructor(props: IProps) {
    super(props);
    this.subscriptionPeriod = this.props.match.params.subscriptionPeriod;
  }

  componentDidMount() {
    this.startVposPayment();
  }

  private startVposPayment(): void {
    const isYearly = this.subscriptionPeriod === SubscriptionPeriod.yearly;
    const recaptcha = getStorage(storageRecaptcha) || undefined;
    this.props.payByMeGetVposPayment(isYearly, recaptcha);
    this.setPaymentListener();
  }

  private setPaymentListener(): void {
    removeStorage(storageVposResult);
    const intervalIdPaymentListener = setInterval(() => {
      const vposResult: string | null = getStorage(storageVposResult);
      if (vposResult) {
        this.clearPaymentListener();
        if (vposResult === Constants.vposResultSuccess) {
          this.handleVposSuccess();
        } else if (vposResult === Constants.vposResultFailed) {
          this.handleVposFail();
        }
      }
    }, this.paymentListenerIntervalMs);
    this.setState({ intervalIdPaymentListener });
  }

  // todo REDUX
  private async handleVposSuccess(): Promise<void> {
    // const userMeDto = await this.userService.getDetails();
    // this.props.setUserMe(userMeDto);
    // await this.authService.refresh();
    // this.props.setAppVisibility(true);

    while (true) {
      const userMeDto = await this.userService.getDetails();
      if (userMeDto.subscriptionState === SubscriptionState.ACTIVE) {
        this.props.setUserMe(userMeDto);
        await this.authService.refresh();
        this.props.setAppVisibility(true);
        break;
      } else {
        await Utilities.wait();
      }
    }
  }

  private isChangePaymentMethodAvailable(): boolean {
    const msisdn =
      this.props.loginMsisdn ?? Utilities.getMsisdnFromToken() ?? "";
    return !(
      !Utilities.isMsisdnTurkish(msisdn) ||
      (Utilities.isMsisdnTurkish(msisdn) &&
        this.subscriptionPeriod === SubscriptionPeriod.yearly)
    );
  }

  private isSubscriptionFail(): boolean {
    return !this.props.loadingVpos && !this.props.dataVpos;
  }

  private handleVposFail(): void {
    this.props.payByMeResetVposPayment();
  }

  private clearPaymentListener(): void {
    if (this.state.intervalIdPaymentListener) {
      clearInterval(this.state.intervalIdPaymentListener);
    }
  }

  private handleRetry(): void {
    FirebaseService.fireEvent(
      FirebaseService.event_error_count_view_check_again_button_clicked
    );
    this.startVposPayment();
  }

  private handleLogout(): void {
    if (this.props.loginMsisdnSubmitData) {
      FirebaseService.fireEvent(
        FirebaseService.event_error_count_view_return_login_button_clicked
      );
    } else {
      FirebaseService.fireEvent(
        FirebaseService.event_payment_count_return_login_button_clicked
      );
    }
    this.props.logout();
    history.push(router.LOGIN_MSISDN);
  }

  private renderContent(): ReactNode {
    if (this.props.loadingVpos) {
      return <Spin size="large" />;
    } else if (this.props.dataVpos) {
      return (
        <Iframe
          id="vpos-iframe"
          url={this.props.dataVpos}
          width="350px"
          height="455px"
          position="relative"
        />
      );
    }
    return (
      <SubscriptionFail
        // todo revert back to original logic (Mobile Payment) (PLAY-2541)
        isChangePaymentMethodAvailable={this.isChangePaymentMethodAvailable()}
        // isChangePaymentMethodAvailable={false}
        errorText="Bir sorun oluştu. Lütfen tekrar dene veya Mobil Ödeme ile devam et"
        // errorText="Bir sorun oluştu. Lütfen tekrar dene"
        textChangePaymentMethod="MOBİL ÖDEME İLE DEVAM ET"
        callbackRetry={() => this.handleRetry()}
        callbackChangePaymentMethod={() => history.push(router.MOBILE_PAYMENT)}
        callbackLogout={() => this.handleLogout()}
      />
    );
  }

  render() {
    return (
      <div id="pay-by-me-page">
        <div className="container">
          <AuthHeaderBottom />
          <AuthHeaderMobile
            isBackArrow={!this.isSubscriptionFail()}
            pageTitle="ABONE OL"
          />
          <div className="page-content">{this.renderContent()}</div>
        </div>
        {this.isSubscriptionFail() && (
          <React.Fragment>
            <Privileges />
            <AuthFooter />
          </React.Fragment>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    this.clearPaymentListener();
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setUserMe,
      payByMeGetVposPayment,
      payByMeResetVposPayment,
      setAppVisibility,
      logout,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  const state = store.payByMe;
  return {
    loginMsisdn: store.loginMsisdn.msisdn,
    loginMsisdnSubmitData: store.loginMsisdn.submit.data,
    loadingVpos: state.vposPayment.loading,
    dataVpos: state.vposPayment.data,
    errorVpos: state.vposPayment.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayByMePage);
