import Layout, {Content} from "antd/lib/layout/layout";
import React from "react";
import "./AuthLayout.scss";

class AuthLayout extends React.Component<{}> {
  render() {
    return (
      <div id="auth-layout">
        <Layout>
          <Content>
            {this.props.children}
          </Content>
        </Layout>
      </div>
    );
  }
}

export default AuthLayout;
