import Adjust from "@adjustcom/adjust-web-sdk";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import "./App.scss";
import ApiErrorModal from "./components/Modals/ApiErrorModal/ApiErrorModal";
import AboutUsPage from "./containers/AboutUsPage/AboutUsPage";
import AppLayout from "./containers/AppLayout/AppLayout";
import AuthLayout from "./containers/Auth/AuthLayout/AuthLayout";
import ContactUsPage from "./containers/Auth/ContactUsPage/ContactUsPage";
import FaqAnonPage from "./containers/Auth/FaqAnonPage/FaqAnonPage";
import LandingPage from "./containers/Auth/LandingPage/LandingPage";
import LoginMsisdnPage from "./containers/Auth/Login/LoginMsisdnPage/LoginMsisdnPage";
import LoginOtpPage from "./containers/Auth/Login/LoginOtpPage/LoginOtpPage";
import LoginSubscriptionPage from "./containers/Auth/Login/LoginSubscriptionPage/LoginSubscriptionPage";
import MobilePaymentPage from "./containers/Auth/MobilePaymentPage/MobilePaymentPage";
import PayByMeFailedPage from "./containers/Auth/PayByMe/PayByMeFailedPage/PayByMeFailedPage";
import PayByMePage from "./containers/Auth/PayByMe/PayByMePage/PayByMePage";
import PayByMeSuccessPage from "./containers/Auth/PayByMe/PayByMeSuccessPage/PayByMeSuccessPage";
import SignupInfoPage from "./containers/Auth/Signup/SignupInfoPage/SignupInfoPage";
import SignupMsisdnPage from "./containers/Auth/Signup/SignupMsisdnPage/SignupMsisdnPage";
import SubscriptionPage from "./containers/Auth/SubscriptionPage/SubscriptionPage";
import CampaignPage from "./containers/CampaignsPage/CampaignPage/CampaignPage";
import CampaignsPage from "./containers/CampaignsPage/CampaignsPage";
import ContactUsPrivatePage from "./containers/ContactUsPrivatePage/ContactUsPrivatePage";
import EmailConfirmPage from "./containers/EmailConfirmPage/EmailConfirmPage";
import FaqPage from "./containers/FaqPage/FaqPage";
import GiftPage from "./containers/GiftPage/GiftPage";
import HomePage from "./containers/HomePage/HomePage";
import InviteFriendsPage from "./containers/InviteFriendsPage/InviteFriendsPage";
import MenuPage from "./containers/MenuPage/MenuPage";
import NotificationsPage from "./containers/NotificationsPage/NotificationsPage";
import PhotoGalleryPage from "./containers/PhotoGalleryPage/PhotoGalleryPage";
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import TestPage from "./containers/TestPage/TestPage";
import {
  codeSubmit,
  fetchAndActivateRemoteConfig,
  fetchUserMe,
  setApiError,
  setPathname,
  setVotingAvailability,
} from "./core/actions/app";
import axios from "./core/helpers/axios";
import { history } from "./core/helpers/history";
import { router } from "./core/helpers/router";
import { getStorage, setStorage, storageToken } from "./core/helpers/storage";
import { Utilities } from "./core/helpers/utilities";
import {
  ErrorDto,
  ErrorGeneric,
  getErrorDtoFromApiError,
} from "./core/models/dtos/error.dto";
import { UserMeDto } from "./core/models/dtos/userMe.dto";
import { SubscriptionState } from "./core/models/enums/subscriptionState";
import { IStore } from "./core/reducers";
import FirebaseService from "./core/services/firebase.service";
import GTMService from "./core/services/gtm.service";
import WheelPage from "./containers/WheelPage/WheelPage";

interface IProps {
  loadingFirebase: boolean;
  pathname: string;
  loadingCode: boolean;
  loadingUserMe: boolean;
  dataUserMe?: UserMeDto;
  errorUserMe?: ErrorDto;
  isAppVisible: boolean;
  fetchAndActivateRemoteConfig: () => void;
  setPathname: (pathname: string) => void;
  codeSubmit: (code: string, route: string) => void;
  fetchUserMe: () => void;
  setVotingAvailability: (isAvailable: boolean) => void;
  setApiError: (errorDto: ErrorDto) => void;
}

interface IState {
  callbackHistoryUnregister?: VoidFunction;
}

class App extends React.Component<IProps> {
  state: IState = {
    callbackHistoryUnregister: undefined,
  };

  constructor(props: IProps) {
    super(props);
    GTMService.init();
    this.setAdjust();
    this.setAxiosInterceptor();
    this.setVotingAvailability();
    this.props.fetchAndActivateRemoteConfig();
  }

  componentDidMount() {
    this.setHistoryListener();
    const code = Utilities.getQueryParam("code");
    const hash = history.location.hash; // can be empty
    if (code) {
      const route = hash.replace("#", "");
      this.props.codeSubmit(code, route);
    } else {
      if (getStorage(storageToken)) {
        this.props.fetchUserMe();
        FirebaseService.getFcmToken(true);
      } else {
        FirebaseService.getFcmToken(false);
      }
    }
  }

  private setAdjust(): void {
    Adjust.initSdk({
      appToken: `${process.env.REACT_APP_ADJUST_APP_TOKEN}`,
      environment: Utilities.isEnvProd() ? "production" : "sandbox",
    });
  }

  private setAxiosInterceptor(): void {
    axios.interceptors.response.use(
      (response) => {
        const setToken: string = response.headers["set-token"];
        if (setToken) {
          setStorage(storageToken, setToken);
        }
        return response;
      },
      async (error) => {
        if (!error.response || error.response.status === 500) {
          this.props.setApiError(ErrorGeneric);
        } else {
          const errorDto = getErrorDtoFromApiError(error);
          this.props.setApiError(errorDto);
        }
        return Promise.reject(error);
      }
    );
  }

  private setVotingAvailability(): void {
    const isAvailable: boolean =
      `${process.env.REACT_APP_IS_VOTING_AVAILABLE}` === "true";
    this.props.setVotingAvailability(isAvailable);
  }

  private setHistoryListener(): void {
    const callbackHistoryUnregister = history.listen(
      (location: any, action: any) => {
        this.props.setPathname(location.pathname);
        GTMService.pageView();
      }
    );
    this.setState({ callbackHistoryUnregister });
  }

  private isAppLoading(): boolean {
    return (
      this.props.loadingFirebase ||
      this.props.loadingCode ||
      this.props.loadingUserMe
    );
  }

  private isAppLayoutVisible(): boolean {
    return (
      this.props.dataUserMe?.subscriptionState === SubscriptionState.ACTIVE &&
      this.props.isAppVisible
    );
  }

  render() {
    return this.isAppLoading() ? (
      <React.Fragment />
    ) : (
      <Router history={history}>
        {this.isAppLayoutVisible() ? (
          <AppLayout pathname={this.props.pathname}>
            <Switch>
              <Route exact path={router.HOME} component={HomePage} />
              <Route exact path={router.GIFT} component={GiftPage} />
              <Route exact path={router.PROFILE} component={ProfilePage} />
              <Route exact path={router.ABOUT_US} component={AboutUsPage} />
              <Route exact path={router.MENU} component={MenuPage} />
              <Route exact path={router.FAQ} component={FaqPage} />
              <Route
                exact
                path={router.CONTACT_US}
                component={ContactUsPrivatePage}
              />
              <Route
                exact
                path={router.INVITE_FRIENDS}
                component={InviteFriendsPage}
              />
              <Route exact path={router.CAMPAIGNS} component={CampaignsPage} />
              <Route
                exact
                path={router.NOTIFICATIONS}
                component={NotificationsPage}
              />
              <Route
                exact
                path={router.PHOTO_GALLERY}
                component={PhotoGalleryPage}
              />
              <Route
                exact
                path={router.EMAIL_CONFIRM}
                component={EmailConfirmPage}
              />
              <Route exact path={router.TEST} component={TestPage} />
              <Route
                exact
                path={`${router.CAMPAIGNS}/:id`}
                component={CampaignPage}
              />
              <Route exact path={router.WHEEL} component={WheelPage} />
              <Redirect to={router.HOME} />
            </Switch>
            <ApiErrorModal />
          </AppLayout>
        ) : (
          <AuthLayout>
            <Switch>
              <Route exact path={router.LANDING} component={LandingPage} />
              <Route
                exact
                path={router.LOGIN_MSISDN}
                component={LoginMsisdnPage}
              />
              <Route exact path={router.LOGIN_OTP} component={LoginOtpPage} />
              <Route
                exact
                path={router.LOGIN_SUBSCRIPTION}
                component={LoginSubscriptionPage}
              />
              <Route
                exact
                path={router.SIGNUP_INFO}
                component={SignupInfoPage}
              />
              <Route
                exact
                path={router.SIGNUP_MSISDN}
                component={SignupMsisdnPage}
              />
              <Route
                exact
                path={router.SUBSCRIPTION}
                component={SubscriptionPage}
              />
              <Route
                exact
                path={`${router.SUBSCRIPTION}/:subscriptionPeriod`}
                component={SubscriptionPage}
              />
              <Route
                exact
                path={router.MOBILE_PAYMENT}
                component={MobilePaymentPage}
              />
              <Route exact path={router.PAY_BY_ME} component={PayByMePage} />
              <Route
                exact
                path={`${router.PAY_BY_ME}/:subscriptionPeriod`}
                component={PayByMePage}
              />
              <Route
                exact
                path={router.PAY_BY_ME_SUCCESS}
                component={PayByMeSuccessPage}
              />
              <Route
                exact
                path={router.PAY_BY_ME_FAILED}
                component={PayByMeFailedPage}
              />
              <Route
                exact
                path={router.EMAIL_CONFIRM}
                component={EmailConfirmPage}
              />
              <Route exact path={router.CONTACT_US} component={ContactUsPage} />
              <Route exact path={router.FAQ} component={FaqAnonPage} />
              <Redirect to={router.LANDING} />
            </Switch>
          </AuthLayout>
        )}
      </Router>
    );
  }

  componentWillUnmount() {
    if (this.state.callbackHistoryUnregister) {
      this.state.callbackHistoryUnregister();
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchAndActivateRemoteConfig,
      setPathname,
      codeSubmit,
      fetchUserMe,
      setVotingAvailability,
      setApiError,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    loadingFirebase: store.app.loadingFirebase,
    pathname: store.app.pathname,
    loadingCode: store.app.code.loading,
    loadingUserMe: store.app.userMe.loading,
    dataUserMe: store.app.userMe.data,
    errorUserMe: store.app.userMe.error,
    isAppVisible: store.app.isAppVisible,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
