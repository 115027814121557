import {Dispatch} from "redux";
import {EMAIL_CONFIRM_FETCH_FAIL, EMAIL_CONFIRM_FETCH_START, EMAIL_CONFIRM_FETCH_SUCCESS} from "../constants/emailConfirm";
import { history } from "../helpers/history";
import { router } from "../helpers/router";
import {ErrorDto, getErrorDtoFromApiError} from "../models/dtos/error.dto";
import UserService from "../services/user.service";
import {
  EmailConfirmFetchFailAction,
  EmailConfirmFetchStartAction,
  EmailConfirmFetchSuccessAction,
} from "../types/emailConfirm";

const userService: UserService = new UserService();

const emailConfirmFetchStartAction = (): EmailConfirmFetchStartAction => {
  return {
    type: EMAIL_CONFIRM_FETCH_START,
  }
}
const emailConfirmFetchSuccessAction = (): EmailConfirmFetchSuccessAction => {
  return {
    type: EMAIL_CONFIRM_FETCH_SUCCESS,
  }
}
const emailConfirmFetchFailAction = (error: ErrorDto): EmailConfirmFetchFailAction => {
  return {
    type: EMAIL_CONFIRM_FETCH_FAIL,
    error: error,
  }
}
export const fetchEmailConfirm = (hash: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(emailConfirmFetchStartAction());
    await userService.confirmEmail(hash);
    dispatch(emailConfirmFetchSuccessAction());
    history.push(router.HOME);
  } catch (error) {
    dispatch(emailConfirmFetchFailAction(getErrorDtoFromApiError(error)));
    history.push(router.HOME);
  }
}
