export class Constants {
  // |test_event|event| => /?init_event=test_event
  public static readonly queryParamInitEvent: string = "init_event";
  public static readonly queryParamInitEventValueSeparator: string = "|";

  public static readonly errorCodeUnauthorized: string = "Unauthorized";
  public static readonly errorCodeMsisdnNotUnique: string = "msisdn_not_unique";
  public static readonly errorCodeWrongMsisdnFormat: string =
    "wrong_msisdn_format";
  public static readonly errorCodeTcknNotUnique: string = "tckn_not_unique";
  public static readonly errorCodeTcknMissingDetails: string =
    "tckn_missing_details";
  public static readonly errorCodeTcknDetailsMismatch: string =
    "tckn_details_mismatch";
  public static readonly errorCodeUserActive: string = "user_active";
  public static readonly errorCodeOtpRequired: string = "otp_required";
  public static readonly errorCodeMobilePaymentClosed: string =
    "mobile_payment_closed";
  public static readonly errorCodeMobilePaymentInsufficientFunds: string =
    "mobile_payment_insufficient_funds";
  public static readonly errorCodeInvalidEmail: string = "invalid_email";

  public static readonly errorGenericTitle: string = "Hay aksi!";
  public static readonly errorGenericText: string =
    "Beklenmeyen bir hata oluştu.";

  public static readonly vposResultSuccess: string = "success";
  public static readonly vposResultFailed: string = "failed";

  public static readonly shareTitle: string = "Fenerbahçe Taraftar Kart";
  public static readonly shareUrl: string =
    "https://taraftarkart.fenerbahce.org";

  public static readonly fenerLogo: string =
    "https://s3-eu-west-1.amazonaws.com/static.4p/fenerbahce-logo.svg";

  public static readonly walletAppUrl: string =
    "https://links.kimgbister.com/bireysel-landing-button";

  public static readonly wheelUrl: string =
    "https://bit.ly/fenerbahceileyeniyil";

  public static readonly goToTaraftarCardEvent: string = "goToTaraftarCard";
  public static readonly walletEvent: string = "goToWallet";

  public static readonly recaptchaSiteKey: string = "6LdFxuQpAAAAAGyPba2VwHUXvoh8eLJeP4JtJEK-";

  public static readonly countryList: string[] = [
    "Afganistan",
    "Almanya",
    "Amerika Birleşik Devletleri",
    "Amerikan Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua ve Barbuda",
    "Arjantin",
    "Arnavutluk",
    "Aruba",
    "Avustralya",
    "Avusturya",
    "Azerbaycan",
    "Bahama Adaları",
    "Bahreyn",
    "Bangladeş",
    "Barbados",
    "Belçika",
    "Belize",
    "Benin",
    "Bermuda",
    "Beyaz Rusya",
    "Bhutan",
    "Birleşik Arap Emirlikleri",
    "Bolivya",
    "Bosna Hersek",
    "Botswana",
    "Brezilya",
    "Brunei",
    "Bulgaristan",
    "Burkina Faso",
    "Burundi",
    "Cape Verde",
    "Cayman Adaları",
    "Cebelitarık",
    "Cezayir",
    "Christmas Adası",
    "Cibuti",
    "Çad",
    "Çek Cumhuriyeti",
    "Çin",
    "Danimarka",
    "Doğu Timor",
    "Dominik Cumhuriyeti",
    "Dominika",
    "Ekvator",
    "Ekvator Ginesi",
    "El Salvador",
    "Endonezya",
    "Eritre",
    "Ermenistan",
    "Estonya",
    "Etiyopya",
    "Fas",
    "Fiji",
    "Fildişi Sahili",
    "Filipinler",
    "Filistin",
    "Finlandiya",
    "Folkland Adaları",
    "Fransa",
    "Fransız Guyanası",
    "Fransız Güney Eyaletleri ",
    "Fransız Polinezyası",
    "Gabon",
    "Galler",
    "Gambiya",
    "Gana",
    "Gine",
    "Gine-Bissau",
    "Grenada",
    "Grönland",
    "Guadalup",
    "Guam",
    "Guatemala",
    "Guyana",
    "Güney Afrika",
    "Güney Georgia ve Güney Sandviç Adaları",
    "Güney Kıbrıs Rum Yönetimi",
    "Güney Kore",
    "Güney Sudan",
    "Gürcistan",
    "Haiti",
    "Havai Adaları",
    "Hırvatistan",
    "Hindistan",
    "Hollanda",
    "Hollanda Antilleri",
    "Honduras",
    "Irak",
    "İngiltere",
    "İran",
    "İrlanda",
    "İskoçya",
    "İspanya",
    "İsrail",
    "İsveç",
    "İsviçre",
    "İtalya",
    "İzlanda",
    "Jamaika",
    "Japonya",
    "Johnston Atoll",
    "K.K.T.C.",
    "Kamboçya",
    "Kamerun",
    "Kanada",
    "Karadağ",
    "Katar",
    "Kazakistan",
    "Kenya",
    "Kırgızistan",
    "Kiribati",
    "Kolombiya",
    "Komorlar",
    "Kongo",
    "Kongo Demokratik Cumhuriyeti",
    "Kosova",
    "Kosta Rika",
    "Kuveyt",
    "Kuzey İrlanda",
    "Kuzey Kore",
    "Kuzey Maryana Adaları",
    "Küba",
    "Laos",
    "Lesotho",
    "Letonya",
    "Liberya",
    "Libya",
    "Liechtenstein",
    "Litvanya",
    "Lübnan",
    "Lüksemburg",
    "Macaristan",
    "Madagaskar",
    "Makau",
    "Makedonya",
    "Malavi",
    "Maldiv Adaları",
    "Malezya",
    "Mali",
    "Malta",
    "Marşal Adaları",
    "Martinik",
    "Mauritius",
    "Mayotte",
    "Meksika",
    "Mısır",
    "Midway Adaları",
    "Mikronezya",
    "Moğolistan",
    "Moldova",
    "Monako",
    "Montserrat",
    "Moritanya",
    "Mozambik",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Nijer",
    "Nijerya",
    "Nikaragua",
    "Norveç",
    "Orta Afrika Cumhuriyeti",
    "Özbekistan",
    "Pakistan",
    "Palau Adaları",
    "Panama",
    "Papua Yeni Gine",
    "Paraguay",
    "Peru",
    "Polonya",
    "Portekiz",
    "Porto Riko",
    "Reunion",
    "Romanya",
    "Ruanda",
    "Rusya Federasyonu",
    "Saint Helena",
    "Saint Martin",
    "Saint Pierre ve Miquelon",
    "Samoa",
    "San Marino",
    "Santa Kitts ve Nevis",
    "Santa Lucia",
    "Santa Vincent ve Grenadinler",
    "Sao Tome ve Principe",
    "Senegal",
    "Seyşeller",
    "Sırbistan",
    "Sierra Leone",
    "Singapur",
    "Slovakya",
    "Slovenya",
    "Solomon Adaları",
    "Somali",
    "Sri Lanka",
    "Sudan",
    "Surinam",
    "Suriye",
    "Suudi Arabistan",
    "Svaziland",
    "Şili",
    "Tacikistan",
    "Tanzanya",
    "Tayland",
    "Tayvan",
    "Togo",
    "Tonga",
    "Trinidad ve Tobago",
    "Tunus",
    "Turks ve Caicos Adaları",
    "Tuvalu",
    "Türkiye",
    "Türkmenistan",
    "Uganda",
    "Ukrayna",
    "Umman",
    "Uruguay",
    "Ürdün",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Virgin Adaları",
    "Yemen",
    "Yeni Kaledonya",
    "Yeni Zelanda",
    "Yunanistan",
    "Zambiya",
    "Zimbabve",
  ];
}
