import axios from "../helpers/axios";
import {MobilePaymentDto} from "../models/dtos/mobilePayment.dto";
import {MobilePaymentStatusDto} from "../models/dtos/mobilePaymentStatus.dto";
import {VposPaymentDto} from "../models/dtos/vposPayment.dto";

export default class PaymentService {

  // payment/mobile/start excluded from apiErrorModal
  async startPayment(): Promise<MobilePaymentDto> {
    try {
      const response = await axios.post<MobilePaymentDto>(
        `${process.env.REACT_APP_BASE_URL}/payment/mobile/start`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // payment/mobile/status excluded from apiErrorModal
  async mobilePaymentStatus(smsCid: string): Promise<MobilePaymentStatusDto> {
    try {
      const response = await axios.get<MobilePaymentStatusDto>(
        `${process.env.REACT_APP_BASE_URL}/payment/mobile/status?smsCid=${smsCid}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async startVposPayment(isYearly: boolean): Promise<VposPaymentDto> {
    try {
      const response = await axios.post<VposPaymentDto>(
        `${process.env.REACT_APP_BASE_URL}/payment/vpos/start?yearly=${isYearly}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async cancelSubscription(): Promise<void> {
    try {
      const response = await axios.post<void>(
        `${process.env.REACT_APP_BASE_URL}/payment/cancel-subscription`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async startFreeTrial(): Promise<void> {
    try {
      const response = await axios.post<void>(
        `${process.env.REACT_APP_BASE_URL}/payment/start-free-trial`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
