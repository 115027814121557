import {Spin} from "antd";
import React from "react";
import "./BodyLoading.scss";

const BodyLoading = () => {

  return (
    <div id="body-loading">
      <Spin size="large"/>
    </div>

  );
}

export default BodyLoading;
