import {CAMPAIGN_FETCH_FAIL, CAMPAIGN_FETCH_START, CAMPAIGN_FETCH_SUCCESS, CAMPAIGN_RESET} from "../constants/campaign";
import {CampaignActions, CampaignState} from "../types/campaign";

const initialState: CampaignState = {
  campaign: {
    loading: false,
    data: undefined,
    error: undefined,
  }
}

const reducer = (
  state = initialState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case CAMPAIGN_FETCH_START:
      return {
        ...state,
        campaign: {
          ...initialState.campaign,
          loading: true,
        }
      };
    case CAMPAIGN_FETCH_SUCCESS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          loading: false,
          data: action.payload
        }
      };
    case CAMPAIGN_FETCH_FAIL:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          loading: false,
          error: action.error,
        }
      };
    case CAMPAIGN_RESET:
      return {
        ...state,
        campaign: {
          ...initialState.campaign,
        }
      };
    default:
      return state;
  }
}

export default reducer;
