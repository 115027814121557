import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import AppHeader from "../../components/AppHeader/AppHeader";
import { router } from "../../core/helpers/router";
import "./AppLayout.scss";

interface IProps {
  pathname: string;
}

class AppLayout extends React.Component<IProps> {
  private isAppHeaderFooter(): boolean {
    return this.props.pathname !== router.WHEEL;
    // return true;
  }

  private isWhiteBackground(): boolean {
    return (
      this.props.pathname === router.PROFILE ||
      this.props.pathname === router.GIFT ||
      this.props.pathname === router.MENU ||
      this.props.pathname.includes(`${router.CAMPAIGNS}/`)
    );
  }

  render() {
    return (
      <div id="app-layout">
        <Layout>
          <Content
            className={`${this.isWhiteBackground() ? "bg-white" : "bg-page"}`}
          >
            {this.isAppHeaderFooter() && <AppHeader />}
            {this.props.children}
            {this.isAppHeaderFooter() && <AppFooter />}
          </Content>
        </Layout>
      </div>
    );
  }
}

export default AppLayout;
