import {Dispatch} from "redux";
import {SET_SIGNUP_INFO} from "../constants/signupInfo";
import {history} from "../helpers/history";
import {router} from "../helpers/router";
import {SignupInfoRequest} from "../models/custom/signupInfo.request";
import {SetSignupInfoAction} from "../types/signupInfo";

const setSignupInfoAction = (payload: SignupInfoRequest): SetSignupInfoAction => {
  return {
    type: SET_SIGNUP_INFO,
    payload: payload,
  };
}
export const setSignupInfo = (request: SignupInfoRequest) => async (
  dispatch: Dispatch
) => {
  dispatch(setSignupInfoAction(request));
  history.push(router.SIGNUP_MSISDN);
};
