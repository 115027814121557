import {Spin} from "antd";
import parse from "html-react-parser";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import more from "../../../../assets/images/chevron-down.svg";
import logo from "../../../../assets/images/fenerbahce.svg";
import {pollHistoryFetch} from "../../../../core/actions/poll";
import {ErrorDto} from "../../../../core/models/dtos/error.dto";
import {PollDto} from "../../../../core/models/dtos/poll.dto";
import {IStore} from "../../../../core/reducers";
import FirebaseService from "../../../../core/services/firebase.service";
import PollResult from "../PollResult/PollResult";
import "./HistoryTab.scss";

interface IProps {
  loadingHistory: boolean;
  dataHistory: PollDto[];
  errorHistory?: ErrorDto;
  pollHistoryFetch: () => void;
}

interface IState {
  isMore: boolean;
}

class HistoryTab extends React.Component<IProps> {
  state: IState = {
    isMore: false,
  }

  componentDidMount() {
    this.props.pollHistoryFetch();
  }

  private handleMore(): void {
    this.setState({isMore: true});
  }

  private renderContent(): ReactNode {
    if (this.props.loadingHistory) {
      return <Spin size="large"/>
    } else if (this.props.dataHistory) {
      const history = this.props.dataHistory;
      if (!this.props.errorHistory && history.length === 0) {
        return this.renderEmpty();
      }
      return this.renderHistory(history);
    }
    return <React.Fragment/>
  }

  private renderEmpty(): ReactNode {
    return (
      <div className="empty-history">
        <p className="text">
          {parse(FirebaseService.getValue(FirebaseService.past_question_empty_title))}
        </p>
        <p className="sub-text">
          {parse(FirebaseService.getValue(FirebaseService.past_question_empty_desc))}
        </p>
        <img className="logo" src={logo} alt="fener"/>
      </div>
    );
  }

  private renderHistory(history: PollDto[]): ReactNode {
    if (this.state.isMore) {
      return (history.map((data, index) => {
          return (
            <div className="d-flex flex-column align-items-center">
              <PollResult data={data} isCounter={false}/>
              {index !== this.props.dataHistory.length - 1 && <hr className="divider"/>}
              {index === this.props.dataHistory.length - 1 && <img className="logo mt-3" src={logo} alt="fener"/>}
            </div>
          );
        })
      );
    }
    return (
      <React.Fragment>
        <PollResult data={this.props.dataHistory[0]} isCounter={false}/>
        {
          this.props.dataHistory.length > 1 &&
          <div
            className="more-wrapper"
            onClick={() => this.handleMore()}
          >
            <span className="more-text">{parse(FirebaseService.getValue(FirebaseService.more_question))}</span>
            <img className="more-icon" src={more} alt="more"/>
          </div>
        }
      </React.Fragment>
    );
  }

  render() {
    return (
      <div id="poll-history-tab">
        {this.renderContent()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      pollHistoryFetch,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    loadingHistory: store.poll.history.loading,
    dataHistory: store.poll.history.data,
    errorHistory: store.poll.history.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab);
