import { Button } from "antd";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import AuthFooter from "../../../../components/Auth/AuthFooter/AuthFooter";
import AuthHeaderBottom from "../../../../components/Auth/AuthHeaderBottom/AuthHeaderBottom";
import FanCard from "../../../../components/Auth/FanCard/FanCard";
import GenericModal, {
  ModalType,
} from "../../../../components/Modals/GenericModal/GenericModal";
import { logout } from "../../../../core/actions/app";
import {
  loginOtpResend,
  loginOtpSubmit,
} from "../../../../core/actions/loginOtp";
import { Constants } from "../../../../core/helpers/constants";
import { history } from "../../../../core/helpers/history";
import { router } from "../../../../core/helpers/router";
import { FormValuesLoginOtp } from "../../../../core/models/custom/formValuesLoginOtp";
import { ErrorDto } from "../../../../core/models/dtos/error.dto";
import { TokenDto } from "../../../../core/models/dtos/token.dto";
import { LoginRequest } from "../../../../core/models/requests/login.request";
import { OtpRequest } from "../../../../core/models/requests/otp.request";
import { IStore } from "../../../../core/reducers";
import FirebaseService from "../../../../core/services/firebase.service";
import LoginOtpForm from "./LoginOtpForm/LoginOtpForm";
import "./LoginOtpPage.scss";

interface IProps {
  storeLoginMsisdn?: string;
  loadingSubmit: boolean;
  dataSubmit?: TokenDto;
  errorSubmit?: ErrorDto;
  loadingResend: boolean;
  dataResend: boolean;
  errorResend?: ErrorDto;
  loginOtpSubmit: (request: LoginRequest) => void;
  loginOtpResend: (request: OtpRequest) => void;
  logout: () => void;
}

interface IState {
  isOtpInvalid: boolean;
  isOtpError: boolean;
}

class LoginOtpPage extends React.Component<IProps> {
  state: IState = {
    isOtpInvalid: false,
    isOtpError: false,
  };

  private readonly errorInvalidOtp =
    "Hatalı bi̇r kod gi̇rdi̇n, lütfen kontrol edi̇p tekrar dene.";
  private readonly errorOtp = Constants.errorGenericText;

  constructor(props: IProps) {
    super(props);
    if (!props.storeLoginMsisdn) {
      history.push(router.LOGIN_MSISDN);
    }
  }

  componentDidMount() {
    FirebaseService.fireEvent(FirebaseService.event_login_user_count_view_otp);
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (prevProps.loadingSubmit && !this.props.loadingSubmit) {
      if (this.props.dataSubmit) {
        history.push(router.LOGIN_SUBSCRIPTION);
      } else {
        this.setState({ isOtpInvalid: true });
      }
    }

    if (prevProps.loadingResend && !this.props.loadingResend) {
      if (this.props.dataResend) {
        this.setState({ isOtpError: false });
      } else {
        this.setState({ isOtpError: true });
      }
    }
  }

  private getError(): string | undefined {
    if (this.state.isOtpInvalid) {
      return this.errorInvalidOtp;
    } else if (this.state.isOtpError) {
      return this.errorOtp;
    }
    return undefined;
  }

  private resetErrors(): void {
    this.setState({ isOtpInvalid: false, isOtpError: false });
  }

  private handleSubmit(values: FormValuesLoginOtp): void {
    if (!this.props.storeLoginMsisdn) {
      alert("Msisdn must be present");
      return;
    }
    FirebaseService.fireEvent(
      FirebaseService.event_login_count_otp_checked_button_clicked
    );
    this.resetErrors();
    const request: LoginRequest = {
      msisdn: this.props.storeLoginMsisdn,
      otp: values.otp,
    };
    this.props.loginOtpSubmit(request);
  }

  private handleResend(captcha: string): void {
    if (this.props.loadingResend) return;
    if (!this.props.storeLoginMsisdn) {
      alert("Msisdn must be present");
      return;
    }
    FirebaseService.fireEvent(
      FirebaseService.event_login_count_otp_send_again_button_clicked
    );
    this.setState({ isOtpError: false });
    const request: OtpRequest = {
      msisdn: this.props.storeLoginMsisdn,
      captcha,
    };
    this.props.loginOtpResend(request);
  }

  private handleLogout(): void {
    this.props.logout();
    history.push(router.LOGIN_MSISDN);
  }

  render() {
    return (
      <div id="login-otp-page">
        <div className="container">
          <AuthHeaderBottom />
          <div className="page-content pb-3">
            <FanCard />
            <LoginOtpForm
              isSubmitDisabled={this.props.loadingSubmit}
              callbackSubmit={(values) => this.handleSubmit(values)}
              callbackError={() => this.setState({ isOtpInvalid: true })}
              callbackResend={(captcha) => this.handleResend(captcha)}
            />
            <div className="d-flex justify-content-center">
              <Button
                className="mt-3"
                type="link"
                onClick={() => this.handleLogout()}
              >
                <span className="link">Giriş sayfasına dön</span>
              </Button>
            </div>
          </div>
        </div>
        <AuthFooter />

        <GenericModal
          isVisible={!!this.getError()}
          type={ModalType.error}
          title={Constants.errorGenericTitle}
          text={this.getError() ?? ""}
          callbackOk={() => this.resetErrors()}
          callbackCancel={() => this.resetErrors()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      loginOtpSubmit,
      loginOtpResend,
      logout,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  const state = store.loginOtp;
  return {
    storeLoginMsisdn: store.loginMsisdn.msisdn,
    loadingSubmit: state.submit.loading,
    dataSubmit: state.submit.data,
    errorSubmit: state.submit.error,
    loadingResend: state.resend.loading,
    dataResend: state.resend.data,
    errorResend: state.resend.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginOtpPage);
