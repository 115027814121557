import parse from "html-react-parser";
import moment from "moment";
import React, {ReactNode} from "react";
import {PhotoGalleryItemDto} from "../../core/models/dtos/photoGalleryItem.dto";
import FirebaseService from "../../core/services/firebase.service";
import "./PhotoGalleryPage.scss";

interface IProps {
}

interface IState {
  photos: PhotoGalleryItemDto[];
}

class PhotoGalleryPage extends React.Component<IProps> {
  state: IState = {
    photos: [],
  }

  componentDidMount() {
    this.setPhotos();
  }

  private setPhotos(): void {
    const photos = FirebaseService.getPhotos();
    this.setState({photos});
  }

  private isDateLabel(index: number): boolean {
    return (
      index === 0 ||
      this.state.photos[index].date !== this.state.photos[index - 1].date
    );
  }

  private renderPhotosEmpty(): ReactNode {
    return (
      <div className="section section-photos-title">
        <div className="heading">
          <div className="info">
            <span className="title">
              {parse(FirebaseService.getValue(FirebaseService.notification_empty))}
            </span>
          </div>
        </div>
      </div>
    );
  }

  private renderPhotos(): ReactNode {
    return (
      <div className="photo-gallery">
        {this.state.photos.map((photo, index) => {
          return (
            <div
              key={`photo-${photo.id}`}
              className="photo-card"
            >
              {
                this.isDateLabel(index) &&
                <span className="date">{moment(photo.date).format('DD.MM.YYYY')}</span>
              }
              <img className="image" src={photo.image} alt="gallery"/>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div id="photo-gallery-page" className="page">
        <div className="page-content">
          {this.state.photos.length === 0
            ? this.renderPhotosEmpty()
            : this.renderPhotos()
          }
        </div>
      </div>
    );
  }
}

export default PhotoGalleryPage;
