import {
  LOGIN_OTP_RESEND_FAIL,
  LOGIN_OTP_RESEND_RESET,
  LOGIN_OTP_RESEND_START,
  LOGIN_OTP_RESEND_SUCCESS,
  LOGIN_OTP_SUBMIT_FAIL,
  LOGIN_OTP_SUBMIT_RESET,
  LOGIN_OTP_SUBMIT_START,
  LOGIN_OTP_SUBMIT_SUCCESS
} from "../constants/loginOtp";
import {setStorage, storageToken} from "../helpers/storage";
import {LoginOtpActions, LoginOtpState} from "../types/loginOtp";

const initialState: LoginOtpState = {
  submit: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  resend: {
    loading: false,
    data: false,
    error: undefined,
  }
}

const reducer = (
  state = initialState,
  action: LoginOtpActions,
): LoginOtpState => {
  switch (action.type) {
    case LOGIN_OTP_SUBMIT_START:
      return {
        ...state,
        submit: {
          ...initialState.submit,
          loading: true,
        }
      }
    case LOGIN_OTP_SUBMIT_SUCCESS:
      setStorage(storageToken, action.payload.token);
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          data: action.payload,
        }
      }
    case LOGIN_OTP_SUBMIT_FAIL:
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          error: action.error,
        }
      }
    case LOGIN_OTP_SUBMIT_RESET:
      return {
        ...state,
        submit: {
          ...initialState.submit,
        }
      }
    case LOGIN_OTP_RESEND_START:
      return {
        ...state,
        resend: {
          ...initialState.resend,
          loading: true,
        }
      }
    case LOGIN_OTP_RESEND_SUCCESS:
      return {
        ...state,
        resend: {
          ...state.resend,
          loading: false,
          data: action.payload,
        }
      }
    case LOGIN_OTP_RESEND_FAIL:
      return {
        ...state,
        resend: {
          ...state.resend,
          loading: false,
          error: action.error,
        }
      }
    case LOGIN_OTP_RESEND_RESET:
      return {
        ...state,
        resend: {
          ...initialState.resend,
        }
      }
    default:
      return state;
  }
}

export default reducer;
