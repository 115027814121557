import { Dispatch } from "redux";
import {
  LOGIN_CODE_SUBMIT_FAIL,
  LOGIN_CODE_SUBMIT_RESET,
  LOGIN_CODE_SUBMIT_START,
  LOGIN_CODE_SUBMIT_SUCCESS,
  LOGIN_MSISDN_SUBMIT_FAIL,
  LOGIN_MSISDN_SUBMIT_RESET,
  LOGIN_MSISDN_SUBMIT_START,
  LOGIN_MSISDN_SUBMIT_SUCCESS,
  SET_LOGIN_MSISDN
} from "../constants/loginMsisdn";
import { history } from "../helpers/history";
import { router } from "../helpers/router";
import { removeStorage, setStorage, storageRecaptcha, storageToken } from "../helpers/storage";
import { Utilities } from "../helpers/utilities";
import { AuthType } from "../models/custom/enums/authType";
import { ErrorDto, ErrorGeneric, ErrorLogin, getErrorDtoFromApiError } from "../models/dtos/error.dto";
import { OtpRequest } from "../models/requests/otp.request";
import AuthService from "../services/auth.service";
import FirebaseService from "../services/firebase.service";
import GTMService from "../services/gtm.service";
import {
  LoginCodeSubmitFailAction,
  LoginCodeSubmitResetAction,
  LoginCodeSubmitStartAction,
  LoginCodeSubmitSuccessAction,
  LoginMsisdnSubmitFailAction,
  LoginMsisdnSubmitResetAction,
  LoginMsisdnSubmitStartAction,
  LoginMsisdnSubmitSuccessAction,
  SetLoginMsisdnAction
} from "../types/loginMsisdn";

const authService: AuthService = new AuthService();

/**
 * LOGIN_MSISDN
 */
const setLoginMsisdnAction = (payload: string): SetLoginMsisdnAction => {
  return {
    type: SET_LOGIN_MSISDN,
    payload: payload,
  };
}
export const setLoginMsisdn = (msisdn: string) => (dispatch: Dispatch) => {
  dispatch(setLoginMsisdnAction(msisdn));
};

/**
 * LOGIN_MSISDN_SUBMIT
 */
const loginMsisdnSubmitStartAction = (): LoginMsisdnSubmitStartAction => {
  return {
    type: LOGIN_MSISDN_SUBMIT_START,
  };
}
const loginMsisdnSubmitSuccessAction = (payload: boolean): LoginMsisdnSubmitSuccessAction => {
  return {
    type: LOGIN_MSISDN_SUBMIT_SUCCESS,
    payload: payload,
  };
}
const loginMsisdnSubmitFailAction = (error: ErrorDto): LoginMsisdnSubmitFailAction => {
  return {
    type: LOGIN_MSISDN_SUBMIT_FAIL,
    error: error,
  };
}
export const loginMsisdnSubmit = (
  request: OtpRequest,
  authType: AuthType,
  marketingConsent: boolean = false,
  privacyConsent: boolean = false,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(loginMsisdnSubmitStartAction());
    dispatch(setLoginMsisdnAction(request.msisdn));
    await authService.sendOtp(request);
    dispatch(loginMsisdnSubmitSuccessAction(true));
    FirebaseService.fireEvent(FirebaseService.event_user_count_MSISDN_checked_already_member);
    removeStorage(storageRecaptcha);
    history.push(router.LOGIN_OTP);
  } catch (error) {
    const errorDto: ErrorDto = getErrorDtoFromApiError(error);
    if (errorDto.status === 404) {
      if (authType === AuthType.login) {
        dispatch(loginMsisdnSubmitFailAction(ErrorLogin));
        return;
      }
      try {
        const tokenDto = await authService.signup({
          msisdn: request.msisdn,
          marketingConsent: marketingConsent,
          privacyConsent: privacyConsent,
        });
        if (tokenDto) {
          setStorage(storageToken, tokenDto.token);
          setStorage(storageRecaptcha, "true");
          dispatch(loginMsisdnSubmitSuccessAction(false));
          FirebaseService.getFcmToken(true);
          if (Utilities.isMsisdnTurkish(request.msisdn)) {
            FirebaseService.fireEvent(FirebaseService.event_payment_user_count_view)
          }
          Utilities.navigateToPayment(request.msisdn);
        } else {
          dispatch(loginMsisdnSubmitFailAction(ErrorGeneric));
        }
      } catch (error) {
        dispatch(loginMsisdnSubmitFailAction(getErrorDtoFromApiError(error)));
      }
    } else {
      dispatch(loginMsisdnSubmitFailAction(errorDto));
    }
  }
};

const loginMsisdnSubmitResetAction = (): LoginMsisdnSubmitResetAction => {
  return {
    type: LOGIN_MSISDN_SUBMIT_RESET,
  };
}
export const loginMsisdnSubmitReset = () => (dispatch: Dispatch) => {
  dispatch(loginMsisdnSubmitResetAction());
};

/**
 * LOGIN_CODE_SUBMIT
 */
const loginCodeSubmitStartAction = (): LoginCodeSubmitStartAction => {
  return {
    type: LOGIN_CODE_SUBMIT_START,
  };
}
const loginCodeSubmitSuccessAction = (payload: boolean): LoginCodeSubmitSuccessAction => {
  return {
    type: LOGIN_CODE_SUBMIT_SUCCESS,
    payload: payload,
  };
}
const loginCodeSubmitFailAction = (error: ErrorDto): LoginCodeSubmitFailAction => {
  return {
    type: LOGIN_CODE_SUBMIT_FAIL,
    error: error,
  };
}
export const loginCodeSubmit = (code: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(loginCodeSubmitStartAction());
    const tokenDto = await authService.loginWithCode({ code });
    if (tokenDto) {
      setStorage(storageToken, tokenDto.token);
      dispatch(loginCodeSubmitSuccessAction(true));
      FirebaseService.getFcmToken(true);
      history.push(router.LOGIN_SUBSCRIPTION);
      GTMService.eventLogin();
    } else {
      dispatch(loginCodeSubmitFailAction(ErrorGeneric));
    }
  } catch (error) {
    dispatch(loginCodeSubmitFailAction(getErrorDtoFromApiError(error)));
  }
};

const loginCodeSubmitResetAction = (): LoginCodeSubmitResetAction => {
  return {
    type: LOGIN_CODE_SUBMIT_RESET,
  };
}
export const loginCodeSubmitReset = () => (dispatch: Dispatch) => {
  dispatch(loginCodeSubmitResetAction());
};
