import {Button, Modal} from "antd";
import parse from "html-react-parser";
import React from "react";
import iconError from "../../../assets/images/popup-icon-error.svg";
import iconSuccess from "../../../assets/images/popup-icon-success.svg";
import "./GenericModal.scss";

export enum ModalType {
  success,
  error,
}

interface IProps {
  isVisible: boolean,
  type: ModalType,
  title: string,
  text: string,
  button?: string,
  buttonSecondary?: string,
  callbackOk: () => void,
  callbackCancel: () => void,
}

const GenericModal = (props: IProps) => {

  return (
    <Modal
      className="generic-modal"
      maskStyle={{backdropFilter: "blur(8px)"}}
      visible={props.isVisible}
      centered
      closable={false}
      footer={false}
      onCancel={props.callbackCancel}
      destroyOnClose
    >
      <div className="body">

        <div className="header">
          <span className="title">
            {parse(props.title)}
          </span>
          {
            props.type === ModalType.success
              ? <img className="logo" src={iconSuccess} alt="success"/>
              : <img className="logo" src={iconError} alt="error"/>
          }
        </div>

        <p className="text">
          {parse(props.text)}
        </p>

        <Button
          className="button"
          onClick={props.callbackOk}
        >
          {parse(props.button ?? 'Tamam')}
        </Button>

        {
          props.buttonSecondary &&
          <Button
            className="button-secondary"
            onClick={props.callbackCancel}
          >
            {parse(props.buttonSecondary)}
          </Button>
        }
      </div>

    </Modal>
  );
}

export default GenericModal;
