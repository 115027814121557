import { InfoCircleOutlined } from "@ant-design/icons";
import { DatePicker, Form, Input, Radio, Select, Tooltip } from "antd";
import { RuleObject } from "antd/es/form";
import moment from "moment";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import iconDate from "../../../assets/images/profile-form-date.svg";
import iconEmail from "../../../assets/images/profile-form-email.svg";
import iconLocation from "../../../assets/images/profile-form-location.svg";
import iconPhone from "../../../assets/images/profile-form-phone.svg";
import ButtonPrimary from "../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import { router } from "../../../core/helpers/router";
import { Utilities } from "../../../core/helpers/utilities";
import { IdType } from "../../../core/models/custom/enums/idType";
import { FormValuesProfile } from "../../../core/models/custom/formValuesProfile";
import { UserMeDto } from "../../../core/models/dtos/userMe.dto";
import { SubscriptionType } from "../../../core/models/enums/subscriptionType";
import FirebaseService from "../../../core/services/firebase.service";
import "./ProfileForm.scss";
import { Constants } from "../../../core/helpers/constants";

const { Option } = Select;

interface IProps {
  userMe?: UserMeDto;
  subscriptionType?: SubscriptionType;
  initialValues: FormValuesProfile;
  initialIdType: IdType;
  isResendConfirmationMailDisabled: boolean;
  isSubmitDisabled: boolean;
  isCancelSubscriptionDisabled: boolean;
  callbackResendConfirmationMail: () => void;
  callbackSubmit: (values: FormValuesProfile) => void;
  callbackCancelSubscription: () => void;
  callbackConsents: () => void;
}

const ProfileForm = (props: IProps) => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState<string | undefined>(
    props.initialValues.email
  );
  const [idType, setIdType] = useState<IdType>(props.initialIdType);

  const idOptions = [
    { label: "TCKN", value: IdType.tckn },
    { label: "TC vatandaşı değilim", value: IdType.passport },
  ];

  const hasEmailInitial = (): boolean => {
    return !!props.initialValues.email;
  };

  const isEmailChanged = (): boolean => {
    return props.initialValues.email !== email;
  };

  const isEmailConfirmed = (): boolean => {
    return !!props.initialValues.emailConfirmed;
  };

  const isEmailRedBorder = (): boolean => {
    return hasEmailInitial() && !isEmailConfirmed();
  };

  const getEmailInfoText = (): string => {
    if (isEmailConfirmed()) {
      return "";
    } else if (hasEmailInitial()) {
      if (isEmailChanged()) {
        return "*Mailiniz Passolig'de kayıtlı mail ile aynı olmalıdır.";
      } else {
        return "Lütfen girdiğiniz mail adresine gelmiş olan linke tıklayarak mail adresinizi konfirme ediniz veya yeni bir mail adresi giriniz.";
      }
    }
    return "*Mailiniz Passolig'de kayıtlı mail ile aynı olmalıdır.";
  };

  const handleKeyPressFullName = (e: React.KeyboardEvent) => {
    if (!Utilities.isCharacterALetter(e.key)) {
      e.preventDefault();
      return false;
    }
  };

  const handleKeyPressDatePicker = (e: React.KeyboardEvent) => {
    if (Utilities.isCharacterALetter(e.key)) {
      e.preventDefault();
      return false;
    }
  };

  const handleKeyPressTckn = (e: React.KeyboardEvent) => {
    const identity: string | undefined = form.getFieldValue("tckn");
    if (
      e.key === " " ||
      isNaN(Number(e.key)) ||
      (identity && identity?.length >= 11)
    ) {
      e.preventDefault();
    }
  };

  const tcknValidation = (
    rule: RuleObject,
    value: any,
    callback: (error?: string) => void
  ) => {
    if (props.initialValues.tckn || !value || Utilities.isTcknValid(value)) {
      return callback();
    }
    return callback("");
  };

  return (
    <Form
      id="profile-form"
      className="container"
      form={form}
      initialValues={props.initialValues}
      layout="vertical"
      onFinish={(values) => props.callbackSubmit(values)}
    >
      <div className="row">
        <div className="col-12 col-lg-6 form-item-wrapper">
          <Form.Item name="firstName" label="İsim:">
            <Input
              className="form-input"
              type="text"
              placeholder="İsim"
              disabled={!!props.initialValues.tckn}
              onKeyPress={handleKeyPressFullName}
            />
          </Form.Item>
        </div>

        <div className="col-12 col-lg-6 form-item-wrapper">
          <Form.Item name="lastName" label="Soyisim:">
            <Input
              className="form-input"
              type="text"
              placeholder="Soyisim"
              disabled={!!props.initialValues.tckn}
              onKeyPress={handleKeyPressFullName}
            />
          </Form.Item>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 form-item-wrapper">
          <Form.Item
            name="birthday"
            label={
              <div className="label-wrapper">
                <img className="label-icon" src={iconDate} alt="date" />
                <span>Doğum Tarihi:</span>
              </div>
            }
          >
            <DatePicker
              className="form-input"
              placeholder="Doğum Tarihi"
              format="DD.MM.YYYY"
              disabledDate={(date) => date >= moment(Date.now())}
              disabled={!!props.initialValues.tckn}
              onKeyDown={handleKeyPressDatePicker}
            />
          </Form.Item>
        </div>

        <div className="col-12 col-lg-6 form-item-wrapper">
          <Form.Item
            name="province"
            label={
              <div className="label-wrapper">
                <img className="label-icon" src={iconLocation} alt="location" />
                <span>Yaşadığın Şehir:</span>
              </div>
            }
          >
            <Select
              className="form-select"
              placeholder="Yaşadığın Şehir"
              disabled={!!props.initialValues.province}
            >
              <Option value="İstanbul">İstanbul</Option>
              <Option value="Ankara">Ankara</Option>
              <Option value="İzmir">İzmir</Option>
              <Option value="Adana">Adana</Option>
              <Option value="Adıyaman">Adıyaman</Option>
              <Option value="Afyonkarahisar">Afyonkarahisar</Option>
              <Option value="Ağrı">Ağrı</Option>
              <Option value="Aksaray">Aksaray</Option>
              <Option value="Amasya">Amasya</Option>
              <Option value="Antalya">Antalya</Option>
              <Option value="Ardahan">Ardahan</Option>
              <Option value="Artvin">Artvin</Option>
              <Option value="Aydın">Aydın</Option>
              <Option value="Balıkesir">Balıkesir</Option>
              <Option value="Bartın">Bartın</Option>
              <Option value="Batman">Batman</Option>
              <Option value="Bayburt">Bayburt</Option>
              <Option value="Bilecik">Bilecik</Option>
              <Option value="Bingöl">Bingöl</Option>
              <Option value="Bitlis">Bitlis</Option>
              <Option value="Bolu">Bolu</Option>
              <Option value="Burdur">Burdur</Option>
              <Option value="Bursa">Bursa</Option>
              <Option value="Çanakkale">Çanakkale</Option>
              <Option value="Çankırı">Çankırı</Option>
              <Option value="Çorum">Çorum</Option>
              <Option value="Denizli">Denizli</Option>
              <Option value="Diyarbakır">Diyarbakır</Option>
              <Option value="Düzce">Düzce</Option>
              <Option value="Edirne">Edirne</Option>
              <Option value="Elazığ">Elazığ</Option>
              <Option value="Erzincan">Erzincan</Option>
              <Option value="Erzurum">Erzurum</Option>
              <Option value="Eskişehir">Eskişehir</Option>
              <Option value="Gaziantep">Gaziantep</Option>
              <Option value="Giresun">Giresun</Option>
              <Option value="Gümüşhane">Gümüşhane</Option>
              <Option value="Hakkâri">Hakkâri</Option>
              <Option value="Hatay">Hatay</Option>
              <Option value="Iğdır">Iğdır</Option>
              <Option value="Isparta">Isparta</Option>
              <Option value="Kahramanmaraş">Kahramanmaraş</Option>
              <Option value="Karabük">Karabük</Option>
              <Option value="Karaman">Karaman</Option>
              <Option value="Kars">Kars</Option>
              <Option value="Kastamonu">Kastamonu</Option>
              <Option value="Kayseri">Kayseri</Option>
              <Option value="Kırıkkale">Kırıkkale</Option>
              <Option value="Kırklareli">Kırklareli</Option>
              <Option value="Kırşehir">Kırşehir</Option>
              <Option value="Kilis">Kilis</Option>
              <Option value="Kocaeli">Kocaeli</Option>
              <Option value="Konya">Konya</Option>
              <Option value="Kütahya">Kütahya</Option>
              <Option value="Malatya">Malatya</Option>
              <Option value="Manisa">Manisa</Option>
              <Option value="Mardin">Mardin</Option>
              <Option value="Mersin">Mersin</Option>
              <Option value="Muğla">Muğla</Option>
              <Option value="Muş">Muş</Option>
              <Option value="Nevşehir">Nevşehir</Option>
              <Option value="Niğde">Niğde</Option>
              <Option value="Ordu">Ordu</Option>
              <Option value="Osmaniye">Osmaniye</Option>
              <Option value="Rize">Rize</Option>
              <Option value="Sakarya">Sakarya</Option>
              <Option value="Samsun">Samsun</Option>
              <Option value="Siirt">Siirt</Option>
              <Option value="Sinop">Sinop</Option>
              <Option value="Sivas">Sivas</Option>
              <Option value="Şırnak">Şırnak</Option>
              <Option value="Tekirdağ">Tekirdağ</Option>
              <Option value="Tokat">Tokat</Option>
              <Option value="Trabzon">Trabzon</Option>
              <Option value="Tunceli">Tunceli</Option>
              <Option value="Şanlıurfa">Şanlıurfa</Option>
              <Option value="Uşak">Uşak</Option>
              <Option value="Van">Van</Option>
              <Option value="Yalova">Yalova</Option>
              <Option value="Yozgat">Yozgat</Option>
              <Option value="Zonguldak">Zonguldak</Option>
              {Constants.countryList.map((country, i) => (
                <Option key={i} value={country}>
                  {country}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 form-item-wrapper">
          <Form.Item name="idType">
            <Radio.Group
              options={idOptions}
              optionType="button"
              buttonStyle="solid"
              onChange={(e) => setIdType(e.target.value)}
              disabled={
                !!props.initialValues.tckn ||
                !!props.initialValues.passportNumber
              }
            />
          </Form.Item>

          {idType === IdType.tckn ? (
            <Form.Item
              name="tckn"
              rules={[
                {
                  validator: tcknValidation,
                  message: FirebaseService.getValue(
                    FirebaseService.profile_tckn_error
                  ),
                },
              ]}
            >
              <Input
                className="form-input identity no-border"
                autoComplete="off"
                type="tel"
                placeholder="TCKN"
                disabled={!!props.initialValues.tckn}
                addonAfter={
                  <Tooltip
                    placement="topLeft"
                    title={FirebaseService.getValue(
                      FirebaseService.profile_tckn_info
                    )}
                  >
                    <InfoCircleOutlined
                      style={{ fontSize: "22px", color: "#002d72" }}
                    />
                  </Tooltip>
                }
                onKeyPress={handleKeyPressTckn}
              />
            </Form.Item>
          ) : (
            <Form.Item name="passportNumber">
              <Input
                className="form-input identity"
                autoComplete="off"
                placeholder="Passport No"
                disabled={!!props.initialValues.passportNumber}
              />
            </Form.Item>
          )}
        </div>

        <div className="col-12 col-lg-6 form-item-wrapper">
          <Form.Item
            name="msisdn"
            label={
              <div className="label-wrapper">
                <img className="label-icon" src={iconPhone} alt="phone" />
                <span>Telefon Numarası:</span>
              </div>
            }
          >
            <Input
              className="form-input"
              type="tel"
              placeholder="Telefon Numarası"
              disabled={!!props.initialValues.msisdn}
            />
          </Form.Item>
          <span className="form-input-info">
            {FirebaseService.getValue(
              FirebaseService.profile_contact_us_redirect
            )}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 form-item-wrapper mb-4">
          <Form.Item
            name="email"
            rules={[{ type: "email", message: "" }]}
            label={
              <div className="label-wrapper">
                <img className="label-icon small" src={iconEmail} alt="email" />
                <span>Email:</span>
              </div>
            }
          >
            <Input
              className={`form-input ${isEmailRedBorder() ? "border-red" : ""}`}
              type="text"
              placeholder="Email"
              // disabled={props.initialValues.emailConfirmed}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <span className="form-input-info">{getEmailInfoText()}</span>
        </div>

        {props.initialValues.email && !props.initialValues.emailConfirmed && (
          <div className="col-12 col-lg-6 form-item-wrapper mb-4">
            <div
              className="ant-form-item-label d-none d-lg-block"
              style={{ color: "transparent" }}
            >
              placeholder
            </div>
            <div className="button-full-width">
              <ButtonPrimary
                text="Mail onaylama linkini tekrar gönder"
                disabled={props.isResendConfirmationMailDisabled}
                callback={props.callbackResendConfirmationMail}
              />
            </div>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center justify-content-md-start mb-3">
        <ButtonPrimary
          text="İzinlerim"
          class="button-consents"
          callback={props.callbackConsents}
        />
      </div>

      <div className="d-flex justify-content-center">
        <ButtonPrimary
          text="Değişiklikleri Kaydet"
          isSubmit={true}
          disabled={props.isSubmitDisabled}
        />
      </div>

      {props.subscriptionType === SubscriptionType.VPOS && (
        <div className="d-flex justify-content-center mt-4">
          <ButtonPrimary
            class="button-dark-yellow"
            text="Aboneliğimi iptal etmek istiyorum"
            isSubmit={false}
            disabled={props.isCancelSubscriptionDisabled}
            callback={props.callbackCancelSubscription}
          />
        </div>
      )}

      <div className="d-flex justify-content-center mt-3">
        <NavLink className="contact-link" to={router.CONTACT_US}>
          Bize Ulaş
        </NavLink>
      </div>
    </Form>
  );
};

export default ProfileForm;
