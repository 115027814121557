import {Dispatch} from "redux";
import {
  SIGNUP_MSISDN_SUBMIT_FAIL,
  SIGNUP_MSISDN_SUBMIT_RESET,
  SIGNUP_MSISDN_SUBMIT_START,
  SIGNUP_MSISDN_SUBMIT_SUCCESS
} from "../constants/signupMsisdn";
import {setStorage, storageToken} from "../helpers/storage";
import {ErrorDto, ErrorGeneric, getErrorDtoFromApiError} from "../models/dtos/error.dto";
import {TokenDto} from "../models/dtos/token.dto";
import {SignupRequest} from "../models/requests/signup.request";
import AuthService from "../services/auth.service";
import FirebaseService from "../services/firebase.service";
import {
  SignupMsisdnSubmitFailAction,
  SignupMsisdnSubmitResetAction,
  SignupMsisdnSubmitStartAction,
  SignupMsisdnSubmitSuccessAction
} from "../types/signupMsisdn";

const authService: AuthService = new AuthService();

const signupMsisdnSubmitStartAction = (): SignupMsisdnSubmitStartAction => {
  return {
    type: SIGNUP_MSISDN_SUBMIT_START,
  };
}
const signupMsisdnSubmitSuccessAction = (payload: TokenDto): SignupMsisdnSubmitSuccessAction => {
  return {
    type: SIGNUP_MSISDN_SUBMIT_SUCCESS,
    payload: payload,
  };
}
const signupMsisdnSubmitFailAction = (error: ErrorDto): SignupMsisdnSubmitFailAction => {
  return {
    type: SIGNUP_MSISDN_SUBMIT_FAIL,
    error: error,
  };
}
export const signupMsisdnSubmit = (request: SignupRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(signupMsisdnSubmitStartAction());
    const tokenDto = await authService.signup(request);
    if (tokenDto) {
      setStorage(storageToken, tokenDto.token);
      dispatch(signupMsisdnSubmitSuccessAction(tokenDto));
      FirebaseService.getFcmToken(true);
    } else {
      dispatch(signupMsisdnSubmitFailAction(ErrorGeneric));
    }
  } catch (error) {
    dispatch(signupMsisdnSubmitFailAction(getErrorDtoFromApiError(error)));
  }
};

const signupMsisdnSubmitResetAction = (): SignupMsisdnSubmitResetAction => {
  return {
    type: SIGNUP_MSISDN_SUBMIT_RESET,
  };
}
export const signupMsisdnSubmitReset = () => (dispatch: Dispatch) => {
  dispatch(signupMsisdnSubmitResetAction());
};
