import {
  WHEEL_HASH_CONSUME_FAIL,
  WHEEL_HASH_CONSUME_RESET,
  WHEEL_HASH_CONSUME_START,
  WHEEL_HASH_CONSUME_SUCCESS,
  WHEEL_HASH_DETAIL_FETCH_FAIL,
  WHEEL_HASH_DETAIL_FETCH_RESET,
  WHEEL_HASH_DETAIL_FETCH_START,
  WHEEL_HASH_DETAIL_FETCH_SUCCESS
} from "../constants/wheel";
import {WheelActions, WheelState} from "../types/wheel";

const initialState: WheelState = {
  hashDetail: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  hashConsume: {
    loading: false,
    data: false,
    error: undefined,
  }
}

const reducer = (
  state = initialState,
  action: WheelActions,
): WheelState => {
  switch (action.type) {
    case WHEEL_HASH_DETAIL_FETCH_START:
      return {
        ...state,
        hashDetail: {
          ...initialState.hashDetail,
          loading: true,
        }
      };
    case WHEEL_HASH_DETAIL_FETCH_SUCCESS:
      return {
        ...state,
        hashDetail: {
          ...state.hashDetail,
          loading: false,
          data: action.payload
        }
      };
    case WHEEL_HASH_DETAIL_FETCH_FAIL:
      return {
        ...state,
        hashDetail: {
          ...state.hashDetail,
          loading: false,
          error: action.error,
        }
      };
    case WHEEL_HASH_DETAIL_FETCH_RESET:
      return {
        ...state,
        hashDetail: {
          ...initialState.hashDetail,
        }
      };
    case WHEEL_HASH_CONSUME_START:
      return {
        ...state,
        hashConsume: {
          ...initialState.hashConsume,
          loading: true,
        }
      };
    case WHEEL_HASH_CONSUME_SUCCESS:
      return {
        ...state,
        hashConsume: {
          ...state.hashConsume,
          loading: false,
          data: action.payload
        }
      };
    case WHEEL_HASH_CONSUME_FAIL:
      return {
        ...state,
        hashConsume: {
          ...state.hashConsume,
          loading: false,
          error: action.error,
        }
      };
    case WHEEL_HASH_CONSUME_RESET:
      return {
        ...state,
        hashConsume: {
          ...initialState.hashConsume,
        }
      };
    default:
      return state;
  }
}

export default reducer;
