import {
  TEST_FINISH_FAIL,
  TEST_FINISH_RESET,
  TEST_FINISH_START,
  TEST_FINISH_SUCCESS,
  TEST_QUESTIONS_FETCH_FAIL,
  TEST_QUESTIONS_FETCH_START,
  TEST_QUESTIONS_FETCH_SUCCESS,
  TEST_QUESTIONS_RESET,
  TEST_UPDATE_QUESTION_ANSWER_FAIL,
  TEST_UPDATE_QUESTION_ANSWER_RESET,
  TEST_UPDATE_QUESTION_ANSWER_START,
  TEST_UPDATE_QUESTION_ANSWER_SUCCESS,
} from "../constants/test";
import {TestActions, TestState} from "../types/test";

const initialState: TestState = {
  questions: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  update: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  finish: {
    loading: false,
    data: false,
    error: undefined,
  },
}

const reducer = (
  state = initialState,
  action: TestActions,
): TestState => {
  switch (action.type) {
    case TEST_QUESTIONS_FETCH_START:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: true,
        }
      };
    case TEST_QUESTIONS_FETCH_SUCCESS:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: false,
          data: action.payload
        }
      };
    case TEST_QUESTIONS_FETCH_FAIL:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: false,
          error: action.error,
        }
      };
    case TEST_QUESTIONS_RESET:
      return {
        ...state,
        questions: {
          ...initialState.questions,
        }
      };
    case TEST_UPDATE_QUESTION_ANSWER_START:
      return {
        ...state,
        update: {
          ...initialState.update,
          loading: true,
        }
      };
    case TEST_UPDATE_QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          data: action.payload
        }
      };
    case TEST_UPDATE_QUESTION_ANSWER_FAIL:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          error: action.error,
        }
      };
    case TEST_UPDATE_QUESTION_ANSWER_RESET:
      return {
        ...state,
        update: {
          ...initialState.update,
        }
      };
    case TEST_FINISH_START:
      return {
        ...state,
        finish: {
          ...initialState.finish,
          loading: true,
        }
      };
    case TEST_FINISH_SUCCESS:
      return {
        ...state,
        finish: {
          ...state.finish,
          loading: false,
          data: action.payload
        }
      };
    case TEST_FINISH_FAIL:
      return {
        ...state,
        finish: {
          ...state.finish,
          loading: false,
          error: action.error,
        }
      };
    case TEST_FINISH_RESET:
      return {
        ...state,
        finish: {
          ...initialState.finish,
        }
      };
    default:
      return state;
  }
}

export default reducer;
