import React, {ReactNode} from "react";
import socialFacebook from "../../../assets/images/social-facebook.svg";
import socialInstagram from "../../../assets/images/social-instagram.svg";
import socialTiktok from "../../../assets/images/social-tiktok.svg";
import socialTwitter from "../../../assets/images/social-twitter.svg";
import socialYoutube from "../../../assets/images/social-youtube.svg";
import "./AuthFooter.scss";

const AuthHeader = () => {

  const renderLink = (href: string, src: any): ReactNode => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <img src={src} alt="social"/>
      </a>
    );
  }

  return (
    <div id="auth-footer">
      <div className="d-flex flex-column">
        <div className="icon-wrapper">
          {renderLink("https://www.facebook.com/FenerbahceKart", socialFacebook)}
          {renderLink("https://twitter.com/Fenerbahce", socialTwitter)}
          {renderLink("https://www.instagram.com/fbkart", socialInstagram)}
          {renderLink("https://www.youtube.com/channel/UCgqlho3-8a6FmDqQm7Q6gJw", socialYoutube)}
          {renderLink(" https://www.tiktok.com/@fenerbahce?lang=tr-TR", socialTiktok)}
        </div>
        <div className="branding">
          <span className="year">1907 - 2021</span>
          <span className="brand">FENERBAHÇE SK</span>
        </div>
      </div>
    </div>
  );
}

export default AuthHeader;
