import {Button} from "antd";
import React from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import notifications from "../../assets/images/hamburger-bell.svg";
import gallery from "../../assets/images/hamburger-gallery.svg";
import info from "../../assets/images/hamburger-info.svg";
import mail from "../../assets/images/hamburger-mail.svg";
import question from "../../assets/images/hamburger-question-mark.svg";
import {logout} from "../../core/actions/app";
import {router} from "../../core/helpers/router";
import FirebaseService from "../../core/services/firebase.service";
import "./MenuPage.scss";

interface IProps {
  logout: () => void;
}

class MenuPage extends React.Component<IProps> {

  private handleLogout(): void {
    FirebaseService.fireEvent(FirebaseService.event_menu_count_log_out_button_clicked);
    this.props.logout();
  }

  render() {
    return (
      <div id="menu-page" className="page">
        <div className="page-content">
          <NavLink
            to={router.NOTIFICATIONS}
            onClick={() => FirebaseService.fireEvent(FirebaseService.event_menu_count_notifications_button_clicked)}
          >
            <div className="nav-link-wrapper">
              <span>Bildirimler</span>
              <div className="img-wrapper">
                <img src={notifications} alt="notifications"/>
              </div>
            </div>
          </NavLink>
          <hr className="line"/>
          <NavLink
            to={router.ABOUT_US}
            onClick={() => FirebaseService.fireEvent(FirebaseService.event_menu_count_about_us_button_clicked)}
          >
            <div className="nav-link-wrapper">
              <span>Hakkımızda</span>
              <div className="img-wrapper">
                <img src={info} alt="info"/>
              </div>
            </div>
          </NavLink>
          <hr className="line"/>
          <NavLink
            to={router.FAQ}
            onClick={() => FirebaseService.fireEvent(FirebaseService.event_menu_count_faq_button_clicked)}
          >
            <div className="nav-link-wrapper">
              <span>Sıkça Sorulan Sorular</span>
              <div className="img-wrapper">
                <img src={question} alt="question"/>
              </div>
            </div>
          </NavLink>
          <hr className="line"/>
          <NavLink
            to={router.PHOTO_GALLERY}
          >
            <div className="nav-link-wrapper">
              <span>Galeri</span>
              <div className="img-wrapper">
                <img className="gallery" src={gallery} alt="gallery"/>
              </div>
            </div>
          </NavLink>
          <hr className="line"/>
          <NavLink
            to={router.CONTACT_US}
            onClick={() => FirebaseService.fireEvent(FirebaseService.event_menu_count_contact_us_button_clicked)}
          >
            <div className="nav-link-wrapper">
              <span>Bize Ulaş</span>
              <div className="img-wrapper">
                <img className="mail" src={mail} alt="mail"/>
              </div>
            </div>
          </NavLink>
          <hr className="line"/>

          <div className="d-flex justify-content-center">
            <Button
              className="link-button my-5"
              type="link"
              onClick={() => this.handleLogout()}
            >
              Çıkış Yap
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(MenuPage);
