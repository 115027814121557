import React from "react";
import "./PageTitle.scss";

interface IProps {
  title: string;
}

const PageTitle = (props: IProps) => {

  return (
    <p id="page-title">
      {props.title}
    </p>
  );
}

export default PageTitle;
