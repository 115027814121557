
import React, { ReactNode } from "react";
import {history} from "../../core/helpers/history";
import {router} from "../../core/helpers/router";
import {connect} from "react-redux";
import { EmailConfirmState } from "../../core/types/emailConfirm";
import {IStore} from "../../core/reducers";
import {bindActionCreators, Dispatch} from "redux";
import { ErrorDto } from "../../core/models/dtos/error.dto";
import {fetchEmailConfirm} from "../../core/actions/emailConfirm";
import "./EmailConfirmPage.scss";
import { Spin } from "antd";
import { Utilities } from "../../core/helpers/utilities";

interface IProps {
    match: any,
    loading: boolean,
    error?: ErrorDto, 
    fetchEmailConfirm: (hash:string) => void
}

class EmailConfirmPage extends React.Component<IProps> {
    private readonly hash: string | null;

    constructor(props: IProps) {
        super(props);
        this.hash = Utilities.getQueryParam("hash");
    }

    componentDidMount() {
        if (this.hash) {
            this.props.fetchEmailConfirm(this.hash);
        } else {
            history.push(router.HOME);
        }
    }

    private renderContent():ReactNode {
        if (this.props.loading) {
            return <Spin size="large" className="my-5"/>
        } else {
            return (
                <div className="section section-email-confirm">
                    <div className="heading">
                        <div className="info">
                            <span className="title">
                                Email confirm
                            </span>
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <div id="email-confirm-page" className="page">
                <div className="page-content">
                    {this.renderContent()}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
      {
        fetchEmailConfirm,
      },
      dispatch
    );
  };
  
  const mapStateToProps = (store: IStore) => {
    const state: EmailConfirmState = store.emailConfirm;
    return {
      loading: state.loading,
      error: state.error,
    }
  };

  export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmPage);
