import parse from "html-react-parser";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import moreArrow from "../../assets/images/more-campaign-arrow.svg";
import headingCard from "../../assets/images/section-heading-card.svg";
import headingFener from "../../assets/images/section-heading-fener.svg";
import headingGift from "../../assets/images/section-heading-gift.svg";
import PriorityTicketBanner from "../../components/Banners/PriorityTicketBanner/PriorityTicketBanner";
import VotingBanner from "../../components/Banners/VotingBanner/VotingBanner";
import WheelBanner from "../../components/Banners/WheelBanner/WheelBanner";
import CampaignCard from "../../components/CampaignCard/CampaignCard";
import GenericModal, {
  ModalType,
} from "../../components/Modals/GenericModal/GenericModal";
import PollModal from "../../components/Modals/PollModal/PollModal";
import ProfileWarningModal from "../../components/Modals/ProfileWarningModal/ProfileWarningModal";
import Personality from "../../components/Personality/Personality";
import PersonalitySmall from "../../components/PersonalitySmall/PersonalitySmall";
import UserFanCard from "../../components/UserFanCard/UserFanCard";
import {
  fetchCampaigns,
  fetchPersonality,
  setPersonality,
} from "../../core/actions/app";
import { Constants } from "../../core/helpers/constants";
import { history } from "../../core/helpers/history";
import { router } from "../../core/helpers/router";
import {
  getStorage,
  removeStorage,
  storageVposResult,
} from "../../core/helpers/storage";
import { CampaignDto } from "../../core/models/dtos/campaign.dto";
import { ErrorDto } from "../../core/models/dtos/error.dto";
import { PersonalityDto } from "../../core/models/dtos/personality.dto";
import { PollDto } from "../../core/models/dtos/poll.dto";
import { UserMeDto } from "../../core/models/dtos/userMe.dto";
import { CampaignState } from "../../core/models/enums/campaignState";
import { IStore } from "../../core/reducers";
import FirebaseService from "../../core/services/firebase.service";
import "./HomePage.scss";

interface IProps {
  isVotingAvailable: boolean;
  userMe?: UserMeDto;
  loadingPersonality: boolean;
  dataPersonality?: PersonalityDto;
  errorPersonality?: ErrorDto;
  loadingCampaigns: boolean;
  dataCampaigns: CampaignDto[];
  errorCampaigns?: ErrorDto;
  dataActivePoll?: PollDto[];
  pollModalQuestion?: PollDto;
  setPersonality: (personalityDto?: PersonalityDto) => void;
  fetchPersonality: (personalityId: number) => void;
  fetchCampaigns: () => void;
}

interface IState {
  isModalPaymentSuccessVisible: boolean;
}

class HomePage extends React.Component<IProps> {
  state: IState = {
    isModalPaymentSuccessVisible: false,
  };

  private readonly votingBannerAnchorRef: React.RefObject<HTMLSpanElement> =
    React.createRef();
  private readonly wheelBannerAnchorRef: React.RefObject<HTMLSpanElement> =
    React.createRef();

  componentDidMount() {
    FirebaseService.fireEvent(FirebaseService.event_user_count_view_main_page);

    const personalityType = this.props.userMe?.personalityType;
    if (personalityType) {
      this.props.fetchPersonality(personalityType);
    } else {
      this.props.setPersonality();
    }

    if (this.props.dataCampaigns.length === 0) {
      this.props.fetchCampaigns();
    }

    if (getStorage(storageVposResult) === Constants.vposResultSuccess) {
      this.setModalPaymentSuccessVisibility(true);
    }
  }

  private setModalPaymentSuccessVisibility(isVisible: boolean): void {
    removeStorage(storageVposResult);
    this.setState({ isModalPaymentSuccessVisible: isVisible });
  }

  private handleGift(): void {
    history.push(router.GIFT);
  }

  private scrollToVotingBanner() {
    this.votingBannerAnchorRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  private scrollToWheelBanner() {
    this.wheelBannerAnchorRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  private handleProfileWarningClose(): void {
    // if (this.props.isVotingAvailable) {
    //   if (this.props.dataActivePoll && this.props.dataActivePoll.length > 0) {
    //     this.scrollToVotingBanner();
    //   }
    // } else {
    //   history.push(router.PROFILE);
    // }
    this.scrollToWheelBanner();
  }

  private renderCampaign(campaign: CampaignDto) {
    return (
      <CampaignCard
        key={`campaign-${campaign.id}`}
        campaign={campaign}
        userMe={this.props.userMe}
      />
    );
  }

  render() {
    return (
      <div id="home-page" className="page">
        <div className="page-content">
          <div className="section section-share">
            <div className="body">
              <div className="d-flex justify-content-center align-items-center">
                <PersonalitySmall
                  activeQuizId={this.props.userMe?.activeQuizId}
                  personality={this.props.dataPersonality}
                />
                <UserFanCard userMe={this.props.userMe} />
              </div>
            </div>
          </div>

          <PriorityTicketBanner />

          <WheelBanner wheelBannerAnchorRef={this.wheelBannerAnchorRef} />

          <div className="section section-gift">
            <div className="heading">
              <img src={headingGift} alt="gift" />
              <div className="info">
                <span className="title">
                  {parse(
                    FirebaseService.getValue(FirebaseService.home_point_header)
                  )}
                </span>
                <span className="text">
                  {parse(
                    FirebaseService.getValue(
                      FirebaseService.home_point_description
                    )
                  )}
                </span>
              </div>
            </div>
            <div className="body">
              <div className="gift-box">
                <div className="box">
                  <p className="title">
                    Her ay hediye
                    <br />
                    11.000 FreeByte
                  </p>
                  <div className="button" onClick={() => this.handleGift()}>
                    HEMEN AL
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section section-card">
            <div className="heading">
              <img src={headingCard} alt="card" />
              <div className="info">
                <span className="title">
                  {parse(
                    FirebaseService.getValue(
                      FirebaseService.home_campaign_headar
                    )
                  )}
                </span>
                <span className="text">
                  {parse(
                    FirebaseService.getValue(
                      FirebaseService.home_campaign_description
                    )
                  )}
                </span>
              </div>
            </div>
            <div className="body">
              <div className="campaign-wrapper">
                <div className="d-flex d-md-none">
                  {this.props.dataCampaigns
                    .filter((c) => c.state === CampaignState.ONGOING)
                    .slice(0, 3)
                    .map((campaign) => this.renderCampaign(campaign))}
                </div>
                <div className="d-none d-md-flex">
                  {this.props.dataCampaigns
                    .filter((c) => c.state === CampaignState.ONGOING)
                    .slice(0, 4)
                    .map((campaign) => this.renderCampaign(campaign))}
                </div>
                <NavLink
                  className="more"
                  to={router.CAMPAIGNS}
                  onClick={() =>
                    FirebaseService.fireEvent(
                      FirebaseService.event_user_count_more_offer_button_clicked
                    )
                  }
                >
                  <span className="more-text">
                    DAHA
                    <br />
                    FAZLA
                    <br />
                    AYRICALIK
                  </span>
                  <img className="more-image" src={moreArrow} alt="arrrow" />
                </NavLink>
              </div>
            </div>
          </div>

          <span ref={this.votingBannerAnchorRef} />
          {this.props.isVotingAvailable && <VotingBanner />}
          {/*{*/}
          {/*  this.props.isVotingAvailable*/}
          {/*    ?*/}
          {/*    <VotingBanner/>*/}
          {/*    :*/}
          {/*    <div className="section section-voting">*/}
          {/*      <div className="heading">*/}
          {/*        <img src={headingVoting} alt="voting"/>*/}
          {/*        <div className="info">*/}
          {/*      <span className="title">*/}
          {/*        {parse(FirebaseService.getValue(FirebaseService.home_referandum_header))}*/}
          {/*      </span>*/}
          {/*          <span className="text">*/}
          {/*        {parse(FirebaseService.getValue(FirebaseService.home_referandum_description))}*/}
          {/*      </span>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="body">*/}
          {/*        <img src={imageVoting} alt="voting"/>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*}*/}

          {/*<div className="section section-mail">*/}
          {/*  <div className="heading">*/}
          {/*    <img src={headingMail} alt="mail"/>*/}
          {/*    <div className="info">*/}
          {/*      <span className="title">*/}
          {/*        {parse(FirebaseService.getValue(FirebaseService.home_mail_header))}*/}
          {/*      </span>*/}
          {/*      <span className="text">*/}
          {/*        {parse(FirebaseService.getValue(FirebaseService.home_mail_description))}*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="body">*/}
          {/*    <div className="content">*/}
          {/*      <div className="text-wrapper">*/}
          {/*        <span className="text-upper">*/}
          {/*          Adına özel mail adresi*/}
          {/*        </span>*/}
          {/*        <span className="text-lower">*/}
          {/*          adın<span className="at">@</span>fenerbahce.com.tr*/}
          {/*        </span>*/}
          {/*      </div>*/}
          {/*      <img className="image" src={imageMail} alt="mail"/>*/}
          {/*    </div>*/}
          {/*    <div className="soon">*/}
          {/*      ÇOK YAKINDA*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="section section-fener">
            <div className="heading">
              <img src={headingFener} alt="fener" />
              <div className="info">
                <span className="title">
                  {parse(
                    FirebaseService.getValue(FirebaseService.home_poll_header)
                  )}
                </span>
                <span className="text">
                  {parse(
                    FirebaseService.getValue(
                      FirebaseService.home_poll_description
                    )
                  )}
                </span>
              </div>
            </div>
            <div className="body">
              <Personality
                activeQuizId={this.props.userMe?.activeQuizId}
                personality={this.props.dataPersonality}
              />
            </div>
          </div>
        </div>

        {this.props.pollModalQuestion && <PollModal />}

        <ProfileWarningModal
          text={parse(
            FirebaseService.getValue(FirebaseService.profile_info_error_message)
          )}
          buttonText={parse(
            FirebaseService.getValue(FirebaseService.profile_info_error_button)
          )}
          callbackClose={() => this.handleProfileWarningClose()}
        />

        <GenericModal
          isVisible={this.state.isModalPaymentSuccessVisible}
          type={ModalType.success}
          title={FirebaseService.getValue(
            FirebaseService.vpos_payment_succes_header
          )}
          text={FirebaseService.getValue(
            FirebaseService.vpos_payment_succes_description
          )}
          button={FirebaseService.getValue(
            FirebaseService.vpos_payment_succes_button
          )}
          callbackOk={() => this.setModalPaymentSuccessVisibility(false)}
          callbackCancel={() => this.setModalPaymentSuccessVisibility(false)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setPersonality,
      fetchPersonality,
      fetchCampaigns,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    isVotingAvailable: store.app.isVotingAvailable,
    userMe: store.app.userMe.data,
    loadingPersonality: store.app.personality.loading,
    dataPersonality: store.app.personality.data,
    errorPersonality: store.app.personality.error,
    loadingCampaigns: store.app.campaigns.loading,
    dataCampaigns: store.app.campaigns.data,
    errorCampaigns: store.app.campaigns.error,
    dataActivePoll: store.poll.activePoll.data,
    pollModalQuestion: store.poll.pollModalQuestion,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
