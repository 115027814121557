import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import left from "../../../assets/images/chevron-left-white.svg";
import faq from "../../../assets/images/faq.svg";
import {logout} from "../../../core/actions/app";
import {history} from "../../../core/helpers/history";
import {router} from "../../../core/helpers/router";
import {IStore} from "../../../core/reducers";
import "./AuthHeaderMobile.scss";

interface IProps {
  isBackArrow?: boolean;
  pageTitle: string;
  logout: () => void;
}

const AuthHeaderMobile = (props: IProps) => {

  const handleBack = (): void => {
    props.logout();
    history.push(router.LANDING);
  }

  const handleFaq = (): void => {
    history.push(router.FAQ);
  }

  return (
    <div id="auth-header-mobile">
      <div className="auth-header-mobile-content">
        {
          props.isBackArrow !== false &&
          <img
            className="left" src={left} alt="left"
            onClick={handleBack}
          />
        }
        <label className="page-title">{props.pageTitle}</label>
        <img
          className="faq" src={faq} alt="faq"
          onClick={handleFaq}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthHeaderMobile);
