import { Button, Modal } from "antd";
import { ReactNode } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { setProfileWarningVisibility } from "../../../core/actions/app";
import { Constants } from "../../../core/helpers/constants";
import { UserMeDto } from "../../../core/models/dtos/userMe.dto";
import { IStore } from "../../../core/reducers";
import "./ProfileWarningModal.scss";

interface IProps {
  pathname: string;
  userMe?: UserMeDto;
  text: ReactNode;
  buttonText: ReactNode;
  isProfileWarningVisible: boolean;
  setProfileWarningVisibility: (isVisible: boolean) => void;
  callbackClose: () => void;
}

const ProfileWarningModal = (props: IProps) => {
  const isVisible = (): boolean => {
    // const isFirstName: boolean = !!props.userMe?.firstName;
    // const isLastName: boolean = !!props.userMe?.lastName;
    // const isID: boolean = !!props.userMe?.tckn || !!props.userMe?.passportNumber;
    // return (
    //   (!isFirstName || !isLastName || !isID) &&
    //   props.isProfileWarningVisible
    // );
    return props.isProfileWarningVisible;
  };

  const handleOk = (): void => {
    props.setProfileWarningVisibility(false);
    props.callbackClose();
  };

  return (
    <Modal
      className="profile-warning-modal"
      maskStyle={{ backdropFilter: "blur(8px)" }}
      visible={isVisible()}
      centered
      closable={false}
      footer={false}
      destroyOnClose
    >
      <div className="body">
        <div className="header">
          <img className="logo" src={Constants.fenerLogo} alt="logo" />
        </div>

        <p className="message">{props.text}</p>

        <Button className="button" onClick={handleOk}>
          {props.buttonText}
        </Button>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setProfileWarningVisibility,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    pathname: store.app.pathname,
    userMe: store.app.userMe.data,
    isProfileWarningVisible: store.app.isProfileWarningVisible,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileWarningModal);
