import React from "react";
import imageClose from "../../../assets/images/x.svg";
import "./ModalCloseButton.scss";

interface IProps {
  callback: () => void
}

const ModalCloseButton = (props: IProps) => {

  return (
    <div
      id="modal-close-button"
      onClick={props.callback}
    >
      <img src={imageClose} alt="close"/>
    </div>
  );
}

export default ModalCloseButton;
