import parse from "html-react-parser";
import React, {ReactNode} from "react";
import pin from "../../assets/images/notification-pin.svg";
import {Utilities} from "../../core/helpers/utilities";
import {NotificationDto} from "../../core/models/dtos/notification.dto";
import FirebaseService from "../../core/services/firebase.service";
import NotificationCard from "./NotificationCard/NotificationCard";
import "./NotificationsPage.scss";

interface IProps {
}

interface IState {
  notifications: NotificationDto[];
}

class NotificationsPage extends React.Component<IProps> {
  state: IState = {
    notifications: [],
  }

  private isTodayLabelAdded = false;
  private isYesterdayLabelAdded = false;
  private isEarlierLabelAdded = false;

  componentDidMount() {
    FirebaseService.fireEvent(FirebaseService.event_user_count_view_notifications);
    this.setNotifications();
  }

  private setNotifications(): void {
    const notifications = FirebaseService.getNotifications();
    this.setState({notifications});
  }

  private renderTimeLabel(notification: NotificationDto, index: number): ReactNode {
    if (index === 0 && notification.isMarked) {
      return (
        <div className="time-label">
          <img className="pin" src={pin} alt="pin"/>
          <span className="label">Sabitlenen</span>
        </div>
      );
    } else if (Utilities.getTimeDifference(notification.date, "days") === 0 && !this.isTodayLabelAdded) {
      this.isTodayLabelAdded = true;
      return <span className="time-label">Bugün</span>;
    } else if (Utilities.getTimeDifference(notification.date, "days") === 1 && !this.isYesterdayLabelAdded) {
      this.isYesterdayLabelAdded = true;
      return <span className="time-label">Dün</span>;
    } else if (Utilities.getTimeDifference(notification.date, "days") > 1 && !this.isEarlierLabelAdded) {
      this.isEarlierLabelAdded = true;
      return <span className="time-label">Önce</span>;
    }
    return <React.Fragment/>;
  }

  private renderNotificationsEmpty(): ReactNode {
    return (
      <div className="section section-notifications-title">
        <div className="heading">
          <div className="info">
            <span className="title">
              {parse(FirebaseService.getValue(FirebaseService.notification_empty))}
            </span>
          </div>
        </div>
      </div>
    );
  }

  private renderNotifications(): ReactNode {
    return (
      this.state.notifications.map((notification, index) => {
        return (
          <React.Fragment>
            {this.renderTimeLabel(notification, index)}
            <NotificationCard
              key={`notification-${index}`}
              notification={notification}
            />
          </React.Fragment>
        );
      })
    );
  }

  render() {
    return (
      <div id="notifications-page" className="page">
        <div className="page-content">
          {this.state.notifications.length === 0
            ? this.renderNotificationsEmpty()
            : this.renderNotifications()
          }
        </div>
      </div>
    );
  }
}

export default NotificationsPage;
