import axios from "../helpers/axios";
import {PersonalityDto} from "../models/dtos/personality.dto";

export default class PersonalityService {

  async getPersonality(personalityId: number): Promise<PersonalityDto> {
    try {
      const response = await axios.get<PersonalityDto>(
        `${process.env.REACT_APP_BASE_URL}/personality/${personalityId}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async resetAvatar(): Promise<void> {
    try {
      const response = await axios.patch<void>(
        `${process.env.REACT_APP_BASE_URL}/personality/reset-avatar`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
