import parse from "html-react-parser";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import headingWheel from "../../../assets/images/section-heading-wheel.svg";
import wheelDesktop from "../../../assets/images/wheel-banner.gif";
import {
  setWheelModalVisibility,
  setWheelPrize,
} from "../../../core/actions/app";
import { history } from "../../../core/helpers/history";
import { router } from "../../../core/helpers/router";
import { UserMeDto } from "../../../core/models/dtos/userMe.dto";
import { WheelHashDetailDto } from "../../../core/models/dtos/wheelHashDetail.dto";
import { WheelOfferDto } from "../../../core/models/dtos/wheelOffer.dto";
import { IStore } from "../../../core/reducers";
import FirebaseService from "../../../core/services/firebase.service";
import "./WheelBanner.scss";

interface IProps {
  wheelBannerAnchorRef: React.RefObject<HTMLSpanElement>;
  userMe?: UserMeDto;
  isWheelModalVisible: boolean;
  wheelPrize?: WheelOfferDto;
  dataHashDetail?: WheelHashDetailDto;
  setWheelModalVisibility: (isVisible: boolean) => void;
  setWheelPrize: (prize?: WheelOfferDto) => void;
}

const WheelBanner = (props: IProps) => {
  // const [isHowToUseModalVisible, setIsHowToUseModalVisible] = useState(false);

  // const handleWheelClose = (prize?: WheelOfferDto): void => {
  //   props.setWheelModalVisibility(false);
  //   props.setWheelPrize(prize);
  // };

  // const handleWheelCongratsClose = (): void => {
  //   props.setWheelPrize();
  // };

  return (
    <div id="wheel-banner" className="section">
      <div className="heading">
        <img src={headingWheel} alt="wheel" />
        <div className="info">
          <span className="title">
            {parse(FirebaseService.getValue(FirebaseService.home_wheel_header))}
          </span>
          <span className="text">
            {parse(
              FirebaseService.getValue(FirebaseService.home_wheel_description)
            )}
          </span>
        </div>
      </div>
      <div className="body">
        <div className="side left">
          <span ref={props.wheelBannerAnchorRef} />
          <React.Fragment>
            <p className="title">
              {parse(
                FirebaseService.getValue(
                  FirebaseService.home_wheel_banner_active_text
                )
              )}
            </p>
            <div className="button" onClick={() => history.push(router.WHEEL)}>
              {parse(
                FirebaseService.getValue(
                  FirebaseService.home_wheel_banner_active
                )
              )}
            </div>
          </React.Fragment>
        </div>
        <div className="side right">
          <img className="wheel d-flex" src={wheelDesktop} alt="wheel" />
        </div>
      </div>

      {/* {props.isWheelModalVisible && (
        <WheelModal
          hash={props.userMe?.wheelHash!}
          callbackClose={(prize) => handleWheelClose(prize)}
        />
      )}

      {props.wheelPrize && (
        <WheelCongratsModal
          externalData={props.dataHashDetail?.externalData}
          prize={props.wheelPrize}
          callbackClose={handleWheelCongratsClose}
          callbackHowToUse={() => setIsHowToUseModalVisible(true)}
        />
      )}
      {isHowToUseModalVisible && (
        <HowToUseModal
          terms={props.wheelPrize?.terms}
          callbackClose={() => setIsHowToUseModalVisible(false)}
        />
      )} */}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setWheelModalVisibility,
      setWheelPrize,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    userMe: store.app.userMe.data,
    isWheelModalVisible: store.app.isWheelModalVisible,
    wheelPrize: store.app.wheelPrize,
    dataHashDetail: store.wheel.hashDetail.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WheelBanner);
