import { Dispatch } from "redux";
import {
  API_ERROR_RESET,
  API_ERROR_SET,
  CAMPAIGNS_FETCH_FAIL,
  CAMPAIGNS_FETCH_START,
  CAMPAIGNS_FETCH_SUCCESS,
  CAMPAIGNS_RESET,
  CODE_SUBMIT_FAIL,
  CODE_SUBMIT_RESET,
  CODE_SUBMIT_START,
  CODE_SUBMIT_SUCCESS,
  FIREBASE_REMOTE_CONFIG_FETCH_START,
  FIREBASE_REMOTE_CONFIG_FETCH_SUCCESS,
  LOGOUT,
  PERSONALITY_FETCH_FAIL,
  PERSONALITY_FETCH_START,
  PERSONALITY_FETCH_SUCCESS,
  PERSONALITY_RESET,
  PERSONALITY_RESET_AVATAR_FAIL,
  PERSONALITY_RESET_AVATAR_RESET,
  PERSONALITY_RESET_AVATAR_START,
  PERSONALITY_RESET_AVATAR_SUCCESS,
  SET_APP_VISIBILITY,
  SET_CONSENT_APPROVAL_VISIBILITY,
  SET_CONSENT_VISIBILITY,
  SET_PATHNAME,
  SET_PERSONALITY,
  SET_PROFILE_WARNING_VISIBILITY,
  SET_USER_ME,
  SET_VOTING_AVAILABILITY,
  SET_WHEEL_PRIZE,
  SET_WHEEL_VISIBILITY,
  USER_ME_FETCH_FAIL,
  USER_ME_FETCH_START,
  USER_ME_FETCH_SUCCESS,
  USER_ME_RESET
} from "../constants/app";
import { history } from "../helpers/history";
import { router } from "../helpers/router";
import { removeStorage, setStorage, storageToken } from "../helpers/storage";
import { Utilities } from "../helpers/utilities";
import { CampaignDto } from "../models/dtos/campaign.dto";
import { ErrorDto, ErrorGeneric, getErrorDtoFromApiError } from "../models/dtos/error.dto";
import { PersonalityDto } from "../models/dtos/personality.dto";
import { UserMeDto } from "../models/dtos/userMe.dto";
import { WheelOfferDto } from "../models/dtos/wheelOffer.dto";
import { SubscriptionState } from "../models/enums/subscriptionState";
import AuthService from "../services/auth.service";
import CampaignService from "../services/campaign.service";
import FirebaseService from "../services/firebase.service";
import GTMService from "../services/gtm.service";
import PersonalityService from "../services/personality.service";
import UserService from "../services/user.service";
import {
  ApiErrorResetAction,
  ApiErrorSetAction,
  CampaignsFetchFailAction,
  CampaignsFetchStartAction,
  CampaignsFetchSuccessAction,
  CampaignsResetAction,
  CodeSubmitFailAction,
  CodeSubmitResetAction,
  CodeSubmitStartAction,
  CodeSubmitSuccessAction,
  FirebaseRemoteConfigFetchStartAction,
  FirebaseRemoteConfigFetchSuccessAction,
  LogoutAction,
  PersonalityFetchFailAction,
  PersonalityFetchStartAction,
  PersonalityFetchSuccessAction,
  PersonalityResetAction,
  PersonalityResetAvatarFailAction,
  PersonalityResetAvatarResetAction,
  PersonalityResetAvatarStartAction,
  PersonalityResetAvatarSuccessAction,
  SetAppVisibilityAction,
  SetConsentApprovalVisibilityAction,
  SetConsentVisibilityAction,
  SetPathnameAction,
  SetPersonalityAction,
  SetProfileWarningVisibilityAction,
  SetUserMeAction,
  SetVotingAvailabilityAction,
  SetWheelPrizeAction,
  SetWheelVisibilityAction,
  UserMeFetchFailAction,
  UserMeFetchStartAction,
  UserMeFetchSuccessAction,
  UserMeResetAction
} from "../types/app";

const authService: AuthService = new AuthService();
const userService: UserService = new UserService();
const personalityService: PersonalityService = new PersonalityService();
const campaignService: CampaignService = new CampaignService();

/**
 * FIREBASE
 */
const firebaseRemoteConfigFetchStartAction = (): FirebaseRemoteConfigFetchStartAction => {
  return {
    type: FIREBASE_REMOTE_CONFIG_FETCH_START,
  }
}
const firebaseRemoteConfigFetchSuccessAction = (): FirebaseRemoteConfigFetchSuccessAction => {
  return {
    type: FIREBASE_REMOTE_CONFIG_FETCH_SUCCESS,
  }
}
export const fetchAndActivateRemoteConfig = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(firebaseRemoteConfigFetchStartAction());
    await FirebaseService.fetchAndActivateRemoteConfig();
    dispatch(firebaseRemoteConfigFetchSuccessAction());
    FirebaseService.sendMarketingEventIfAvailable();
  } catch (error) {
    dispatch(firebaseRemoteConfigFetchSuccessAction());
  }
};

/**
 * PATHNAME
 */
const setPathnameAction = (payload: string): SetPathnameAction => {
  return {
    type: SET_PATHNAME,
    payload: payload,
  }
}
export const setPathname = (pathname: string) => (
  dispatch: Dispatch
) => {
  dispatch(setPathnameAction(pathname));
}

/**
 * USER_ME
 */
export const setUserMeAction = (payload: UserMeDto): SetUserMeAction => {
  return {
    type: SET_USER_ME,
    payload: payload,
  }
}
export const setUserMe = (userMe: UserMeDto) => (
  dispatch: Dispatch
) => {
  dispatch(setUserMeAction(userMe));
}

/**
 * PERSONALITY
 */
export const setPersonalityAction = (payload?: PersonalityDto): SetPersonalityAction => {
  return {
    type: SET_PERSONALITY,
    payload: payload,
  }
}
export const setPersonality = (personality?: PersonalityDto) => (
  dispatch: Dispatch
) => {
  dispatch(setPersonalityAction(personality));
}

/**
 * APP_VISIBILITY
 */
export const setAppVisibilityAction = (payload: boolean): SetAppVisibilityAction => {
  return {
    type: SET_APP_VISIBILITY,
    payload: payload,
  }
}
export const setAppVisibility = (isAppVisible: boolean) => (
  dispatch: Dispatch
) => {
  dispatch(setAppVisibilityAction(isAppVisible));
}

/**
 * PROFILE_WARNING_VISIBILITY
 */
const setProfileWarningVisibilityAction = (payload: boolean): SetProfileWarningVisibilityAction => {
  return {
    type: SET_PROFILE_WARNING_VISIBILITY,
    payload: payload,
  }
}
export const setProfileWarningVisibility = (isProfileWarningVisible: boolean) => (
  dispatch: Dispatch
) => {
  dispatch(setProfileWarningVisibilityAction(isProfileWarningVisible));
}

/**
 * WHEEL_VISIBILITY
 */
const setWheelVisibilityAction = (payload: boolean): SetWheelVisibilityAction => {
  return {
    type: SET_WHEEL_VISIBILITY,
    payload: payload,
  }
}
export const setWheelModalVisibility = (isWheelModalVisible: boolean) => (
  dispatch: Dispatch
) => {
  dispatch(setWheelVisibilityAction(isWheelModalVisible));
}

/**
 * WHEEL_PRIZE
 */
const setWheelPrizeAction = (payload?: WheelOfferDto): SetWheelPrizeAction => {
  return {
    type: SET_WHEEL_PRIZE,
    payload: payload,
  }
}
export const setWheelPrize = (prize?: WheelOfferDto) => (
  dispatch: Dispatch
) => {
  dispatch(setWheelPrizeAction(prize));
}

/**
 * CONSENT_VISIBILITY
 */
const setConsentVisibilityAction = (payload: boolean): SetConsentVisibilityAction => {
  return {
    type: SET_CONSENT_VISIBILITY,
    payload: payload,
  }
}
export const setConsentModalVisibility = (isConsentModalVisible: boolean) => (
  dispatch: Dispatch
) => {
  dispatch(setConsentVisibilityAction(isConsentModalVisible));
}

/**
 * CONSENT_APPROVAL_VISIBILITY
 */
const setConsentApprovalVisibilityAction = (payload: boolean): SetConsentApprovalVisibilityAction => {
  return {
    type: SET_CONSENT_APPROVAL_VISIBILITY,
    payload: payload,
  }
}
export const setConsentApprovalModalVisibility = (isConsentApprovalModalVisible: boolean) => (
  dispatch: Dispatch
) => {
  dispatch(setConsentApprovalVisibilityAction(isConsentApprovalModalVisible));
}

/**
 * VOTING_AVAILABILITY
 */
const setVotingAvailabilityAction = (payload: boolean): SetVotingAvailabilityAction => {
  return {
    type: SET_VOTING_AVAILABILITY,
    payload: payload,
  }
}
export const setVotingAvailability = (isVotingAvailable: boolean) => (
  dispatch: Dispatch
) => {
  dispatch(setVotingAvailabilityAction(isVotingAvailable));
}

/**
 * CODE_SUBMIT
 */
const codeSubmitStartAction = (): CodeSubmitStartAction => {
  return {
    type: CODE_SUBMIT_START,
  };
}
const codeSubmitSuccessAction = (payload: boolean): CodeSubmitSuccessAction => {
  return {
    type: CODE_SUBMIT_SUCCESS,
    payload: payload,
  };
}
const codeSubmitFailAction = (error: ErrorDto): CodeSubmitFailAction => {
  return {
    type: CODE_SUBMIT_FAIL,
    error: error,
  };
}
const codeSubmitResetAction = (): CodeSubmitResetAction => {
  return {
    type: CODE_SUBMIT_RESET,
  };
}

export const codeSubmit = (code: string, route: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(codeSubmitStartAction());
    const tokenDto = await authService.loginWithCode({ code });
    if (tokenDto) {
      setStorage(storageToken, tokenDto.token);
      const userMeDto = await userService.getDetails();
      dispatch(userMeFetchSuccessAction(userMeDto));
      dispatch(codeSubmitSuccessAction(true));
      if (!route) {
        // empty route => HOME
        dispatch(setAppVisibilityAction(true));
      }
      history.push("/" + route);
      GTMService.eventLogin();
    } else {
      dispatch(codeSubmitFailAction(ErrorGeneric));
    }
  } catch (error) {
    dispatch(codeSubmitFailAction(getErrorDtoFromApiError(error)));
  }
};

export const codeSubmitReset = () => (dispatch: Dispatch) => {
  dispatch(codeSubmitResetAction());
};

/**
 * USER_ME
 */
const userMeFetchStartAction = (): UserMeFetchStartAction => {
  return {
    type: USER_ME_FETCH_START,
  }
}
const userMeFetchSuccessAction = (payload: UserMeDto): UserMeFetchSuccessAction => {
  return {
    type: USER_ME_FETCH_SUCCESS,
    payload: payload,
  }
}
const userMeFetchFailAction = (error: ErrorDto): UserMeFetchFailAction => {
  return {
    type: USER_ME_FETCH_FAIL,
    error: error,
  }
}
export const fetchUserMe = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(userMeFetchStartAction());
    const userMeDto = await userService.getDetails();
    if (userMeDto) {
      if (Utilities.isCurrentPathPaymentRedirect()) {
        dispatch(userMeFetchSuccessAction(userMeDto));
        return;
      }
      const subscriptionState = userMeDto.subscriptionState;
      if (subscriptionState === SubscriptionState.ACTIVE) {
        if (!Utilities.isUserRoleSubscriber()) {
          await authService.refresh();
        }
        // Success action must be after refresh
        dispatch(userMeFetchSuccessAction(userMeDto));
        dispatch(setAppVisibilityAction(true));
      } else {
        dispatch(userMeFetchSuccessAction(userMeDto));
        if (subscriptionState === SubscriptionState.PENDING) {
          if (!Utilities.isCurrentPathPaymentRedirect()) {
            const msisdn: string | null = Utilities.getMsisdnFromToken();
            msisdn ? Utilities.navigateToPayment(msisdn) : history.push(router.LANDING);
          }
        } else if (subscriptionState === SubscriptionState.INACTIVE) {
          history.push(router.LANDING);
        }
      }
    } else {
      dispatch(userMeFetchFailAction(ErrorGeneric));
    }
  } catch (error) {
    dispatch(userMeFetchFailAction(getErrorDtoFromApiError(error)));
  }
}

const userMeResetAction = (): UserMeResetAction => {
  return {
    type: USER_ME_RESET,
  }
}
export const resetUserMe = () => (
  dispatch: Dispatch
) => {
  dispatch(userMeResetAction());
}

/**
 * PERSONALITY
 */
const personalityFetchStartAction = (): PersonalityFetchStartAction => {
  return {
    type: PERSONALITY_FETCH_START,
  }
}
const personalityFetchSuccessAction = (payload: PersonalityDto): PersonalityFetchSuccessAction => {
  return {
    type: PERSONALITY_FETCH_SUCCESS,
    payload: payload,
  }
}
const personalityFetchFailAction = (error: ErrorDto): PersonalityFetchFailAction => {
  return {
    type: PERSONALITY_FETCH_FAIL,
    error: error,
  }
}
export const fetchPersonality = (personalityId: number) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(personalityFetchStartAction());
    const personalityDto = await personalityService.getPersonality(personalityId);
    if (personalityDto) {
      dispatch(personalityFetchSuccessAction(personalityDto));
    } else {
      dispatch(personalityFetchFailAction(ErrorGeneric));
    }
  } catch (error) {
    dispatch(personalityFetchFailAction(getErrorDtoFromApiError(error)));
  }
}

const personalityResetAction = (): PersonalityResetAction => {
  return {
    type: PERSONALITY_RESET,
  }
}
export const resetPersonality = () => (
  dispatch: Dispatch
) => {
  dispatch(personalityResetAction());
}

/**
 * PERSONALITY
 */
const personalityResetAvatarStartAction = (): PersonalityResetAvatarStartAction => {
  return {
    type: PERSONALITY_RESET_AVATAR_START,
  }
}
const personalityResetAvatarSuccessAction = (payload: boolean): PersonalityResetAvatarSuccessAction => {
  return {
    type: PERSONALITY_RESET_AVATAR_SUCCESS,
    payload: payload,
  }
}
const personalityResetAvatarFailAction = (error: ErrorDto): PersonalityResetAvatarFailAction => {
  return {
    type: PERSONALITY_RESET_AVATAR_FAIL,
    error: error,
  }
}
export const resetAvatar = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(personalityResetAvatarStartAction());
    await personalityService.resetAvatar();
    const userMeDto = await userService.getDetails();
    dispatch(setUserMeAction(userMeDto));
    dispatch(personalityResetAvatarSuccessAction(true));
  } catch (error) {
    dispatch(personalityResetAvatarFailAction(getErrorDtoFromApiError(error)));
  }
}

const personalityResetAvatarResetAction = (): PersonalityResetAvatarResetAction => {
  return {
    type: PERSONALITY_RESET_AVATAR_RESET,
  }
}
export const resetAvatarRest = () => (
  dispatch: Dispatch
) => {
  dispatch(personalityResetAvatarResetAction());
}

/**
 * CAMPAIGNS
 */
const campaignsFetchStartAction = (): CampaignsFetchStartAction => {
  return {
    type: CAMPAIGNS_FETCH_START,
  }
}
const campaignsFetchSuccessAction = (payload: CampaignDto[]): CampaignsFetchSuccessAction => {
  return {
    type: CAMPAIGNS_FETCH_SUCCESS,
    payload: payload,
  }
}
const campaignsFetchFailAction = (error: ErrorDto): CampaignsFetchFailAction => {
  return {
    type: CAMPAIGNS_FETCH_FAIL,
    error: error,
  }
}
export const fetchCampaigns = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(campaignsFetchStartAction());
    const campaigns = await campaignService.getAllCampaigns();
    if (campaigns) {
      dispatch(campaignsFetchSuccessAction(campaigns));
    } else {
      dispatch(campaignsFetchFailAction(ErrorGeneric));
    }
  } catch (error) {
    dispatch(campaignsFetchFailAction(getErrorDtoFromApiError(error)));
  }
}

const campaignsResetAction = (): CampaignsResetAction => {
  return {
    type: CAMPAIGNS_RESET,
  }
}
export const resetCampaigns = () => (
  dispatch: Dispatch
) => {
  dispatch(campaignsResetAction());
}

/**
 * API_ERROR
 */
const apiErrorSetAction = (payload: ErrorDto): ApiErrorSetAction => {
  return {
    type: API_ERROR_SET,
    payload: payload,
  }
}
export const setApiError = (errorDto: ErrorDto) => (
  dispatch: Dispatch
) => {
  dispatch(apiErrorSetAction(errorDto));
}

const apiErrorResetAction = (): ApiErrorResetAction => {
  return {
    type: API_ERROR_RESET,
  }
}
export const resetApiError = () => (
  dispatch: Dispatch
) => {
  dispatch(apiErrorResetAction());
}

/**
 * LOGOUT
 */
const logoutAction = (): LogoutAction => {
  return {
    type: LOGOUT,
  }
}
export const logout = () => (
  dispatch: Dispatch
) => {
  removeStorage(storageToken);
  dispatch(logoutAction());
}
