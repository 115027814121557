import {Dispatch} from "redux";
import {
  TEST_FINISH_FAIL,
  TEST_FINISH_RESET,
  TEST_FINISH_START,
  TEST_FINISH_SUCCESS,
  TEST_QUESTIONS_FETCH_FAIL,
  TEST_QUESTIONS_FETCH_START,
  TEST_QUESTIONS_FETCH_SUCCESS,
  TEST_QUESTIONS_RESET,
  TEST_UPDATE_QUESTION_ANSWER_FAIL,
  TEST_UPDATE_QUESTION_ANSWER_RESET,
  TEST_UPDATE_QUESTION_ANSWER_START,
  TEST_UPDATE_QUESTION_ANSWER_SUCCESS,
} from "../constants/test";
import {QuestionOption} from "../models/custom/questionOption";
import {ErrorDto, ErrorGeneric, getErrorDtoFromApiError} from "../models/dtos/error.dto";
import {QuizDto} from "../models/dtos/quiz";
import {UserMeDto} from "../models/dtos/userMe.dto";
import FirebaseService from "../services/firebase.service";
import PersonalityService from "../services/personality.service";
import QuizService from "../services/quiz.service";
import UserService from "../services/user.service";
import {
  TestFinishFailAction,
  TestFinishResetAction,
  TestFinishStartAction,
  TestFinishSuccessAction,
  TestQuestionsFetchFailAction,
  TestQuestionsFetchStartAction,
  TestQuestionsFetchSuccessAction,
  TestQuestionsResetAction,
  TestUpdateQuestionAnswerFailAction,
  TestUpdateQuestionAnswerResetAction,
  TestUpdateQuestionAnswerStartAction,
  TestUpdateQuestionAnswerSuccessAction
} from "../types/test";
import {setPersonalityAction, setUserMeAction} from "./app";

const quizService: QuizService = new QuizService();
const userService: UserService = new UserService();
const personalityService: PersonalityService = new PersonalityService();

/**
 * QUESTIONS_FETCH
 */
const testQuestionsFetchStartAction = (): TestQuestionsFetchStartAction => {
  return {
    type: TEST_QUESTIONS_FETCH_START,
  }
}
const testQuestionsFetchSuccessAction = (payload: QuizDto): TestQuestionsFetchSuccessAction => {
  return {
    type: TEST_QUESTIONS_FETCH_SUCCESS,
    payload: payload,
  }
}
const testQuestionsFetchFailAction = (error: ErrorDto): TestQuestionsFetchFailAction => {
  return {
    type: TEST_QUESTIONS_FETCH_FAIL,
    error: error,
  }
}
export const testFetchQuestions = (isContinue: boolean) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(testQuestionsFetchStartAction());
    let quizDto;
    if (isContinue) {
      quizDto = await quizService.getActiveQuiz();
    } else {
      quizDto = await quizService.startQuiz();
      const userMeDto = await userService.getDetails();
      dispatch(setUserMeAction(userMeDto));
    }

    if (quizDto) {
      dispatch(testQuestionsFetchSuccessAction(quizDto));
    } else {
      dispatch(testQuestionsFetchFailAction(ErrorGeneric));
    }
  } catch (error) {
    dispatch(testQuestionsFetchFailAction(getErrorDtoFromApiError(error)));
  }
}

const testQuestionsResetAction = (): TestQuestionsResetAction => {
  return {
    type: TEST_QUESTIONS_RESET,
  }
}
export const testResetQuestions = () => (
  dispatch: Dispatch
) => {
  dispatch(testQuestionsResetAction());
}

/**
 * UPDATE_QUESTION_ANSWER
 */
const testUpdateQuestionAnswerStartAction = (): TestUpdateQuestionAnswerStartAction => {
  return {
    type: TEST_UPDATE_QUESTION_ANSWER_START,
  }
}
const testUpdateQuestionAnswerSuccessAction = (payload: QuestionOption): TestUpdateQuestionAnswerSuccessAction => {
  return {
    type: TEST_UPDATE_QUESTION_ANSWER_SUCCESS,
    payload: payload,
  }
}
const testUpdateQuestionAnswerFailAction = (error: ErrorDto): TestUpdateQuestionAnswerFailAction => {
  return {
    type: TEST_UPDATE_QUESTION_ANSWER_FAIL,
    error: error,
  }
}
export const testUpdateQuestionAnswer = (questionOption: QuestionOption) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(testUpdateQuestionAnswerStartAction());
    await quizService.updateQuestionAnswer(questionOption.optionId);
    dispatch(testUpdateQuestionAnswerSuccessAction(questionOption));
    FirebaseService.fireEvent(FirebaseService.event_quiz_count_question_clicked);
  } catch (error) {
    dispatch(testUpdateQuestionAnswerFailAction(getErrorDtoFromApiError(error)));
  }
}

const testUpdateQuestionAnswerResetAction = (): TestUpdateQuestionAnswerResetAction => {
  return {
    type: TEST_UPDATE_QUESTION_ANSWER_RESET,
  }
}
export const testResetUpdateQuestionAnswer = () => (
  dispatch: Dispatch
) => {
  dispatch(testUpdateQuestionAnswerResetAction());
}

/**
 * FINISH
 */
const testFinishStartAction = (): TestFinishStartAction => {
  return {
    type: TEST_FINISH_START,
  }
}
const testFinishSuccessAction = (payload: boolean): TestFinishSuccessAction => {
  return {
    type: TEST_FINISH_SUCCESS,
    payload: payload,
  }
}
const testFinishFailAction = (error: ErrorDto): TestFinishFailAction => {
  return {
    type: TEST_FINISH_FAIL,
    error: error,
  }
}
export const testFinish = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(testFinishStartAction());
    await quizService.finishQuiz();
    const userMeDto = await userService.getDetails();
    if (!userMeDto.personalityType) {
      alert(`PersonalityType error: ${userMeDto.personalityType}`);
      dispatch(testFinishFailAction(ErrorGeneric));
      return;
    }
    const personalityDto = await personalityService.getPersonality(userMeDto.personalityType);
    dispatch(setUserMeAction(userMeDto));
    dispatch(setPersonalityAction(personalityDto));
    dispatch(testFinishSuccessAction(true));
    fbTestFinishEvents(userMeDto);
  } catch (error) {
    dispatch(testFinishFailAction(getErrorDtoFromApiError(error)));
  }
}

const testFinishResetAction = (): TestFinishResetAction => {
  return {
    type: TEST_FINISH_RESET,
  }
}
export const testResetFinish = () => (
  dispatch: Dispatch
) => {
  dispatch(testFinishResetAction());
}

const fbTestFinishEvents = (userMe: UserMeDto): void => {
  FirebaseService.fireEvent(FirebaseService.event_quiz_count_view_result);
  switch (userMe.personalityType) {
    case 1:
      FirebaseService.fireEvent(FirebaseService.event_quiz_count_view_result_tip1);
      break;
    case 2:
      FirebaseService.fireEvent(FirebaseService.event_quiz_count_view_result_tip2);
      break;
    case 3:
      FirebaseService.fireEvent(FirebaseService.event_quiz_count_view_result_tip3);
      break;
    case 4:
      FirebaseService.fireEvent(FirebaseService.event_quiz_count_view_result_tip4);
      break;
    case 5:
      FirebaseService.fireEvent(FirebaseService.event_quiz_count_view_result_tip5);
      break;
  }
}
