import React from "react";
import Iframe from "react-iframe";
import { Constants } from "../../core/helpers/constants";
import { history } from "../../core/helpers/history";
import { router } from "../../core/helpers/router";
import "./WheelPage.scss";

interface IProps {}

class WheelPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.goToHome();
  }

  private goToHome(): void {
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin !== process.env.REACT_APP_WHEEL_URL) return;

        if (event.data === Constants.walletEvent) {
          window.open(Constants.walletAppUrl, "_blank");
        }

        history.push(router.HOME);
      },
      false
    );
  }

  render() {
    return (
      <Iframe
        className="flex-grow-1"
        url={`${process.env.REACT_APP_WHEEL_URL}`}
        height={"100%"}
        width="100%"
        frameBorder={0}
        title="Fenerbahçe"
        allowFullScreen
      />
    );
  }
}

export default WheelPage;
