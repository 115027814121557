import axios from "../helpers/axios";
import {QuizDto} from "../models/dtos/quiz";

export default class QuizService {

  async startQuiz(quizId: number = 1): Promise<QuizDto> {
    try {
      const response = await axios.post<QuizDto>(
        `${process.env.REACT_APP_BASE_URL}/quiz/${quizId}/start`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getActiveQuiz(): Promise<QuizDto> {
    try {
      const response = await axios.get<QuizDto>(
        `${process.env.REACT_APP_BASE_URL}/quiz/active`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateQuestionAnswer(optionId: number): Promise<void> {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/quiz/active/${optionId}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async finishQuiz(): Promise<void> {
    try {
      const response = await axios.post<void>(
        `${process.env.REACT_APP_BASE_URL}/quiz/active/finish`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
