import {
  FETCH_ACTIVE_POLL_FAIL,
  FETCH_ACTIVE_POLL_RESET,
  FETCH_ACTIVE_POLL_START,
  FETCH_ACTIVE_POLL_SUCCESS,
  FETCH_HISTORY_FAIL,
  FETCH_HISTORY_RESET,
  FETCH_HISTORY_START,
  FETCH_HISTORY_SUCCESS,
  POST_POLL_ANSWER_FAIL,
  POST_POLL_ANSWER_RESET,
  POST_POLL_ANSWER_START,
  POST_POLL_ANSWER_SUCCESS,
  SET_POLL_MODAL_INITIAL_TAB,
  SET_POLL_MODAL_QUESTION
} from "../constants/poll";
import {PollActions, PollState} from "../types/poll";

const initialState: PollState = {
  pollModalInitialTab: undefined,
  pollModalQuestion: undefined,
  activePoll: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  answer: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  history: {
    loading: false,
    data: [],
    error: undefined,
  },
}

const reducer = (
  state = initialState,
  action: PollActions,
): PollState => {
  switch (action.type) {
    case FETCH_ACTIVE_POLL_START:
      return {
        ...state, // Do not use initial state here because of pollModalInitialTab
        activePoll: {
          ...initialState.activePoll,
          loading: true,
        },
        answer: {
          ...initialState.answer,
        },
      }
    case FETCH_ACTIVE_POLL_SUCCESS:
      return {
        ...state,
        activePoll: {
          ...state.activePoll,
          loading: false,
          data: action.payload,
        }
      }
    case FETCH_ACTIVE_POLL_FAIL:
      return {
        ...state,
        activePoll: {
          ...state.activePoll,
          loading: false,
          error: action.error,
        }
      }
    case FETCH_ACTIVE_POLL_RESET:
      return {
        ...state,
        activePoll: {
          ...initialState.activePoll,
        }
      }
    case SET_POLL_MODAL_INITIAL_TAB:
      return {
        ...state,
        pollModalInitialTab: action.payload,
      };
    case SET_POLL_MODAL_QUESTION:
      return {
        ...state,
        pollModalQuestion: action.payload,
      };
    case POST_POLL_ANSWER_START:
      return {
        ...state,
        answer: {
          ...initialState.answer,
          loading: true,
        },
      }
    case POST_POLL_ANSWER_SUCCESS:
      return {
        ...state,
        answer: {
          ...state.answer,
          loading: false,
          data: action.payload,
        }
      }
    case POST_POLL_ANSWER_FAIL:
      return {
        ...state,
        answer: {
          ...state.answer,
          loading: false,
          error: action.error,
        }
      }
    case POST_POLL_ANSWER_RESET:
      return {
        ...state,
        answer: {
          ...initialState.answer,
        }
      }
    case FETCH_HISTORY_START:
      return {
        ...state,
        history: {
          ...initialState.history,
          loading: true,
        },
      }
    case FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          loading: false,
          data: action.payload,
        }
      }
    case FETCH_HISTORY_FAIL:
      return {
        ...state,
        history: {
          ...state.history,
          loading: false,
          error: action.error,
        }
      }
    case FETCH_HISTORY_RESET:
      return {
        ...state,
        history: {
          ...initialState.history,
        }
      }
    default:
      return state;
  }
}

export default reducer;
