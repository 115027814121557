import React from "react";
import "./WelcomeText.scss";

const WelcomeText = () => {

  return (
    <div id="welcome-text">
      <h1 className="title">
        HOŞ GELDİN
      </h1>
      <p className="text">
        BÜYÜK FENERBAHÇE'NİN
        <br/>
        BÜYÜK TARAFTARI
      </p>
    </div>
  );
}

export default WelcomeText;
