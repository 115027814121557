export const FIREBASE_REMOTE_CONFIG_FETCH_START = 'FIREBASE_REMOTE_CONFIG_FETCH_START';
export const FIREBASE_REMOTE_CONFIG_FETCH_SUCCESS = 'FIREBASE_REMOTE_CONFIG_FETCH_SUCCESS';

export const SET_PATHNAME = 'SET_PATHNAME';
export const SET_USER_ME = 'SET_USER_ME';
export const SET_PERSONALITY = 'SET_PERSONALITY';
export const SET_APP_VISIBILITY = 'SET_APP_VISIBILITY';
export const SET_PROFILE_WARNING_VISIBILITY = 'SET_PROFILE_WARNING_VISIBILITY';
export const SET_WHEEL_VISIBILITY = 'SET_WHEEL_VISIBILITY';
export const SET_WHEEL_PRIZE = 'SET_WHEEL_PRIZE';
export const SET_CONSENT_VISIBILITY = 'SET_CONSENT_VISIBILITY';
export const SET_CONSENT_APPROVAL_VISIBILITY = 'SET_CONSENT_APPROVAL_VISIBILITY';
export const SET_VOTING_AVAILABILITY = 'SET_VOTING_AVAILABILITY';

export const CODE_SUBMIT_START = 'CODE_SUBMIT_START';
export const CODE_SUBMIT_SUCCESS = 'CODE_SUBMIT_SUCCESS';
export const CODE_SUBMIT_FAIL = 'CODE_SUBMIT_FAIL';
export const CODE_SUBMIT_RESET = 'CODE_SUBMIT_RESET';

export const USER_ME_FETCH_START = 'USER_ME_FETCH_START';
export const USER_ME_FETCH_SUCCESS = 'USER_ME_FETCH_SUCCESS';
export const USER_ME_FETCH_FAIL = 'USER_ME_FETCH_FAIL';
export const USER_ME_RESET = 'USER_ME_RESET';

export const PERSONALITY_FETCH_START = 'PERSONALITY_FETCH_START';
export const PERSONALITY_FETCH_SUCCESS = 'PERSONALITY_FETCH_SUCCESS';
export const PERSONALITY_FETCH_FAIL = 'PERSONALITY_FETCH_FAIL';
export const PERSONALITY_RESET = 'PERSONALITY_RESET';

export const PERSONALITY_RESET_AVATAR_START = 'PERSONALITY_RESET_AVATAR_START';
export const PERSONALITY_RESET_AVATAR_SUCCESS = 'PERSONALITY_RESET_AVATAR_SUCCESS';
export const PERSONALITY_RESET_AVATAR_FAIL = 'PERSONALITY_RESET_AVATAR_FAIL';
export const PERSONALITY_RESET_AVATAR_RESET = 'PERSONALITY_RESET_AVATAR_RESET';

export const CAMPAIGNS_FETCH_START = 'CAMPAIGNS_FETCH_START';
export const CAMPAIGNS_FETCH_SUCCESS = 'CAMPAIGNS_FETCH_SUCCESS';
export const CAMPAIGNS_FETCH_FAIL = 'CAMPAIGNS_FETCH_FAIL';
export const CAMPAIGNS_RESET = 'CAMPAIGNS_RESET';

export const EMAIL_CONFIRM_FETCH_START = 'EMAIL_CONFIRM_FETCH_START';
export const EMAIL_CONFIRM_FETCH_SUCCESS = 'EMAIL_CONFIRM_FETCH_SUCCESS';
export const EMAIL_CONFIRM_FETCH_FAIL = 'EMAIL_CONFIRM_FETCH_FAIL';

export const API_ERROR_SET = 'API_ERROR_SET';
export const API_ERROR_RESET = 'API_ERROR_RESET';

export const LOGOUT = 'LOGOUT';
