import {
  API_ERROR_RESET,
  API_ERROR_SET,
  CAMPAIGNS_FETCH_FAIL,
  CAMPAIGNS_FETCH_START,
  CAMPAIGNS_FETCH_SUCCESS,
  CAMPAIGNS_RESET,
  CODE_SUBMIT_FAIL,
  CODE_SUBMIT_RESET,
  CODE_SUBMIT_START,
  CODE_SUBMIT_SUCCESS,
  FIREBASE_REMOTE_CONFIG_FETCH_START,
  FIREBASE_REMOTE_CONFIG_FETCH_SUCCESS,
  LOGOUT,
  PERSONALITY_FETCH_FAIL,
  PERSONALITY_FETCH_START,
  PERSONALITY_FETCH_SUCCESS,
  PERSONALITY_RESET,
  PERSONALITY_RESET_AVATAR_FAIL,
  PERSONALITY_RESET_AVATAR_RESET,
  PERSONALITY_RESET_AVATAR_START,
  PERSONALITY_RESET_AVATAR_SUCCESS,
  SET_APP_VISIBILITY,
  SET_CONSENT_APPROVAL_VISIBILITY,
  SET_CONSENT_VISIBILITY,
  SET_PATHNAME,
  SET_PERSONALITY,
  SET_PROFILE_WARNING_VISIBILITY,
  SET_USER_ME,
  SET_VOTING_AVAILABILITY,
  SET_WHEEL_PRIZE,
  SET_WHEEL_VISIBILITY,
  USER_ME_FETCH_FAIL,
  USER_ME_FETCH_START,
  USER_ME_FETCH_SUCCESS,
  USER_ME_RESET,
} from "../constants/app";
import { AppActions, AppState } from "../types/app";

const initialState: AppState = {
  loadingFirebase: true,
  pathname: window.location.pathname,
  isAppVisible: false,
  isProfileWarningVisible: true,
  isSubscriptionModalVisible: false,
  isWheelModalVisible: false,
  wheelPrize: undefined,
  isConsentModalVisible: false,
  isConsentApprovalModalVisible: false,
  isVotingAvailable: false,
  code: {
    loading: false,
    data: false,
    error: undefined,
  },
  userMe: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  personality: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  resetAvatar: {
    loading: false,
    data: false,
    error: undefined,
  },
  campaigns: {
    loading: false,
    data: [],
    error: undefined,
  },
  apiError: undefined,
};

const reducer = (state = initialState, action: AppActions): AppState => {
  switch (action.type) {
    case FIREBASE_REMOTE_CONFIG_FETCH_START:
      return {
        ...state,
        loadingFirebase: true,
      };
    case FIREBASE_REMOTE_CONFIG_FETCH_SUCCESS:
      return {
        ...state,
        loadingFirebase: false,
      };
    case SET_PATHNAME:
      return {
        ...state,
        pathname: action.payload,
      };
    case SET_USER_ME:
      return {
        ...state,
        userMe: {
          ...state.userMe,
          data: action.payload,
        },
      };
    case SET_PERSONALITY:
      return {
        ...state,
        personality: {
          ...state.personality,
          data: action.payload,
        },
      };
    case SET_APP_VISIBILITY:
      return {
        ...state,
        isAppVisible: action.payload,
        apiError: undefined,
      };
    case SET_PROFILE_WARNING_VISIBILITY:
      return {
        ...state,
        isProfileWarningVisible: action.payload,
      };
    case SET_WHEEL_VISIBILITY:
      return {
        ...state,
        isWheelModalVisible: action.payload,
      };
    case SET_WHEEL_PRIZE:
      return {
        ...state,
        wheelPrize: action.payload,
      };
    case SET_CONSENT_VISIBILITY:
      return {
        ...state,
        isConsentModalVisible: action.payload,
      };
    case SET_CONSENT_APPROVAL_VISIBILITY:
      return {
        ...state,
        isConsentApprovalModalVisible: action.payload,
      };
    case SET_VOTING_AVAILABILITY:
      return {
        ...state,
        isVotingAvailable: action.payload,
      };
    case CODE_SUBMIT_START:
      return {
        ...state,
        code: {
          ...initialState.code,
          loading: true,
        },
      };
    case CODE_SUBMIT_SUCCESS:
      return {
        ...state,
        code: {
          ...state.code,
          loading: false,
          data: action.payload,
        },
      };
    case CODE_SUBMIT_FAIL:
      return {
        ...state,
        code: {
          ...state.code,
          loading: false,
          error: action.error,
        },
      };
    case CODE_SUBMIT_RESET:
      return {
        ...state,
        code: {
          ...initialState.code,
        },
      };
    case USER_ME_FETCH_START:
      return {
        ...state,
        userMe: {
          ...state.userMe,
          loading: true,
        },
      };
    case USER_ME_FETCH_SUCCESS:
      return {
        ...state,
        userMe: {
          ...state.userMe,
          loading: false,
          data: action.payload,
        },
      };
    case USER_ME_FETCH_FAIL:
      return {
        ...state,
        userMe: {
          ...state.userMe,
          loading: false,
          error: action.error,
        },
      };
    case USER_ME_RESET:
      return {
        ...state,
        userMe: {
          ...initialState.userMe,
        },
      };
    case PERSONALITY_FETCH_START:
      return {
        ...state,
        personality: {
          ...state.personality,
          loading: true,
        },
      };
    case PERSONALITY_FETCH_SUCCESS:
      return {
        ...state,
        personality: {
          ...state.personality,
          loading: false,
          data: action.payload,
        },
      };
    case PERSONALITY_FETCH_FAIL:
      return {
        ...state,
        personality: {
          ...state.personality,
          loading: false,
          error: action.error,
        },
      };
    case PERSONALITY_RESET:
      return {
        ...state,
        personality: {
          ...initialState.personality,
        },
      };
    case PERSONALITY_RESET_AVATAR_START:
      return {
        ...state,
        resetAvatar: {
          ...initialState.resetAvatar,
          loading: true,
        },
      };
    case PERSONALITY_RESET_AVATAR_SUCCESS:
      return {
        ...state,
        resetAvatar: {
          ...state.resetAvatar,
          loading: false,
          data: action.payload,
        },
      };
    case PERSONALITY_RESET_AVATAR_FAIL:
      return {
        ...state,
        resetAvatar: {
          ...state.resetAvatar,
          loading: false,
          error: action.error,
        },
      };
    case PERSONALITY_RESET_AVATAR_RESET:
      return {
        ...state,
        resetAvatar: {
          ...initialState.resetAvatar,
        },
      };
    case CAMPAIGNS_FETCH_START:
      return {
        ...state,
        campaigns: {
          ...initialState.campaigns,
          loading: true,
        },
      };
    case CAMPAIGNS_FETCH_SUCCESS:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: false,
          data: action.payload,
        },
      };
    case CAMPAIGNS_FETCH_FAIL:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: false,
          error: action.error,
        },
      };
    case CAMPAIGNS_RESET:
      return {
        ...state,
        campaigns: {
          ...initialState.campaigns,
        },
      };
    case API_ERROR_SET:
      return {
        ...state,
        apiError: action.payload,
      };
    case API_ERROR_RESET:
      return {
        ...state,
        apiError: undefined,
      };
    case LOGOUT:
      return {
        ...state,
        isAppVisible: false,
        userMe: {
          ...initialState.userMe,
        },
      };
    default:
      return state;
  }
};

export default reducer;
