import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import success from "../../../assets/images/contact-us-success.svg";
import AuthFooter from "../../../components/Auth/AuthFooter/AuthFooter";
import AuthHeaderBottom from "../../../components/Auth/AuthHeaderBottom/AuthHeaderBottom";
import ButtonPrimary from "../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import GenericModal, {ModalType} from "../../../components/Modals/GenericModal/GenericModal";
import {contactUsSubmit} from "../../../core/actions/contactUs";
import {Constants} from "../../../core/helpers/constants";
import {history} from "../../../core/helpers/history";
import {FormValuesContactUs} from "../../../core/models/custom/formValuesContactUs";
import {ErrorDto} from "../../../core/models/dtos/error.dto";
import {ContactUsRequest} from "../../../core/models/requests/contactUs.request";
import {IStore} from "../../../core/reducers";
import ContactUsForm from "./ContactUsForm/ContactUsForm";
import "./ContactUsPage.scss";

interface IProps {
  loadingSubmit: boolean;
  dataSubmit: boolean;
  errorSubmit?: ErrorDto;
  contactUsSubmit: (request: ContactUsRequest) => void;
}

interface IState {
  page: Page
  isErrorField: boolean;
  isErrorGeneric: boolean;
}

enum Page {
  form,
  success,
}

class ContactUsPage extends React.Component<IProps> {
  state: IState = {
    page: Page.form,
    isErrorField: false,
    isErrorGeneric: false,
  }

  private readonly errorField = 'LÜTFEN TÜM ALANLARI DOLDURUNUZ.';
  private readonly errorGeneric = 'BEKLENMEYEN BİR HATA OLUŞTU.';

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.loadingSubmit && !this.props.loadingSubmit) {
      if (this.props.dataSubmit) {
        this.setState({page: Page.success});
      } else {
        this.setState({errorGeneric: true});
      }
    }
  }

  private getError(): string | undefined {
    if (this.state.isErrorField) {
      return this.errorField;
    } else if (this.state.isErrorGeneric) {
      return this.errorGeneric;
    }
    return undefined;
  }

  private resetErrors(): void {
    this.setState({isErrorField: false, isErrorGeneric: false});
  }

  private async handleSubmit(values: FormValuesContactUs): Promise<void> {
    this.setState({isErrorField: false, isErrorGeneric: false});
    const request: ContactUsRequest = {
      ...values
    };
    await this.props.contactUsSubmit(request);
  }

  private renderContent(): ReactNode {
    if (this.state.page === Page.form) {
      return (
        <ContactUsForm
          isSubmitDisabled={this.props.loadingSubmit}
          callbackSubmit={values => this.handleSubmit(values)}
          callbackError={() => this.setState({isErrorField: true})}
        />
      );
    } else {
      return (
        <div className="success-wrapper">
          <img className="image" src={success} alt="success"/>
          <p className="text">
            Mesajın bize ulaştı, en yakın zamanda geri dönüş yapacağız.
          </p>
          <ButtonPrimary
            text="Devam Et"
            callback={() => history.goBack()}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div id="contact-us-page">
        <div className="container">
          <AuthHeaderBottom/>
          <div className="page-content">
            {this.renderContent()}
          </div>
        </div>
        <AuthFooter/>

        <GenericModal
          isVisible={!!this.getError()}
          type={ModalType.error}
          title={Constants.errorGenericTitle}
          text={this.getError() ?? ''}
          callbackOk={() => this.resetErrors()}
          callbackCancel={() => this.resetErrors()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      contactUsSubmit,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  const state = store.contactUs;
  return {
    loadingSubmit: state.submit.loading,
    dataSubmit: state.submit.data,
    errorSubmit: state.submit.error,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
