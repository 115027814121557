import {Spin} from "antd";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import campaignsUpcomingEmpty from "../../assets/images/campaigns-upcoming-empty.svg";
import CampaignCard from "../../components/CampaignCard/CampaignCard";
import {fetchCampaigns} from "../../core/actions/app";
import {CampaignDto} from "../../core/models/dtos/campaign.dto";
import {ErrorDto} from "../../core/models/dtos/error.dto";
import {UserMeDto} from "../../core/models/dtos/userMe.dto";
import {CampaignState} from "../../core/models/enums/campaignState";
import {IStore} from "../../core/reducers";
import FirebaseService from "../../core/services/firebase.service";
import "./CampaignsPage.scss";

interface IProps {
  userMe?: UserMeDto,
  loadingCampaigns: boolean,
  dataCampaigns: CampaignDto[],
  errorCampaigns?: ErrorDto,
  fetchCampaigns: () => void,
}

class CampaignsPage extends React.Component<IProps> {

  componentDidMount() {
    FirebaseService.fireEvent(FirebaseService.event_user_count_view_offer);
    this.props.fetchCampaigns();
  }

  private getCampaigns(state: CampaignState): CampaignDto[] {
    return this.props.dataCampaigns.filter(c => c.state === state);
  }

  private isAnyCampaign(state: CampaignState): boolean {
    return this.getCampaigns(state).length > 0;
  }

  private renderCampaigns(state: CampaignState): ReactNode {
    return (
      <div className="section">
        <div className="section-title">
          {state === CampaignState.ONGOING
            ? "Aktif Ayrıcalıklar"
            : "Gelecek Ayrıcalıklar"
          }
        </div>
        <div className="cards">
          <div className="row">
            {
              this.getCampaigns(state).map(campaign => {
                return (
                  <CampaignCard
                    key={`campaign-${campaign.id}`}
                    className="col-4 col-lg-2"
                    campaign={campaign}
                    userMe={this.props.userMe}
                  />
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }

  private renderEmpty(): ReactNode {
    return (
      <div className="section">
        <div className="section-title">
          Gelecek Ayrıcalıklar
        </div>
        <div className="cards d-flex justify-content-center">
          <img className="empty-image" src={campaignsUpcomingEmpty} alt="placeholder"/>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="campaigns-page" className="page">
        {
          this.props.loadingCampaigns
            ?
            <Spin size="large" className="d-flex justify-content-center mt-5"/>
            :
            <div className="page-content">
              <h1 className="page-title">Ayrıcalıklar</h1>
              {
                this.isAnyCampaign(CampaignState.ONGOING) &&
                this.renderCampaigns(CampaignState.ONGOING)
              }
              {
                this.isAnyCampaign(CampaignState.UPCOMING)
                  ? this.renderCampaigns(CampaignState.UPCOMING)
                  : this.renderEmpty()
              }
            </div>
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchCampaigns,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    userMe: store.app.userMe.data,
    loadingCampaigns: store.app.campaigns.loading,
    dataCampaigns: store.app.campaigns.data.sort((a, b) => a.id > b.id ? -1 : 1),
    errorCampaigns: store.app.campaigns.error,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsPage);
