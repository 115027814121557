import {Spin} from "antd";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {testFetchQuestions, testFinish, testUpdateQuestionAnswer} from "../../core/actions/test";
import {Utilities} from "../../core/helpers/utilities";
import {QuestionOption} from "../../core/models/custom/questionOption";
import {ErrorDto} from "../../core/models/dtos/error.dto";
import {PersonalityDto} from "../../core/models/dtos/personality.dto";
import {QuestionDto} from "../../core/models/dtos/question.dto";
import {QuizDto} from "../../core/models/dtos/quiz";
import {UserMeDto} from "../../core/models/dtos/userMe.dto";
import {IStore} from "../../core/reducers";
import Cover from "./Cover/Cover";
import Question from "./Question/Question";
import "./TestPage.scss";

interface IProps {
  userMe?: UserMeDto,
  personality?: PersonalityDto,
  loadingQuestions: boolean,
  dataQuestions?: QuizDto,
  errorQuestions?: ErrorDto,
  loadingUpdate: boolean,
  dataUpdate?: QuestionOption,
  errorUpdate?: ErrorDto,
  loadingFinish: boolean,
  dataFinish: boolean,
  errorFinish?: ErrorDto,
  testFetchQuestions: (isContinue: boolean) => void,
  testUpdateQuestionAnswer: (questionOption: QuestionOption) => void,
  testFinish: () => void,
}

interface IState {
  question?: QuestionDto;
  questionIdOptionIdMap: Map<number, number>;
  percent: number;
  submittedOptionId?: number;
}

class TestPage extends React.Component<IProps> {
  state: IState = {
    question: undefined,
    questionIdOptionIdMap: new Map(),
    percent: 0,
    submittedOptionId: undefined,
  }

  private readonly percentParam: number;

  constructor(props: IProps) {
    super(props);
    this.percentParam = this.getQueryParamPercent();
  }

  componentDidMount() {
    if (this.percentParam === 100 && this.props.personality) {
      this.setState({percent: this.percentParam});
    } else {
      const isContinue: boolean = this.props.userMe?.activeQuizId !== null;
      this.props.testFetchQuestions(isContinue);
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.loadingQuestions && !this.props.loadingQuestions) {
      if (this.props.dataQuestions) {
        this.initializeTest();
      }
    }

    if (prevProps.loadingUpdate && !this.props.loadingUpdate) {
      if (this.props.dataUpdate) {
        const questionIdOptionIdMap = this.state.questionIdOptionIdMap;
        questionIdOptionIdMap.set(this.props.dataUpdate.questionId, this.props.dataUpdate.optionId);
        this.setState({questionIdOptionIdMap, submittedOptionId: undefined});
        this.handleNext();
      }
    }

    if (prevProps.loadingFinish && !this.props.loadingFinish) {
      if (this.props.dataFinish) {
        this.setState({
          question: undefined,
          percent: 100,
        });
      }
    }
  }

  private getQueryParamPercent(): number {
    const percentString = Utilities.getQueryParam('percent');
    if (percentString && !isNaN(+percentString)) {
      const percent: number = +percentString;
      if (percent === 100) {
        return percent;
      }
    }
    return 0;
  }

  private initializeTest(forcedQuestion: boolean = false): void {
    const questionsDto = this.props.dataQuestions;
    if (!questionsDto) return;
    if (questionsDto.questions.length === 0) {
      alert('Empty question list!');
      return;
    }

    let userQuestionIndex = questionsDto.currentQuestion;

    if (!forcedQuestion) {
      this.setState({
        question: undefined,
        questionIdOptionIdMap: new Map(),
        percent: 0,
      });
    } else {
      const questionIdOptionIdMap = new Map<number, number>();
      questionsDto.answers?.forEach(answer => {
        questionIdOptionIdMap.set(answer.questionId, answer.optionId);
      });

      if (userQuestionIndex === questionsDto.questions.length) {
        // In case of out of bound open last question
        userQuestionIndex -= 1;
      }

      this.setState({
        questionIdOptionIdMap,
        question: questionsDto.questions[userQuestionIndex],
        percent: this.getPercentFromIndex(userQuestionIndex),
      });
    }
  }

  private getPercentFromIndex(index: number): number {
    if (!this.props.dataQuestions) return 0;
    return Number.parseFloat((100 * index / this.props.dataQuestions.questions.length).toFixed(1));
  }

  private getIndexOfQuestionId(questionId: number): number | null {
    if (!this.props.dataQuestions) return null;
    const index = this.props.dataQuestions.questions.findIndex(q => q.id === questionId);
    if (index === -1) {
      alert(`Wrong state (Question with id ${questionId} not found!)`);
      return null;
    }
    return index;
  }

  private setQuestionByIndex(index: number) {
    if (!this.props.dataQuestions) return;
    this.setState({
      question: this.props.dataQuestions.questions[index],
      percent: this.getPercentFromIndex(index),
    });
  }

  private isPrevAvailable(): boolean {
    return this.state.percent !== 0;
  }

  private isNextAvailable(): boolean {
    if (!this.props.dataQuestions) return false;
    if (!this.state.question) return false;

    const currentIndex = this.getIndexOfQuestionId(this.state.question.id);
    if (currentIndex === null || currentIndex === this.props.dataQuestions.questions.length - 1) return false;

    return !!this.state.questionIdOptionIdMap.get(this.state.question.id);
  }

  private handleStart(): void {
    this.initializeTest(true);
  }

  private handleSelectAnswer(questionOption: QuestionOption): void {
    if (this.props.loadingUpdate) return;
    this.setState({submittedOptionId: questionOption.optionId});
    this.props.testUpdateQuestionAnswer(questionOption);
  }

  private handlePrev(): void {
    if (this.props.loadingUpdate) return;
    if (!this.state.question) return;
    const currentIndex = this.getIndexOfQuestionId(this.state.question.id);
    if (currentIndex === null || currentIndex === 0) return;

    this.setQuestionByIndex(currentIndex - 1);
  }

  private handleNext(): void {
    if (this.props.loadingUpdate) return;
    if (!this.props.dataQuestions) return;
    if (!this.state.question) return;
    const currentIndex = this.getIndexOfQuestionId(this.state.question.id);
    if (currentIndex === null) return;

    if (currentIndex === this.props.dataQuestions.questions.length - 1) {
      this.props.testFinish();
    } else {
      this.setQuestionByIndex(currentIndex + 1);
    }
  }

  private handleAgain(): void {
    this.props.testFetchQuestions(false);
  }

  private renderContent(): ReactNode {
    if (this.props.loadingQuestions) {
      return <Spin size="large"/>
    } else if (this.props.dataQuestions) {
      return (
        this.state.question
          ?
          <Question
            currentIndex={this.getIndexOfQuestionId(this.state.question.id) ?? 0}
            question={this.state.question}
            selectedOptionId={this.state.questionIdOptionIdMap.get(this.state.question.id)}
            percent={this.state.percent}
            submittedOptionId={this.state.submittedOptionId}
            callbackSelectAnswer={(questionOption) => this.handleSelectAnswer(questionOption)}
            callbackPrev={this.isPrevAvailable() ? () => this.handlePrev() : undefined}
            callbackNext={this.isNextAvailable() ? () => this.handleNext() : undefined}
          />
          :
          <Cover
            personality={this.props.personality}
            isCoverStart={this.state.percent === 0}
            isContinue={this.props.dataQuestions.currentQuestion !== 0}
            callbackStart={() => this.handleStart()}
            callbackAgain={() => this.handleAgain()}
          />
      );
    } else if (this.state.percent === 100 && this.props.personality) {
      return (
        <Cover
          personality={this.props.personality}
          isCoverStart={false}
          isContinue={false}
          callbackStart={() => this.handleStart()}
          callbackAgain={() => this.handleAgain()}
        />
      );
    }
    return <React.Fragment/>;
  }

  render() {
    return (
      <div id="test-page" className="page">
        <div className={`page-content ${!this.state.question ? 'cover' : ''}`}>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      testFetchQuestions,
      testUpdateQuestionAnswer,
      testFinish,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  const state = store.test;
  return {
    userMe: store.app.userMe.data,
    personality: store.app.personality.data,
    loadingQuestions: state.questions.loading,
    dataQuestions: state.questions.data,
    errorQuestions: state.questions.error,
    loadingUpdate: state.update.loading,
    dataUpdate: state.update.data,
    errorUpdate: state.update.error,
    loadingFinish: state.finish.loading,
    dataFinish: state.finish.data,
    errorFinish: state.finish.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);
