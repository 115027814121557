import {
  PROFILE_CANCEL_SUBSCRIPTION_FAIL,
  PROFILE_CANCEL_SUBSCRIPTION_RESET,
  PROFILE_CANCEL_SUBSCRIPTION_START,
  PROFILE_CANCEL_SUBSCRIPTION_SUCCESS,
  PROFILE_SEND_EMAIL_CONFIRM_FAIL,
  PROFILE_SEND_EMAIL_CONFIRM_RESET,
  PROFILE_SEND_EMAIL_CONFIRM_START,
  PROFILE_SEND_EMAIL_CONFIRM_SUCCESS,
  PROFILE_USER_ME_FETCH_FAIL,
  PROFILE_USER_ME_FETCH_START,
  PROFILE_USER_ME_FETCH_SUCCESS,
  PROFILE_USER_ME_RESET,
  PROFILE_USER_ME_SUBMIT_FAIL,
  PROFILE_USER_ME_SUBMIT_RESET,
  PROFILE_USER_ME_SUBMIT_START,
  PROFILE_USER_ME_SUBMIT_SUCCESS
} from "../constants/profile";
import {ProfileActions, ProfileState} from "../types/profile";

const initialState: ProfileState = {
  userMe: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  resendConfirmationEmail: {
    loading: false,
    data: false,
    error: undefined,
  },
  submit: {
    loading: false,
    data: false,
    error: undefined,
  },
  cancelSubscription: {
    loading: false,
    data: false,
    error: undefined,
  }
}

const reducer = (
  state = initialState,
  action: ProfileActions,
): ProfileState => {
  switch (action.type) {
    case PROFILE_USER_ME_FETCH_START:
      return {
        ...state,
        userMe: {
          ...state.userMe,
          loading: true,
        }
      };
    case PROFILE_USER_ME_FETCH_SUCCESS:
      return {
        ...state,
        userMe: {
          ...state.userMe,
          loading: false,
          data: action.payload
        }
      };
    case PROFILE_USER_ME_FETCH_FAIL:
      return {
        ...state,
        userMe: {
          ...state.userMe,
          loading: false,
          error: action.error,
        }
      };
    case PROFILE_USER_ME_RESET:
      return {
        ...state,
        userMe: {
          ...initialState.userMe,
        }
      };
    case PROFILE_SEND_EMAIL_CONFIRM_START:
      return {
        ...state,
        resendConfirmationEmail: {
          ...initialState.resendConfirmationEmail,
          loading: true,
        }
      }
    case PROFILE_SEND_EMAIL_CONFIRM_SUCCESS:
      return {
        ...state,
        resendConfirmationEmail: {
          ...state.resendConfirmationEmail,
          loading: false,
          data: action.payload,
        }
      }
    case PROFILE_SEND_EMAIL_CONFIRM_FAIL:
      return {
        ...state,
        resendConfirmationEmail: {
          ...state.resendConfirmationEmail,
          loading: false,
          error: action.error,
        }
      }
    case PROFILE_SEND_EMAIL_CONFIRM_RESET:
      return {
        ...state,
        resendConfirmationEmail: {
          ...initialState.resendConfirmationEmail,
        }
      }
    case PROFILE_USER_ME_SUBMIT_START:
      return {
        ...state,
        submit: {
          ...initialState.submit,
          loading: true,
        }
      }
    case PROFILE_USER_ME_SUBMIT_SUCCESS:
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          data: action.payload,
        }
      }
    case PROFILE_USER_ME_SUBMIT_FAIL:
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          error: action.error,
        }
      }
    case PROFILE_USER_ME_SUBMIT_RESET:
      return {
        ...state,
        submit: {
          ...initialState.submit,
        }
      }
    case PROFILE_CANCEL_SUBSCRIPTION_START:
      return {
        ...state,
        cancelSubscription: {
          ...initialState.cancelSubscription,
          loading: true,
        }
      }
    case PROFILE_CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          loading: false,
          data: action.payload,
        }
      }
    case PROFILE_CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          loading: false,
          error: action.error,
        }
      }
    case PROFILE_CANCEL_SUBSCRIPTION_RESET:
      return {
        ...state,
        cancelSubscription: {
          ...initialState.cancelSubscription,
        }
      }
    default:
      return state;
  }
}

export default reducer;
