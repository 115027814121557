import {Spin} from "antd";
import moment from "moment";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import ConsentApprovalModal from "../../components/Modals/Consent/ConsentApprovalModal/ConsentApprovalModal";
import ConsentModal from "../../components/Modals/Consent/ConsentModal/ConsentModal";
import GenericModal, {ModalType} from "../../components/Modals/GenericModal/GenericModal";
import UserFanCard from "../../components/UserFanCard/UserFanCard";
import {setConsentApprovalModalVisibility, setConsentModalVisibility} from "../../core/actions/app";
import {
  profileCancelSubscription,
  profileCancelSubscriptionReset,
  profileFetchUserMe,
  profileResetUserMe,
  profileSendEmailConfirm,
  profileSendEmailConfirmReset,
  profileSubmitUserMe,
  profileSubmitUserMeReset,
} from "../../core/actions/profile";
import {ConsentValues} from "../../core/models/custom/consentValues";
import {IdType} from "../../core/models/custom/enums/idType";
import {FormValuesProfile} from "../../core/models/custom/formValuesProfile";
import {ErrorDto} from "../../core/models/dtos/error.dto";
import {UserMeDto} from "../../core/models/dtos/userMe.dto";
import {SubscriptionType} from "../../core/models/enums/subscriptionType";
import {UserMeUpdateRequest} from "../../core/models/requests/userMeUpdate.request";
import {IStore} from "../../core/reducers";
import FirebaseService from "../../core/services/firebase.service";
import ProfileForm from "./ProfileForm/ProfileForm";
import "./ProfilePage.scss";

interface IProps {
  subscriptionType?: SubscriptionType;
  loadingUserMe: boolean;
  dataUserMe?: UserMeDto;
  errorUserMe?: ErrorDto;
  loadingResendConfirmationEmail: boolean;
  dataResendConfirmationEmail: boolean;
  errorResendConfirmationEmail?: ErrorDto;
  loadingSubmit: boolean;
  dataSubmit: boolean;
  errorSubmit?: ErrorDto;
  loadingCancelSubscription: boolean;
  dataCancelSubscription: boolean;
  errorCancelSubscription?: ErrorDto;
  isConsentModalVisible: boolean;
  isConsentApprovalModalVisible: boolean;
  profileFetchUserMe: () => void;
  profileResetUserMe: () => void;
  profileSendEmailConfirm: () => void;
  profileSendEmailConfirmReset: () => void;
  profileSubmitUserMe: (request: UserMeUpdateRequest) => void;
  profileSubmitUserMeReset: () => void;
  profileCancelSubscription: () => void;
  profileCancelSubscriptionReset: () => void;
  setConsentModalVisibility: (isVisible: boolean) => void;
  setConsentApprovalModalVisibility: (isVisible: boolean) => void;
}

interface IState {
  isModalCancelSubscriptionVisible: boolean,
  consentValues?: ConsentValues,
}

class ProfilePage extends React.Component<IProps> {
  state: IState = {
    isModalCancelSubscriptionVisible: false,
    consentValues: undefined,
  }

  componentDidMount() {
    FirebaseService.fireEvent(FirebaseService.event_user_count_view_profile);
    this.props.profileFetchUserMe();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.loadingSubmit && !this.props.loadingSubmit) {
      if (this.props.dataSubmit) {
        this.setModalConsentVisibility(false);
        this.setModalConsentApprovalVisibility(false);
      }
    }
  }

  private setModalCancelSubscriptionVisibility(isVisible: boolean): void {
    this.setState({isModalCancelSubscriptionVisible: isVisible});
  }

  private setModalConsentVisibility(isVisible: boolean): void {
    this.props.setConsentModalVisibility(isVisible);
  }

  private setModalConsentApprovalVisibility(isVisible: boolean): void {
    this.props.setConsentApprovalModalVisibility(isVisible);
  }

  private handleConsentModalSave(values: ConsentValues): void {
    this.setState({consentValues: values});
    const me = this.props.dataUserMe;
    if (me) {
      if (
        (!!me.privacyConsent && !values.privacyConsent) ||
        (!!me.marketingConsent && !values.marketingConsent)
      ) {
        this.setModalConsentVisibility(false);
        this.setModalConsentApprovalVisibility(true);
      } else {
        this.props.profileSubmitUserMe({...values});
      }
    }
  }

  private handleConsentApprovalModalClose(isApproved: boolean): void {
    if (isApproved) {
      this.props.profileSubmitUserMe({...this.state.consentValues});
    } else {
      this.setModalConsentApprovalVisibility(false);
    }
  }

  private getProfileFormInitialValues(): FormValuesProfile {
    return {
      ...this.props.dataUserMe,
      birthday: this.props.dataUserMe?.birthday ? moment(this.props.dataUserMe?.birthday) : undefined,
      idType: this.props.dataUserMe?.passportNumber ? IdType.passport : IdType.tckn,
    }
  }

  private getInitialIdType(): IdType {
    const isTckn = this.props.dataUserMe?.tckn;
    const isPassport = this.props.dataUserMe?.passportNumber;
    // tckn has privilege
    return isTckn ? IdType.tckn : isPassport ? IdType.passport : IdType.tckn;
  }

  private handleResendConfirmationMail(): void {
    this.props.profileSendEmailConfirm();
  }

  private handleSubmit(values: FormValuesProfile): void {
    FirebaseService.fireEvent(FirebaseService.event_menu_user_count_profile_change);
    let request: UserMeUpdateRequest = {};
    if (values.firstName) {
      request.firstName = values.firstName;
    }
    if (values.lastName) {
      request.lastName = values.lastName;
    }
    if (values.birthday) {
      request.birthday = moment(values.birthday).format("YYYY-MM-DD");
    }
    if (values.province) {
      request.province = values.province;
    }
    if (values.idType === IdType.tckn) {
      if (values.tckn) {
        request.tckn = values.tckn;
      }
    } else {
      if (values.passportNumber) {
        request.passportNumber = values.passportNumber;
      }
    }
    if (values.email !== this.props.dataUserMe?.email) {
      request.email = values.email;
    }
    this.props.profileSubmitUserMe(request);
  }

  private handleModalCancelSubscription(isAccepted: boolean): void {
    this.setModalCancelSubscriptionVisibility(false);
    if (isAccepted) {
      this.props.profileCancelSubscription();
    }
  }

  private renderContent(): ReactNode {
    if (this.props.loadingUserMe) {
      return <Spin size="large" className="my-5"/>
    } else if (this.props.dataUserMe) {
      return (
        <React.Fragment>
          <UserFanCard
            userMe={this.props.dataUserMe}
          />
          <ProfileForm
            userMe={this.props.dataUserMe}
            subscriptionType={this.props.subscriptionType}
            initialValues={this.getProfileFormInitialValues()}
            initialIdType={this.getInitialIdType()}
            isResendConfirmationMailDisabled={this.props.loadingResendConfirmationEmail}
            isSubmitDisabled={this.props.loadingSubmit}
            isCancelSubscriptionDisabled={this.props.loadingCancelSubscription}
            callbackResendConfirmationMail={() => this.handleResendConfirmationMail()}
            callbackSubmit={values => this.handleSubmit(values)}
            callbackCancelSubscription={() => this.setModalCancelSubscriptionVisibility(true)}
            callbackConsents={() => this.setModalConsentVisibility(true)}
          />
        </React.Fragment>
      );
    }
    return <React.Fragment/>;
  }

  render() {
    return (
      <div id="profile-page" className="page">
        <div className="page-content">
          {this.renderContent()}
        </div>

        {
          this.props.isConsentModalVisible &&
          <ConsentModal
            userMe={this.props.dataUserMe}
            isLoading={this.props.loadingSubmit}
            callbackSave={values => this.handleConsentModalSave(values)}
          />
        }

        {
          this.props.isConsentApprovalModalVisible &&
          <ConsentApprovalModal
            isLoading={this.props.loadingSubmit}
            callback={isApproved => this.handleConsentApprovalModalClose(isApproved)}
          />
        }

        <GenericModal
          isVisible={this.props.dataResendConfirmationEmail}
          type={ModalType.success}
          title=""
          text="Konfirmasyon linki mail adresinize gönderilmiştir."
          callbackOk={() => this.props.profileSendEmailConfirmReset()}
          callbackCancel={() => this.props.profileSendEmailConfirmReset()}
        />

        <GenericModal
          isVisible={this.props.dataSubmit}
          type={ModalType.success}
          title=""
          text={FirebaseService.getValue(FirebaseService.profile_update_success_message)}
          callbackOk={() => this.props.profileSubmitUserMeReset()}
          callbackCancel={() => this.props.profileSubmitUserMeReset()}
        />

        <GenericModal
          isVisible={this.state.isModalCancelSubscriptionVisible}
          type={ModalType.error}
          title=""
          text="Aboneliğini iptal etmek istediğine emin misin?"
          button="Evet"
          buttonSecondary="Hayır"
          callbackOk={() => this.handleModalCancelSubscription(true)}
          callbackCancel={() => this.handleModalCancelSubscription(false)}
        />

        <GenericModal
          isVisible={this.props.dataCancelSubscription}
          type={ModalType.success}
          title=""
          text="Aboneliğiniz iptal edilmiştir."
          callbackOk={() => this.props.profileCancelSubscriptionReset()}
          callbackCancel={() => this.props.profileCancelSubscriptionReset()}
        />
      </div>
    );
  }

  componentWillUnmount() {
    this.props.profileResetUserMe();
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      profileFetchUserMe,
      profileResetUserMe,
      profileSendEmailConfirm,
      profileSendEmailConfirmReset,
      profileSubmitUserMe,
      profileSubmitUserMeReset,
      profileCancelSubscription,
      profileCancelSubscriptionReset,
      setConsentModalVisibility,
      setConsentApprovalModalVisibility,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  const state = store.profile;
  return {
    subscriptionType: store.app.userMe.data?.subscriptionType,
    loadingUserMe: state.userMe.loading,
    dataUserMe: state.userMe.data,
    errorUserMe: state.userMe.error,
    loadingResendConfirmationEmail: state.resendConfirmationEmail.loading,
    dataResendConfirmationEmail: state.resendConfirmationEmail.data,
    errorResendConfirmationEmail: state.resendConfirmationEmail.error,
    loadingSubmit: state.submit.loading,
    dataSubmit: state.submit.data,
    errorSubmit: state.submit.error,
    loadingCancelSubscription: state.cancelSubscription.loading,
    dataCancelSubscription: state.cancelSubscription.data,
    errorCancelSubscription: state.cancelSubscription.error,
    isConsentModalVisible: store.app.isConsentModalVisible,
    isConsentApprovalModalVisible: store.app.isConsentApprovalModalVisible,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
