import {Button, Checkbox, Modal, Spin} from "antd";
import parse from "html-react-parser";
import React, {ReactNode, useState} from "react";
import {Constants} from "../../../../core/helpers/constants";
import {ConsentValues} from "../../../../core/models/custom/consentValues";
import {UserMeDto} from "../../../../core/models/dtos/userMe.dto";
import FirebaseService from "../../../../core/services/firebase.service";
import "./ConsentModal.scss";

interface IProps {
  userMe?: UserMeDto,
  isLoading: boolean;
  callbackSave: (values: ConsentValues) => void,
}

const ConsentModal = (props: IProps) => {
  const [privacyConsent, setPrivacyConsent] = useState<boolean>(!!props.userMe?.privacyConsent);
  const [marketingConsent, setMarketingConsent] = useState<boolean>(!!props.userMe?.marketingConsent);

  const handleOk = (): void => {
    props.callbackSave({privacyConsent, marketingConsent});
  }

  const renderCheckboxPrivacy = (): ReactNode => {
    return (
      <div className="checkbox-wrapper">
        <Checkbox
          checked={marketingConsent}
          onChange={e => setMarketingConsent(e.target.checked)}
        />
        <span className="text">
          Tüm kampanya ve fırsatlardan haberdar olabilmek için&nbsp;
          <a
            className="link"
            href="https://s3-eu-west-1.amazonaws.com/static.4p/Taraftar_Kart_Ticari_Elektronik_I%CC%87leti_Onayi.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Ticari Elektronik İleti Onayı'nı
          </a>
          &nbsp;veriyorum.
        </span>
      </div>
    );
  }

  const renderCheckboxMarketing = (): ReactNode => {
    return (
      <div className="checkbox-wrapper">
        <Checkbox
          checked={privacyConsent}
          onChange={e => setPrivacyConsent(e.target.checked)}
        />
        <span className="text">
          <a
            className="link"
            href="https://s3-eu-west-1.amazonaws.com/static.4p/Taraftar_Kart_Yurtdisi_Acik_Riza_Metni.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Açık Rıza Metni’ni
          </a>
          &nbsp;onaylıyorum.
        </span>
      </div>
    );
  }

  const renderNote = (): ReactNode => {
    return (
      <div className="checkbox-wrapper">
      <span className="text">
        Taraftar Kart üyesi olarak&nbsp;
        <a
          className="link"
          href="https://s3.eu-west-1.amazonaws.com/static.4p/fener/Taraftar_Kart_Haftalik_Abonelik_Sozlesmesi.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Haftalık Abonelik Sözleşmesi'ni
        </a>
        &nbsp;ya da&nbsp;
        <a
          className="link"
          href="https://s3.eu-west-1.amazonaws.com/static.4p/fener/Taraftar_Kart_Aylik_Abonelik_Sozlesmesi.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Aylık/Yıllık Abonelik Sözleşmesi'ni
        </a>
        &nbsp;ve&nbsp;
        <a
          className="link"
          href="https://s3-eu-west-1.amazonaws.com/static.4p/Taraftar_Kart_Aydinlatma_Metni.pdf"
          target="_blank"
          rel="noreferrer"
        >
          KVKK Aydınlatma Metni'ni
        </a>
        &nbsp;onaylamış olursun.
      </span>
      </div>
    );
  }

  return (
    <Modal
      className="consent-modal"
      maskStyle={{backdropFilter: "blur(8px)"}}
      visible
      centered
      closable={false}
      footer={false}
      destroyOnClose
    >
      <div className="body">
        <div className="header">
          <img
            className="logo"
            src={Constants.fenerLogo}
            alt="logo"
          />
        </div>

        <p className="message">
          {parse(FirebaseService.getValue(FirebaseService.profile_consent_desc))}
        </p>

        {renderCheckboxPrivacy()}
        {renderCheckboxMarketing()}
        {renderNote()}

        <Button
          className="button"
          disabled={props.isLoading}
          onClick={handleOk}
        >
          {props.isLoading ? <Spin/> : parse(FirebaseService.getValue(FirebaseService.profile_consent_button))}
        </Button>
      </div>
    </Modal>
  );
}

export default ConsentModal;
