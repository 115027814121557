import React, {ReactNode} from "react";
import socialFacebook from "../../assets/images/social-facebook.svg";
import socialInstagram from "../../assets/images/social-instagram.svg";
import socialTiktok from "../../assets/images/social-tiktok.svg";
import socialTwitter from "../../assets/images/social-twitter.svg";
import socialYoutube from "../../assets/images/social-youtube.svg";
import {Constants} from "../../core/helpers/constants";
import "./AppFooter.scss";

const AppFooter = () => {

  const renderLink = (href: string, src: any): ReactNode => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <img className="icon" src={src} alt="social"/>
      </a>
    );
  }

  const renderBranding = (): ReactNode => {
    return (
      <div className="branding">
        <span className="year">1907 - 2021</span>
        <span className="brand">FENERBAHÇE SK</span>
      </div>
    );
  }
  const renderIcons = (): ReactNode => {
    return (
      <div className="icon-wrapper">
        {renderLink("https://www.facebook.com/FenerbahceKart", socialFacebook)}
        {renderLink("https://twitter.com/Fenerbahce", socialTwitter)}
        {renderLink("https://www.instagram.com/fbkart", socialInstagram)}
        {renderLink("https://www.youtube.com/channel/UCgqlho3-8a6FmDqQm7Q6gJw", socialYoutube)}
        {renderLink(" https://www.tiktok.com/@fenerbahce?lang=tr-TR", socialTiktok)}
      </div>
    );
  }

  return (
    <React.Fragment>

      <div
        id="app-footer"
        className="d-flex d-lg-none mobile"
      >
        <img
          className="logo"
          src={Constants.fenerLogo}
          alt="logo"
        />
        <div className="wrapper upper">
          {renderIcons()}
        </div>
        <div className="wrapper lower">
          {renderBranding()}
        </div>
      </div>

      <div
        id="app-footer"
        className="d-none d-lg-flex desktop"
      >
        <img
          className="logo"
          src={Constants.fenerLogo}
          alt="logo"
        />
        <div className="wrapper">
          {renderBranding()}
          {renderIcons()}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AppFooter;
