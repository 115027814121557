import {Button, Input, Popover} from "antd";
import parse from "html-react-parser";
import React, {ReactNode, RefObject} from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import copyIcon from "../../assets/images/invite-friends-copy-icon.svg";
import {Constants} from "../../core/helpers/constants";
import FirebaseService from "../../core/services/firebase.service";
import "./InviteFriendsPage.scss";

class InviteFriendsPage extends React.Component {

  private codeRef: RefObject<Input> = React.createRef();
  private isShareAvailable = navigator.share !== undefined;

  componentDidMount() {
    FirebaseService.fireEvent(FirebaseService.event_user_count_view_invite_us);
  }

  private shareButtonFacebook(): ReactNode {
    return (
      <FacebookShareButton
        className="share-button"
        url={Constants.shareUrl}
        quote={Constants.shareTitle}
        onClick={() => FirebaseService.fireEvent(FirebaseService.event_quiz_count_share_facebook_button_clicked)}
      >
        <FacebookIcon size={32} round/>
      </FacebookShareButton>
    );
  }

  private shareButtonTwitter(): ReactNode {
    return (
      <TwitterShareButton
        className="share-button"
        url={Constants.shareUrl}
        title={Constants.shareTitle}
        onClick={() => FirebaseService.fireEvent(FirebaseService.event_quiz_count_share_twitter_button_clicked)}
      >
        <TwitterIcon size={32} round/>
      </TwitterShareButton>
    );
  }

  private shareButtonWhatsapp(): ReactNode {
    return (
      <WhatsappShareButton
        className="share-button"
        url={Constants.shareUrl}
        title={Constants.shareTitle}>
        <WhatsappIcon size={32} round/>
      </WhatsappShareButton>
    );
  }

  private shareButtonEmail(): ReactNode {
    return (
      <EmailShareButton
        className="share-button"
        url={Constants.shareUrl}
        subject={Constants.shareTitle}>
        <EmailIcon size={32} round/>
      </EmailShareButton>
    );
  }

  private shareContent(): ReactNode {
    return (
      <div className="share-content">
        {this.shareButtonFacebook()}
        {this.shareButtonTwitter()}
        {this.shareButtonWhatsapp()}
        {this.shareButtonEmail()}
      </div>
    );
  }

  private handleCopyLink(): void {
    if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      const textarea = document.createElement("textarea");
      textarea.textContent = this.codeRef.current?.state.value ?? '';
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
      } catch (ex) {
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  private handleButtonClick(): void {
    FirebaseService.fireEvent(FirebaseService.event_menu_user_count_invite_friends);
    if (this.isShareAvailable) {
      navigator.share({
        title: Constants.shareTitle,
        url: Constants.shareUrl
      }).then(() => {
      });
    }
  }

  private renderButton(): ReactNode {
    return (
      <Button
        className="btn-copy-link"
        onClick={() => this.handleButtonClick()}
      >
        Paylaş
      </Button>
    );
  }

  render() {
    return (
      <div id="invite-friends-page" className="page">
        <div className="page-content">
          <div className="section section-invite-friends-title">
            <div className="heading">
              <div className="info">
                <span className="title">
                  {parse(FirebaseService.getValue(FirebaseService.invite_friends_header))}
                </span>
                <span className="text">
                  {parse(FirebaseService.getValue(FirebaseService.invite_friends_description))}
                </span>
              </div>
            </div>
          </div>

          <div className="section section-invite-friends-content">
            <div className="body">
              <Input
                ref={this.codeRef}
                className="input-copy-link"
                value={Constants.shareUrl}
                suffix={
                  <img
                    src={copyIcon}
                    alt="copy"
                    style={{cursor: "pointer"}}
                    onClick={() => this.handleCopyLink()}
                  />
                }
              />
              {
                this.isShareAvailable
                  ?
                  this.renderButton()
                  :
                  <Popover
                    className="share-popover"
                    placement="bottom"
                    content={this.shareContent()}
                    trigger="click">
                    {this.renderButton()}
                  </Popover>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InviteFriendsPage;
