import { Button } from "antd";
import React, { ReactNode } from "react";
import "./ButtonPrimary.scss";
import right from "../../../assets/images/chevron-right.svg";

interface IProps {
  text: ReactNode;
  block?: boolean;
  class?: string;
  arrowSuffix?: boolean;
  isSubmit?: boolean;
  disabled?: boolean;
  callback?: Function;
}

const ButtonPrimary = (props: IProps) => {
  const getButtonContentWithArrow = (): ReactNode => {
    return (
      <div className="d-flex">
        <span>{props.text}</span>
        <img className="arrow" src={right} alt="arrow" />
      </div>
    );
  };

  return (
    <Button
      id="button-primary"
      type="primary"
      block={props.block}
      className={props.class ?? ""}
      htmlType={props.isSubmit ? "submit" : "button"}
      disabled={props.disabled}
      onClick={() => (props.callback ? props.callback() : {})}
    >
      {props.arrowSuffix ? getButtonContentWithArrow() : props.text}
    </Button>
  );
};

export default ButtonPrimary;
