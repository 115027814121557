import { Checkbox, Form, Radio } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import ButtonPrimary from "../../../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import { Constants } from "../../../../../core/helpers/constants";
import { Utilities } from "../../../../../core/helpers/utilities";
import { AuthType } from "../../../../../core/models/custom/enums/authType";
import { FormValuesLoginMsisdn } from "../../../../../core/models/custom/formValuesLoginMsisdn";
import { ErrorDto } from "../../../../../core/models/dtos/error.dto";
import { IStore } from "../../../../../core/reducers";
import LoginMsisdnPage from "../LoginMsisdnPage";
import ModalContract from "../ModalContract/ModalContract";
import "./LoginMsisdnForm.scss";

interface IProps {
  authType: AuthType;
  isSubmitDisabled: boolean;
  callbackChangeAuthType: (authType: AuthType) => void;
  callbackChangePhone: (phone: string) => void;
  callbackSubmit: (values: FormValuesLoginMsisdn, authType: AuthType) => void;
  callbackError: () => void;
  callbackErrorContract: () => void;
  errorSubmit?: ErrorDto;
}

const LoginMsisdnForm = (props: IProps) => {
  const [form] = Form.useForm();
  const [phone, setPhone] = useState<string>("90");
  const [isPhoneError, setPhoneError] = useState<boolean>(false);
  const [isContractVisible, setContractVisibility] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isCaptchaError, setCaptchaError] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({ authType: props.authType });
  }, [form, props.authType]);

  const options = [
    { label: LoginMsisdnPage.authTextLogin, value: AuthType.login },
    { label: LoginMsisdnPage.authTextSignUp, value: AuthType.signup },
  ];

  useEffect(() => {
    if (props.errorSubmit?.status === 418) {
      props.callbackChangeAuthType(AuthType.signup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errorSubmit]);

  const handleRecaptchaChange = (token: string | null) => {
    if (token) {
      setCaptchaError(false);
      setRecaptchaToken(token);
    }
  };

  const isPhoneValid = (msisdn: string): boolean => {
    if (msisdn) {
      if (msisdn.length > 2) {
        if (Utilities.isMsisdnTurkish(msisdn)) {
          if (msisdn.length !== 12) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  };

  const handleModalContract = (isApproved: boolean) => {
    if (isApproved) {
      form.setFieldsValue({
        agreement: true,
      });
    }
    setContractVisibility(false);
  };

  const handleFinish = (values: FormValuesLoginMsisdn) => {
    setPhoneError(false);
    setCaptchaError(false);
    if (!isPhoneValid(values.msisdn)) {
      setPhoneError(true);
      props.callbackError();
      return;
    }
    if (!recaptchaToken) {
      setCaptchaError(true);
      return;
    }
    values.captcha = recaptchaToken;
    props.callbackSubmit(values, props.authType);
  };

  const handleFinishFailed = (errorInfo: any) => {
    const values: FormValuesLoginMsisdn = errorInfo.values;
    if (props.authType === AuthType.signup && isPhoneValid(values.msisdn)) {
      props.callbackErrorContract();
    } else {
      props.callbackError();
    }
  };

  const renderAuthType = (): ReactNode => {
    return (
      <Form.Item name="authType">
        <Radio.Group
          options={options}
          optionType="button"
          buttonStyle="solid"
          onChange={(e) => {
            props.callbackChangeAuthType(e.target.value);
          }}
        />
      </Form.Item>
    );
  };

  const renderMsisdn = (): ReactNode => {
    return (
      <React.Fragment>
        <p className="msisdn-note">
          Telefon numaran ile&nbsp;
          {props.authType === AuthType.login ? "giriş yap" : "abone ol"}
        </p>
        <Form.Item name="msisdn" rules={[{ required: true, message: "" }]}>
          <PhoneInput
            inputClass={`form-input phone ${
              isPhoneError ? "error-border" : ""
            }`}
            country="tr"
            value={phone}
            placeholder=""
            countryCodeEditable={false}
            onChange={(phone) => {
              props.callbackChangePhone(phone);
              setPhone(phone);
            }}
          />
        </Form.Item>
      </React.Fragment>
    );
  };

  const renderConsents = (): ReactNode => {
    return (
      <React.Fragment>
        <div className="checkbox-wrapper mt-4">
          <Form.Item
            className="mr-2 mb-0"
            name="marketingConsent"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <label className="checkbox-text marketingConsent">
            Tüm kampanya ve fırsatlardan haberdar olabilmek için&nbsp;
            <a
              className="link"
              href="https://s3-eu-west-1.amazonaws.com/static.4p/Taraftar_Kart_Ticari_Elektronik_I%CC%87leti_Onayi.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Ticari Elektronik İleti Onayı'nı
            </a>
            &nbsp;veriyorum.
          </label>
        </div>

        <div className="checkbox-wrapper privacy-consent">
          <Form.Item
            className="mr-2 mb-0"
            name="privacyConsent"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <label className="checkbox-text">
            <a
              className="link"
              href="https://s3-eu-west-1.amazonaws.com/static.4p/Taraftar_Kart_Yurtdisi_Acik_Riza_Metni.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Açık Rıza Metni’ni
            </a>
            &nbsp;onaylıyorum.
          </label>
        </div>
      </React.Fragment>
    );
  };

  const renderSubmit = (): ReactNode => {
    return (
      <React.Fragment>
        <div
          className={`d-flex justify-content-center ${
            props.authType === AuthType.login ? "mt-5" : ""
          }`}
        >
          <ButtonPrimary
            class="button-yellow"
            text="DEVAM ET"
            arrowSuffix={true}
            isSubmit={true}
            disabled={props.isSubmitDisabled}
          />
        </div>

        {props.authType === AuthType.signup && (
          <div className="contracts">
            Devam Et'e tıklayarak&nbsp;
            <a
              className="link"
              href="https://s3-eu-west-1.amazonaws.com/static.4p/Taraftar_Kart_Abonelik_Sozlesmesi.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Abonelik Sözleşmesi'ni
            </a>
            &nbsp;ve&nbsp;
            <a
              className="link"
              href="https://s3-eu-west-1.amazonaws.com/static.4p/Taraftar_Kart_Aydinlatma_Metni.pdf"
              target="_blank"
              rel="noreferrer"
            >
              KVKK Aydınlatma Metni'ni
            </a>
            &nbsp;onaylamış olursun.
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <Form
      id="login-msisdn-form"
      form={form}
      onFinish={handleFinish}
      onFinishFailed={handleFinishFailed}
    >
      {renderAuthType()}
      {renderMsisdn()}
      <ReCAPTCHA
        className={`recaptcha mt-3 ${isCaptchaError ? "error" : ""}`}
        sitekey={Constants.recaptchaSiteKey}
        onChange={handleRecaptchaChange}
      />
      {props.authType === AuthType.signup && renderConsents()}
      {renderSubmit()}

      <ModalContract
        visible={isContractVisible}
        callbackOk={() => handleModalContract(true)}
        callbackCancel={() => handleModalContract(false)}
      />
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};
const mapStateToProps = (store: IStore) => {
  return {
    errorSubmit: store.loginMsisdn.submit.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginMsisdnForm);
