import {Modal, Tabs} from "antd";
import parse from "html-react-parser";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import iconPast from "../../../assets/images/past-blue.svg";
import iconVote from "../../../assets/images/vote.svg";
import {setPollModalQuestion} from "../../../core/actions/poll";
import {PollDto} from "../../../core/models/dtos/poll.dto";
import {IStore} from "../../../core/reducers";
import FirebaseService from "../../../core/services/firebase.service";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import HistoryTab from "./HistoryTab/HistoryTab";
import "./PollModal.scss";
import QuestionTab from "./QuestionTab/QuestionTab";

const {TabPane} = Tabs;

interface IProps {
  pollInitialTab?: PollModalInitialTab;
  setPollModalQuestion: (poll?: PollDto) => void;
}

interface IState {
  activeKey: string;
}

export enum PollModalInitialTab {
  QUESTION = 'QUESTION',
  HISTORY = 'HISTORY',
}

class PollModal extends React.Component<IProps> {
  state: IState = {
    activeKey: 'question',
  }

  private readonly tabQuestion = 'question';
  private readonly tabHistory = 'history';

  componentDidMount() {
    if (this.props.pollInitialTab === PollModalInitialTab.HISTORY) {
      this.setState({activeKey: this.tabHistory});
    }
  }

  private handleTabClick = (key: string): void => {
    if (key === this.tabHistory) {
      FirebaseService.fireEvent(FirebaseService.event_ref_count_past_button_clicked);
    }
    this.setState({activeKey: key});
  }

  private renderHeaderQuestion(): ReactNode {
    return (
      <React.Fragment>
        <img className="mr-1" src={iconVote} alt="vote"/>
        {parse(FirebaseService.getValue(FirebaseService.monthly_question_header))}
      </React.Fragment>
    );
  }

  private renderHeaderHistory(): ReactNode {
    return (
      <React.Fragment>
        <img className="mr-1" src={iconPast} alt="history"/>
        {parse(FirebaseService.getValue(FirebaseService.past_question_header))}
      </React.Fragment>
    );
  }

  render() {
    return (
      <Modal
        className="poll-modal"
        maskStyle={{backdropFilter: "blur(8px)", backgroundColor: "rgba(0, 45, 114, 0.85)"}}
        visible={true}
        centered
        closable={false}
        footer={false}
        destroyOnClose
      >
        <div className="poll-body">
          <Tabs
            type="card"
            activeKey={this.state.activeKey}
            onChange={key => this.handleTabClick(key)}
          >
            <TabPane
              key={this.tabQuestion}
              tab={this.renderHeaderQuestion()}
            >
              <QuestionTab/>
            </TabPane>
            <TabPane
              key={this.tabHistory}
              tab={this.renderHeaderHistory()}
            >
              <HistoryTab/>
            </TabPane>
          </Tabs>
        </div>
        <ModalCloseButton
          callback={() => this.props.setPollModalQuestion()}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setPollModalQuestion,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    pollInitialTab: store.poll.pollModalInitialTab,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PollModal);
