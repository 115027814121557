import { Badge, Menu } from "antd";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import notifications from "../../assets/images/hamburger-bell.svg";
import gallery from "../../assets/images/hamburger-gallery.svg";
import info from "../../assets/images/hamburger-info.svg";
import mail from "../../assets/images/hamburger-mail.svg";
import question from "../../assets/images/hamburger-question-mark.svg";
import giftActive from "../../assets/images/header-gift-active.svg";
import gift from "../../assets/images/header-gift.svg";
import hamburgerActive from "../../assets/images/header-hamburger-active.svg";
import hamburger from "../../assets/images/header-hamburger.svg";
import homeActive from "../../assets/images/header-home-active.svg";
import home from "../../assets/images/header-home.svg";
import peopleActive from "../../assets/images/header-people-active.svg";
import people from "../../assets/images/header-people.svg";
import personActive from "../../assets/images/header-person-active.svg";
import person from "../../assets/images/header-person.svg";
import { logout } from "../../core/actions/app";
import { Constants } from "../../core/helpers/constants";
import { history } from "../../core/helpers/history";
import { router } from "../../core/helpers/router";
import { UserMeDto } from "../../core/models/dtos/userMe.dto";
import { IStore } from "../../core/reducers";
import FirebaseService from "../../core/services/firebase.service";
import "./AppHeader.scss";

const { SubMenu } = Menu;

interface IProps {
  pathname: string;
  userMe?: UserMeDto;
  logout: () => void;
}

interface IState {
  pathname: string;
  callbackHistoryUnregister?: VoidFunction;
}

enum HeaderIcon {
  home,
  person,
  gift,
  people,
  hamburger,
}

class AppHeader extends React.Component<IProps> {
  state: IState = {
    pathname: window.location.pathname,
    callbackHistoryUnregister: undefined,
  };

  componentDidMount() {
    this.setHistoryListener();
  }

  private setHistoryListener(): void {
    const callbackHistoryUnregister = history.listen(
      (location: any, action: any) => {
        this.setState({ pathname: location.pathname });
      }
    );
    this.setState({ callbackHistoryUnregister });
  }

  private isActive(headerIcon: HeaderIcon): boolean {
    switch (headerIcon) {
      case HeaderIcon.home:
        return this.props.pathname === router.HOME;
      case HeaderIcon.person:
        return this.props.pathname === router.PROFILE;
      case HeaderIcon.gift:
        return this.props.pathname === router.GIFT;
      case HeaderIcon.people:
        return this.props.pathname === router.INVITE_FRIENDS;
      case HeaderIcon.hamburger:
        return (
          this.props.pathname === router.MENU ||
          this.props.pathname === router.NOTIFICATIONS ||
          this.props.pathname === router.ABOUT_US ||
          this.props.pathname === router.FAQ ||
          this.props.pathname === router.CONTACT_US
        );
      default:
        return false;
    }
  }

  private handleRoute(icon: HeaderIcon): void {
    switch (icon) {
      case HeaderIcon.home:
        FirebaseService.fireEvent(
          FirebaseService.event_menu_count_nav_landing_button_clicked
        );
        history.push(router.HOME);
        return;
      case HeaderIcon.person:
        FirebaseService.fireEvent(
          FirebaseService.event_menu_count_nav_profile_button_clicked
        );
        history.push(router.PROFILE);
        return;
      case HeaderIcon.gift:
        FirebaseService.fireEvent(
          FirebaseService.event_menu_count_nav_gift_button_clicked
        );
        history.push(router.GIFT);
        return;
      case HeaderIcon.people:
        FirebaseService.fireEvent(
          FirebaseService.event_menu_count_nav_invite_friends_button_clicked
        );
        history.push(router.INVITE_FRIENDS);
        return;
      case HeaderIcon.hamburger:
        FirebaseService.fireEvent(
          FirebaseService.event_menu_count_hamburger_button_clicked
        );
        if (window.innerWidth < 992) {
          history.push(router.MENU);
        }
        return;
    }
  }

  private handleLogout(): void {
    FirebaseService.fireEvent(
      FirebaseService.event_menu_count_log_out_button_clicked
    );
    this.props.logout();
  }

  private renderHamburgerMenu(): ReactNode {
    return (
      <Menu className="header-menu" mode="horizontal">
        <SubMenu
          key="SubMenu"
          className="header-submenu"
          icon={
            <img
              src={`${
                this.isActive(HeaderIcon.hamburger)
                  ? hamburgerActive
                  : hamburger
              }`}
              alt="hamburger"
              onClick={() => this.handleRoute(HeaderIcon.hamburger)}
            />
          }
        >
          <Menu.Item
            key="header-menu-item-0"
            className="header-menu-item-wrapper"
          >
            <NavLink
              className="header-menu-item"
              to={router.NOTIFICATIONS}
              onClick={() =>
                FirebaseService.fireEvent(
                  FirebaseService.event_menu_count_notifications_button_clicked
                )
              }
            >
              <span className="text">Bildirimler</span>
              <img
                className="image bell"
                src={notifications}
                alt="notifications"
              />
            </NavLink>
          </Menu.Item>
          <hr className="header-divider" />
          <Menu.Item
            key="header-menu-item-1"
            className="header-menu-item-wrapper"
          >
            <NavLink
              className="header-menu-item"
              to={router.ABOUT_US}
              onClick={() =>
                FirebaseService.fireEvent(
                  FirebaseService.event_menu_count_about_us_button_clicked
                )
              }
            >
              <span className="text">Hakkımızda</span>
              <img className="image info" src={info} alt="info" />
            </NavLink>
          </Menu.Item>
          <hr className="header-divider" />
          <Menu.Item
            key="header-menu-item-2"
            className="header-menu-item-wrapper"
          >
            <NavLink
              className="header-menu-item"
              to={router.FAQ}
              onClick={() =>
                FirebaseService.fireEvent(
                  FirebaseService.event_menu_count_faq_button_clicked
                )
              }
            >
              <span className="text">Sıkça Sorulan Sorular</span>
              <img className="image question" src={question} alt="question" />
            </NavLink>
          </Menu.Item>
          <hr className="header-divider" />
          <Menu.Item
            key="header-menu-item-3"
            className="header-menu-item-wrapper"
          >
            <NavLink className="header-menu-item" to={router.PHOTO_GALLERY}>
              <span className="text">Galeri</span>
              <img className="image gallery" src={gallery} alt="gallery" />
            </NavLink>
          </Menu.Item>
          <hr className="header-divider" />
          <Menu.Item
            key="header-menu-item-4"
            className="header-menu-item-wrapper"
          >
            <NavLink
              className="header-menu-item"
              to={router.CONTACT_US}
              onClick={() =>
                FirebaseService.fireEvent(
                  FirebaseService.event_menu_count_contact_us_button_clicked
                )
              }
            >
              <span className="text">Bize Ulaş</span>
              <img className="image email" src={mail} alt="mail" />
            </NavLink>
          </Menu.Item>
          <hr className="header-divider" />
          <Menu.Item
            key="header-menu-item-5"
            className="header-menu-item-wrapper"
          >
            <div
              className="header-menu-item"
              onClick={() => this.handleLogout()}
            >
              <span className="text logout">Çıkış Yap</span>
            </div>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  }

  render() {
    return (
      <div id="app-header">
        <a
          className="d-none d-lg-flex"
          href="https://www.fenerbahce.org"
          target="_blank"
          rel="noreferrer"
        >
          <img className="logo" src={Constants.fenerLogo} alt="fbLogo" />
        </a>

        <div className="content">
          <img
            src={`${this.isActive(HeaderIcon.home) ? homeActive : home}`}
            alt="home"
            onClick={() => this.handleRoute(HeaderIcon.home)}
          />
          <div>
            <img
              src={`${
                this.isActive(HeaderIcon.person) ? personActive : person
              }`}
              alt="person"
              onClick={() => this.handleRoute(HeaderIcon.person)}
            />
            {this.props.userMe && (
              <Badge dot={!this.props.userMe.tckn} offset={[-5, -10]} />
            )}
          </div>
          <img
            src={`${this.isActive(HeaderIcon.gift) ? giftActive : gift}`}
            alt="gift"
            onClick={() => this.handleRoute(HeaderIcon.gift)}
          />
          <img
            src={`${this.isActive(HeaderIcon.people) ? peopleActive : people}`}
            alt="people"
            onClick={() => this.handleRoute(HeaderIcon.people)}
          />

          <div className="d-block d-lg-none">
            <img
              src={`${
                this.isActive(HeaderIcon.hamburger)
                  ? hamburgerActive
                  : hamburger
              }`}
              alt="hamburger"
              onClick={() => this.handleRoute(HeaderIcon.hamburger)}
            />
          </div>

          <div className="d-none d-lg-block">{this.renderHamburgerMenu()}</div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    if (this.state.callbackHistoryUnregister) {
      this.state.callbackHistoryUnregister();
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  const storeApp = store.app;
  return {
    pathname: storeApp.pathname,
    userMe: storeApp.userMe.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
