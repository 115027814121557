import React from "react";
import "./CreateCardText.scss";

const CreateCardText = () => {

  return (
    <div id="create-card-text">
      <p className="text">
        TARAFTAR KARTINI YARAT
      </p>
    </div>
  );
}

export default CreateCardText;
