export const TEST_QUESTIONS_FETCH_START = 'TEST_QUESTIONS_FETCH_START';
export const TEST_QUESTIONS_FETCH_SUCCESS = 'TEST_QUESTIONS_FETCH_SUCCESS';
export const TEST_QUESTIONS_FETCH_FAIL = 'TEST_QUESTIONS_FETCH_FAIL';
export const TEST_QUESTIONS_RESET = 'TEST_QUESTIONS_RESET';

export const TEST_UPDATE_QUESTION_ANSWER_START = 'TEST_UPDATE_QUESTION_ANSWER_START';
export const TEST_UPDATE_QUESTION_ANSWER_SUCCESS = 'TEST_UPDATE_QUESTION_ANSWER_SUCCESS';
export const TEST_UPDATE_QUESTION_ANSWER_FAIL = 'TEST_UPDATE_QUESTION_ANSWER_FAIL';
export const TEST_UPDATE_QUESTION_ANSWER_RESET = 'TEST_UPDATE_QUESTION_ANSWER_RESET';

export const TEST_FINISH_START = 'TEST_FINISH_START';
export const TEST_FINISH_SUCCESS = 'TEST_FINISH_SUCCESS';
export const TEST_FINISH_FAIL = 'TEST_FINISH_FAIL';
export const TEST_FINISH_RESET = 'TEST_FINISH_RESET';
