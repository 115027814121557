import {
  LOGIN_SUBSCRIPTION_STATUS_CHECK_FAIL,
  LOGIN_SUBSCRIPTION_STATUS_CHECK_RESET,
  LOGIN_SUBSCRIPTION_STATUS_CHECK_START,
  LOGIN_SUBSCRIPTION_STATUS_CHECK_SUCCESS
} from "../constants/loginSubscription";
import {LoginSubscriptionActions, LoginSubscriptionState} from "../types/loginSubscription";

const initialState: LoginSubscriptionState = {
  status: {
    loading: false,
    data: undefined,
    error: undefined,
  }
}

const reducer = (
  state = initialState,
  action: LoginSubscriptionActions,
): LoginSubscriptionState => {
  switch (action.type) {
    case LOGIN_SUBSCRIPTION_STATUS_CHECK_START:
      return {
        ...state,
        status: {
          ...initialState.status,
          loading: true,
        }
      }
    case LOGIN_SUBSCRIPTION_STATUS_CHECK_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          data: action.payload,
        }
      }
    case LOGIN_SUBSCRIPTION_STATUS_CHECK_FAIL:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.error,
        }
      }
    case LOGIN_SUBSCRIPTION_STATUS_CHECK_RESET:
      return {
        ...state,
        status: {
          ...initialState.status,
        }
      }
    default:
      return state;
  }
}

export default reducer;
